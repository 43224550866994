import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SideNavbar from '../../Components/Navigation/SideNavbar';

const SideNavbarPage = ({menuCollapse, permissions}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [employeeId, setEmployeeId] = useState();

    useEffect(() => {
        const employeeId = location.pathname.split('/')[2];
        if(!employeeId) {
            return;
        }
        setEmployeeId(employeeId);
    },[location]);

    const handleCheckEmployeeId = (location) => {
        if(!employeeId) {
           window.location.replace('/employee-info');
        } else {
            navigate(location)
        }
    }

    const handleLogout = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('user');
        window.location.href = '/login';
    }

    useEffect(()=>{
        if (!menuCollapse) {
            document.body.style.marginLeft = '160px'
            document.body.style.transition = 'all 0.4s'
        } else{
            document.body.style.marginLeft = '0px'
            document.body.style.transition = 'all 0.4s'
        }
    },[menuCollapse]);

    return (
        <SideNavbar
            employeeId={employeeId}
            menuCollapse={menuCollapse}
            navigate={navigate}
            location={location}
            handleCheckEmployeeId={handleCheckEmployeeId}
            handleLogout={handleLogout}
            permissions={permissions}
        />
    );
};

export default SideNavbarPage;