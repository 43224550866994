import React from 'react';
import FieldErrorMessage from "../Utils/FieldErrorMessage";
import TooltipsPercentage from '../Utils/TooltipsPercentage';

const EmployeeHeader = ({e_title, userName}) => {
    return (
        <div className='d-inline d-md-flex justify-content-between'>
            <h4 style={{color: '#683091', fontWeight: 'bold', fontSize: '22px'}}>{e_title} &nbsp;</h4>
            <div style={{marginLeft: '20px !important'}}  className='ml-2'>
                <TooltipsPercentage></TooltipsPercentage>
            </div>
            <div className='ms-auto w-10'>
                { userName && <h6 style={{color: '#683091', fontWeight: 'bold', fontSize: '22px' }}>{ `${userName}` }</h6> }
            </div>
        </div>
    );
};

export default EmployeeHeader;