import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {MdDeleteOutline} from 'react-icons/md';
import {AiOutlineEye, AiOutlineSearch} from 'react-icons/ai';
import {FaRegEdit, FaList, FaPrint} from 'react-icons/fa';
import Pagination from '../Utils/Pagination';
import axios from 'axios';
import {FaCheckCircle, FaTimesCircle, FaTrash } from 'react-icons/fa';
import {asset, authUser, axiosConfig, formatDate, isAdmin, translateToBanglaDigit, url} from "../../helpers/helpers";
import {toast, ToastContainer} from "react-toastify";
import Select2Wrapper from "../Utils/Select2Wrapper";
import {ImSortNumericAsc} from "react-icons/im";
import {useReactToPrint} from "react-to-print";
import PrintEmployeeList from "./PrintEmployeeList";
import {FiRefreshCw} from "react-icons/fi";
import Loader from "../Loader/Loader";
import avatar from "../../Assets/avatar.png"

const Dashboard = (
    {
        data,
        setData,
        currentPage,
        totalPages,
        setCurrentPage,
        handlePageChange,
        query,
        setQuery,
        nid,
        setNid,
        gender,
        setGender,
        designation,
        setDesignation,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        bloodGroup,
        setBloodGroup,
        ministry,
        setMinistry,
        organization,
        setOrganization,
        institution,
        setInstitution,
        perPage,
        setPerPage,
        printableEmployees,
        setPrintableEmployees,
        loadPrintableEmployees,
        permissions,
        isSearching,
        cadre,
        setCadre,
        classId,
        setClassId,
        married,
        setMarried,
        religion,
        setReligion,
        userStatus,
        setUserStatus,
        percentage,
        setPercentage,
        educationInfo,
        setEducationInfo,
        subject,
        setSubject
    }
) => {
    const navigate = useNavigate();
    const [genders, setGenders] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [institutions, setInstitutions] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [ministryOrganizationAndInstitution, setMinistryOrganizationAndInstitution] = useState([]);
    const [sortInputFieldsHidden, setSortInputHidden] = useState(true);
    const [isPrinting, setIsPrinting] = useState(false);
    const printableComponentRef = useRef();
    const promiseResolveRef = useRef(null);
    const [bloodGroups, setBloodGroups] = useState([]);
    const [marriedList, setMarriedList] = useState([]);
    const [cadres, setCadres] = useState([]);
    const [classes, setClasses] = useState([]);
    const [religions, setReligions] = useState([]);
    const [eduData, setEduData] = useState(null);

    const handleEdit = (id) => {
        localStorage.setItem("editId", id);
        navigate(`/employee-info-edit/${id}`)
    }

    const getDesignation = () => {
        try {
            axios.get(url(`fetch-designation`), {
                ...axiosConfig(), params: {
                    ministry,
                    organization,
                    institution
                }
            })
                .then(({data: {data}}) => {
                    setDesignations(data)
                })
                .catch((error) => {
                    console.log(error);
                })
        } catch (e) {
            toast.error('something went wrong')
        }
    }

    const fetchEduData = async () => {
        try {
          const accessToken = localStorage.getItem("accessToken");
          const headers = {
            Authorization: `Bearer ${accessToken}`,
          };
          const [eduData] = await Promise.all([
              axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-educational-qualification`, {headers}),
          ]);
          setEduData(eduData?.data?.data);
        } catch (error) {
          console.error(error);
        }
    };

    const handleStatus = (id, separationStatus=0) => {
        if (!window.confirm('Are you sure?')) return;

        axios.get(url(`employee/${id}/toggle-status?separationStatus=${separationStatus}`), axiosConfig())
            .then(response => {
                handlePageChange();
                toast.success('Status Updated Successful');
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleDelete = (id) => {
        if (!window.confirm('Are you sure?')) return;

        axios.get(url(`employee/${id}/delete`), axiosConfig())
            .then(response => {
                handlePageChange();
                toast.success('Status Updated Successful');
            })
            .catch(error => {
                console.log(error);
            });
    }

    const fetchDropdownData = async () => {
        try {
            const accessToken = localStorage.getItem("accessToken");
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const [genders, classes, married, cadres, bloodGroups, religion] = await Promise.all([
                axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-gender`, {headers}),
                axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-class`, {headers}),
                axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-marital-status`, {headers}),
                axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-cadre`, {headers}),
                axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-blood-group`, {headers}),
                axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-religion`, {headers}),
            ]);
            setGenders(genders?.data?.data)
            setCadres(cadres?.data?.data)
            setMarriedList(married?.data?.data)
            setClasses(classes?.data?.data)
            setBloodGroups(bloodGroups?.data?.data)
            setReligions(religion?.data?.data)
        } catch (error) {
            console.error(error);
        }
    };

    const getMinistryOrganizationAndInstitution = () => {
        try {
            axios.get(url(`fetch-ministry-organization-and-institution-user-wise`), axiosConfig())
                .then(({data: {data}}) => {
                    setMinistryOrganizationAndInstitution(data);
                })
                .catch((error) => {
                    console.log(error);
                })
        } catch (e) {
            toast.error('something went wrong')
        }
    }

    const handleMinistryChange = (id) => {
        let org = ministryOrganizationAndInstitution.filter(data => data.parent_id == id && data.type === 'organization');
        setOrganizations(org);
        setMinistry(id);
    }
    const handleOrganizationChange = (id) => {
        let institute = ministryOrganizationAndInstitution.filter(data => data.parent_id == id && data.type === 'institution');
        setInstitutions(institute);
        setOrganization(id);
    }
    const sortingOrderChange = (index, value) => {
        data.data[index]['sort_order'] = value ? parseFloat(value) : '';
        setData({...data});
    }

    const saveSorting = async () => {
        try {
            const {data : {message}} = await axios.post(url(`store-employee-sorting-order`), data.data, axiosConfig());
            toast.success(message);
            setSortInputHidden(true);
            handlePageChange();
        } catch (e) {
            toast.error('Something went wrong!');
        }
    }
    const handlePrint = async () => {
        await loadPrintableEmployees();
        print();
    }
   
    const onError = ({ currentTarget }) => {
        currentTarget.onError = null;
        currentTarget.src= avatar
    }

    const print = useReactToPrint({
        content: () => printableComponentRef.current,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            promiseResolveRef.current = null;
            setIsPrinting(false);
            setPrintableEmployees([]);
        }
    });
    const handleSearchButtonClick = () => {
        handlePageChange();
    }
    const handleRefreshButtonClick = () => {
        toast.success("রিফ্রেশ করা হয়েছে");
        window.location.reload();
    }
    useEffect(() => {
        if (ministry && organization) {
            getDesignation();
        }
    }, [ministry, organization]);

    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            promiseResolveRef.current();
        }
    }, [isPrinting])

    useEffect(() => {
        handleMinistryChange(authUser()?.ministry_id);
        if (!isAdmin() && ministryOrganizationAndInstitution) {
            handleOrganizationChange(authUser()?.organization_id);
        }
    }, [ministryOrganizationAndInstitution]);

    useEffect(() => {
        getMinistryOrganizationAndInstitution();
        fetchDropdownData();
        fetchEduData();
    }, []);

    return (
        <div>
            <div style={{display: isSearching ? "block" : "none"}}>
                <Loader/>
            </div>
            <ToastContainer/>
            <PrintEmployeeList employees={printableEmployees} isPrinting={isPrinting} reference={printableComponentRef} />
            <div className="card" style={{display: isSearching ? "none" : "block"}}>
                <div className="card-header pt-3">
                    <div className="row">
                        <div className="col-md-10">
                            <h4><FaList/> &nbsp; সকল তথ্য</h4>
                        </div>
                        <div className="col-md-2" style={{textAlign: 'right'}}>
                           <div>
                               <h4>সর্বমোট ({translateToBanglaDigit(data?.total)})</h4>
                           </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-3">
                            <input type="text" className="form-control"
                                   value={query}
                                   onChange={(e) => setQuery(e.target.value)}
                                   placeholder="সার্চ..."/>
                        </div>
                        <div className="col-3">
                            <input type="text" className="form-control"
                                   value={nid}
                                   onChange={(e) => setNid(e.target.value)}
                                   placeholder="জাতীয় পরিচয়পত্র নম্বর..."/>
                        </div>
                        <div className="col-3">
                            <select className="form-select" value={gender}
                                    onChange={(e) => setGender(e.target.value)}>
                                <option value="" style={{color: 'gray'}}>জেন্ডার নির্বাচন করুন</option>
                                {genders.map(gender => {
                                    return (<option key={gender.id} value={gender.id}>{gender.bn}</option>)
                                })}
                            </select>
                        </div>
                        <div className="col-3">
                            <select
                                className="form-select" value={ministry}
                                onChange={(e) => handleMinistryChange(e.target.value)}
                            >
                                <option value="" style={{color: 'gray'}}>মন্ত্রণালয় নির্বাচন করুন</option>
                                {ministryOrganizationAndInstitution && ministryOrganizationAndInstitution.map(ministry => {
                                    return ministry.parent_id === null ? (<option key={ministry.id} value={ministry.id}>
                                        {ministry.name}
                                    </option>) : '';
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-3">
                            <select
                                className="form-select" value={organization}
                                onChange={(e) => handleOrganizationChange(e.target.value)}
                            >
                                <option value="" style={{color: 'gray'}}>দপ্তর /সংস্থা নির্বাচন করুন</option>
                                {organizations && organizations.map(org => {
                                    return (<option key={org.id} value={org.id}>
                                        {org.name}
                                    </option>);
                                })}
                            </select>
                        </div>
                        <div className="col-3">
                            <Select2Wrapper
                                value={institution}
                                placeholder="প্রতিষ্ঠান নির্বাচন করুন"
                                onChange={(value) => setInstitution(value)}
                                options={institutions}
                            />
                        </div>
                        <div className="col-3">
                            <Select2Wrapper
                                value={designation}
                                placeholder="পদবি নির্বাচন করুন"
                                onChange={(value) => setDesignation(value)}
                                options={designations}
                            />
                        </div>
                        <div className="col-3">
                            <input type="date" className="form-control"
                                value={startDate}
                                onChange={(event) => setStartDate(event.target.value) }
                            />
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className="col-3">
                            <input type="date" className="form-control"
                                value={endDate}
                                onChange={(event) => setEndDate(event.target.value) }
                            />
                        </div>
                        <div className="col-3">
                            <select id='blood_group'
                                onChange={(event) => {
                                    setBloodGroup(event.target.value)
                                }
                            }
                            className="form-select" aria-label="Default select example">
                                <option style={{color: 'gray'}} value={bloodGroup}>রক্তের গ্রুপ নির্বাচন করুন</option>
                                {
                                    bloodGroups.map(bloodGroup => <option key={bloodGroup.id} value={bloodGroup.id}>{bloodGroup.bn}</option>)
                                }
                            </select>
                        </div>
                        <div className="col-3">
                           <select id='cadre' className="form-select" aria-label="Default select example"
                             value={cadre}
                             onChange={(event) => {
                                setCadre(event.target.value)
                            }}
                           >
                               <option value="" style={{color: 'gray'}}>ক্যাডার নির্বাচন করুন</option>
                               {
                                   cadres.map(cadre => <option key={cadre.id} value={cadre.id}>{cadre.bn}</option>)
                               }
                           </select>
                        </div>
                        <div className="col-3">
                           <select id='classId' className="form-select" aria-label="Default select example"
                            value={classId}
                            onChange={(event) => {
                                setClassId(event.target.value)
                            }}
                           >
                               <option value="" style={{color: 'gray'}}>কোটা নির্বাচন করুন</option>
                               {
                                   classes.map(classId => <option key={classId.id} value={classId.id}>{classId.bn}</option>)
                               }
                           </select>
                        </div>
                        <div className='row mt-3 m-0 p-0'>
                            <div className="col-3">
                                <select id='marital_status' className="form-select" aria-label="Default select example"
                                value={married}
                                 onChange={(event) => {
                                    setMarried(event.target.value)
                                }}
                                >
                                    <option value="" style={{color: 'gray'}}>বৈবাহিক অবস্থা নির্বাচন করুন</option>
                                    {
                                        marriedList.map((value) => {
                                            return <option key={value.id}
                                                            value={value.id}>{value.bn}
                                            </option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-3">
                                <select id='religion' className="form-select" aria-label="Default select example"
                                value={religion}
                                onChange={(event) => {
                                    setReligion(event.target.value)
                                }}
                                >
                                    <option value="" style={{color: 'gray'}}>ধর্ম নির্বাচন করুন</option>
                                    {
                                        religions.map((value) => {
                                            return <option key={value.id}
                                                            value={value.id}>{value.bn}
                                            </option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-3">
                                <select id='userStatus' className="form-select" aria-label="Default select example"
                                value={userStatus}
                                onChange={(event) => {
                                    setUserStatus(event.target.value)
                                }}
                                >
                                    <option value="" style={{color: 'gray'}}>অবস্থা নির্বাচন করুন</option>
                                    <option value='0'>সকল</option>
                                    <option value='1'>অ্যাক্টিভ</option>
                                    <option value='2'>ইনঅ্যাক্টিভ</option>
                                    <option value='3'>পেন্ডিং</option>
                                </select>
                            </div>
                            <div className="col-3">
                                {
                                    eduData &&
                                    <select className="form-select" aria-label="Default select example"
                                        value={educationInfo}
                                        onChange={(event) => {
                                            setEducationInfo(event.target.value)
                                        }}
                                    >
                                        <option value="" style={{color: 'gray'}}>শিক্ষাগত যোগ্যতা নির্বাচন করুন</option>
                                        {
                                            eduData?.map(edu => <option key={edu.id}
                                                                        value={edu.id}>{edu.bn}</option>)
                                        }
                                    </select>
                                }
                            </div>
                        </div>
                        <div className='row mt-3 m-0 p-0'>
                            <div className="col-3">
                                <select id='percentage' className="form-select" aria-label="Default select example"
                                        value={percentage}
                                        onChange={(event) => {
                                            setPercentage(event.target.value)
                                        }}
                                >
                                    <option value="" style={{color: 'gray'}}>পারসেন্টেজ নির্বাচন করুন</option>
                                    <option value='1'>১০০%</option>
                                    <option value='2'>১০০% থেকে কম</option>
                                </select>
                            </div>
                            <div className="col-3">
                                <input type="text" className="form-control"
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                    placeholder="বিভাগ / বিষয় / অর্জিত ডিগ্রি"/>
                            </div>
                            <div className="col-9" style={{textAlign: 'right'}}>
                                <button
                                    onClick={handleSearchButtonClick}
                                    type="submit"
                                    style={{marginRight: '10px'}}
                                    disabled={isSearching}
                                    className="btn btn-sm btn-success mt-1">
                                    <AiOutlineSearch/> Search
                                </button>
                                <button
                                    onClick={handleRefreshButtonClick}
                                    type="submit"
                                    disabled={isSearching}
                                    className="btn btn-sm btn-warning mt-1">
                                    <FiRefreshCw/> Refresh
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-1">
                            <select className="form-select"
                                    value={perPage}
                                    onChange={(e) => setPerPage(e.target.value)}>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <div className='col-11' style={{textAlign: 'right'}}>
                            <div>
                                <button className="btn btn-primary" onClick={handlePrint}>
                                    <FaPrint/> প্রিন্ট
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12">
                            <div className='table-responsive'>
                                <table className="table table-bordered table-hover">
                                    <thead>
                                    <tr style={{backgroundColor: '#f9f9f9'}}>
                                        <th className="text-center" scope="col">ক্রমিক নং</th>
                                        <th scope="col" className='text-center'>ছবি</th>
                                        <th scope="col">নাম</th>
                                        <th scope="col">আইডি</th>
                                        <th scope="col">মোবাইল নং</th>
                                        <th scope="col">পদবি</th>
                                        <th scope="col">মন্ত্রণালয়,দপ্তর /সংস্থা, বর্তমান কর্মস্থল</th>
                                        <th scope="col">এন্ট্রি (%)</th>
                                        <th scope="col">যোগদানের তারিখ</th>
                                        <th scope="col">সর্বশেষ হালনাগাদ</th>
                                        <th scope="col">অবস্থা</th>
                                        {!sortInputFieldsHidden && <th scope="col">সাজানোর অর্ডার</th>}
                                        <th scope="col">
                                            অ্যাকশন
                                            {
                                                (isAdmin() || permissions?.includes('edit')) &&
                                                <button
                                                    title={sortInputFieldsHidden ? 'সাজানোর অর্ডার দেখুন' : 'সাজানোর অর্ডার গোপন করুন'}
                                                    style={{marginLeft: '8px'}}
                                                    onClick={() => setSortInputHidden(!sortInputFieldsHidden)}
                                                    className={'btn btn-sm ' + (sortInputFieldsHidden ? 'btn-secondary' : 'btn-info')}>
                                                    <ImSortNumericAsc/>
                                                </button>
                                            }
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data && data.data.map((user, key) => {
                                        return (<tr key={user?.id}>
                                            <td className="text-center" style={{verticalAlign: 'center'}}>{translateToBanglaDigit(data.from + key)}</td>
                                            <td style={{textAlign: 'center'}}>
                                                <img onError={onError} style={{width: '80px', height: '80px', border: '2px solid gray', borderRadius: '50px'}} src={user?.image ? asset(user.image) : avatar } />
                                            </td>
                                            <td style={{verticalAlign: 'center'}}>{user?.name_in_bn}</td>
                                            <td style={{verticalAlign: 'center'}}>{user?.unique_id}</td>
                                            <td style={{verticalAlign: 'center'}}>{translateToBanglaDigit(user?.phone)}</td>
                                            <td style={{verticalAlign: 'center'}}>{user?.designation}</td>
                                            <td style={{verticalAlign: 'center'}}>
                                                {
                                                    user?.min_org_ins
                                                }
                                            </td>
                                            <td style={{verticalAlign: 'center', textAlign: 'center'}}>{translateToBanglaDigit(user.percentage)}</td>
                                            <td style={{verticalAlign: 'center'}}>{translateToBanglaDigit(formatDate(user?.joining_date))}</td>
                                            <td style={{verticalAlign: 'center'}}>{translateToBanglaDigit(formatDate(user?.updated_at))}</td>
                                            <td style={{verticalAlign: 'center'}}>
                                            {
                                                user?.status == 1 ? (
                                                    <span className='badge bg-success'>অ্যাক্টিভ</span>
                                                ) : user?.status == 2 ? (
                                                    <span className='badge bg-warning'>ইনঅ্যাক্টিভ</span>
                                                ) : (
                                                    <span className='badge bg-danger'>পেন্ডিং</span>
                                                )
                                            }
                                            </td>
                                            {!sortInputFieldsHidden && <td style={{verticalAlign: 'center', textAlign: 'center', width: 100 + 'px'}}>
                                                <span className='badge bg-success'> {user?.order}</span>
                                                <input
                                                    type="number"
                                                    placeholder="অর্ডার"
                                                    className="form-control mt-2"
                                                    value={user?.sort_order}
                                                    onChange={(event) => sortingOrderChange(key, event.target.value)}
                                                />
                                            </td>}
                                            <td style={{verticalAlign: 'center', width: 120 + 'px'}}>
                                                <div>
                                                    {
                                                        (isAdmin() || permissions?.includes('view')) &&
                                                        <button className="btn btn-sm btn-info mt-1"
                                                                title="ভিউ করুন"
                                                                onClick={() => navigate(`/employee-info/${user.id}/view-full`)}>
                                                            <AiOutlineEye/>
                                                        </button>
                                                    }
                                                    &nbsp;
                                                    {
                                                        (isAdmin() || (permissions?.includes('edit') && user?.status == 0))  &&
                                                        <button className="btn btn-sm btn-primary mt-1"
                                                                title="এডিট করুন"
                                                                onClick={() => handleEdit(user?.id)}>
                                                            <FaRegEdit/>
                                                        </button>
                                                    }
                                                    &nbsp;
                                                    {
                                                        (isAdmin() && permissions?.includes('delete'))  &&
                                                        <button style={{marginLeft: ''}} className="btn btn-sm btn-danger mt-1"
                                                                title="মুছে ফেলুন"
                                                                onClick={() => handleDelete(user?.id, 1)}>
                                                            <FaTrash/>
                                                        </button>
                                                    }
                                                    &nbsp;
                                                    {
                                                        (user?.status && user?.status == 1 ?
                                                            <div>
                                                                {
                                                                    (isAdmin() || permissions?.includes('status_change'))  &&
                                                                    <button className="btn btn-sm btn-danger mt-1"
                                                                            title="পেন্ডিং "
                                                                            onClick={() => handleStatus(user?.id)}>
                                                                        <FaTimesCircle/>
                                                                    </button>
                                                                }
                                                                {
                                                                    (isAdmin() || permissions?.includes('separation_status'))  &&
                                                                    <button style={{marginLeft: '4px'}} className="btn btn-sm btn-warning mt-1"
                                                                            title="আন-পাবলিশ"
                                                                            onClick={() => handleStatus(user?.id, 1)}>
                                                                        <FaTimesCircle/>
                                                                    </button>
                                                                }
                                                            </div>
                                                            :
                                                             (<button style={{marginLeft: '4px'}} className="btn btn-sm btn-success mt-1"
                                                                    title="পাবলিশ"
                                                                    onClick={() => handleStatus(user?.id)}>
                                                                <FaCheckCircle/>
                                                            </button>)
                                                        )
                                                    }
                                                </div>
                                            </td>
                                        </tr>)
                                    })}
                                    </tbody>
                                </table>
                            </div>
                            {!sortInputFieldsHidden && <div>
                                <button
                                    className="float-end btn btn-success"
                                    onClick={saveSorting}>
                                    সাজানোর অর্ডার সংরক্ষণ করুন
                                </button>
                            </div>}
                            <br></br>
                            {
                                !isSearching && <Pagination currentPage={currentPage} totalPages={totalPages}
                                                                onPageChange={setCurrentPage} firstAndLastPageShow={true}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>);
};

export default Dashboard;