import {toast, ToastContainer} from "react-toastify";
import {FaList, FaRegEdit} from "react-icons/fa";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {asset, authUser, axiosConfig, isAdmin, translateToBanglaDigit, url} from "../../helpers/helpers";
import {useNavigate} from "react-router-dom";
import Loader from "../Loader/Loader";
import Pagination from "../Utils/Pagination";
import UserEdit from "./UserEdit";
import {AiOutlineSearch} from "react-icons/ai";
import {FiRefreshCw} from "react-icons/fi";
import {useForm} from "react-hook-form";
import Select2Wrapper from "../Utils/Select2Wrapper";
import avatar from "../../Assets/avatar.png"

const UserList = ({permissions}) => {
    const navigate = useNavigate()
    const [users, setUsers] = useState({});
    const [editUser, setEditUser] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPage] = useState(3);
    const [organizations, setOrganizations] = useState([]);
    const [from, setFrom] = useState();
    const [total, setTotal] = useState(0);
    const {register, getValues, setValue, handleSubmit, reset} = useForm();
    const [modalShow, setModalShow] = React.useState(false);
    const [ministryOrganizationAndInstitution, setMinistryOrganizationAndInstitution] = useState([]);
    const [institutions, setInstitutions] = useState([]);

    useEffect(() => {
        getUsers()
        getMinistryOrganizationAndInstitution();
    }, []);

    useEffect(() => {
        getUsers()
    }, [currentPage]);

    const handleEdit = (user) => {
        setEditUser(user);
        setModalShow(true);
    }
    const handleResetBtnClick = () => {
        reset({
            search: '',
            role_id: '',
        })
        toast.success("Refreshed");
    }
    const getUsers = (formData = {}) => {
        setIsLoading(true);
        axios.get(url(`users?page=${currentPage}`), {...axiosConfig(), params: formData})
            .then(({data: {data: {data, current_page, per_page, total, from}}}) => {
                setTotal(total);
                setFrom(from);
                setUsers(data);
                setCurrentPage(current_page);
                setTotalPage(Math.ceil(total / per_page));
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                if (error?.response?.status === 401) {
                    navigate('/login')
                }
            });
    }
    const getMinistryOrganizationAndInstitution = () => {
        try {
            axios.get(url(`fetch-ministry-organization-and-institution-user-wise`), axiosConfig())
                .then(({data: {data}}) => {
                    setMinistryOrganizationAndInstitution(data);
                    let org = data.filter(item => item.type == 'organization');
                    setOrganizations(org);
                })
                .catch((error) => {
                    console.log(error);
                })
        } catch (e) {
            toast.error('something went wrong')
        }
    }

    const handleOrganizationChange = (id) => {
        setValue('organization_id', id)
        let institutes = ministryOrganizationAndInstitution.filter(data => data.parent_id == id && data.type === 'institution');
        setInstitutions(institutes);
    }

    const onError = ({ currentTarget }) => {
        currentTarget.onError = null;
        currentTarget.src= avatar
    }

    return (
        isLoading ? (<Loader/>) : (
            <>
                <ToastContainer/>
                <div className="component_container py-2 my-3">
                    <div>
                        <div className="card">
                            <div className="card-header pt-3">
                                <div className="row">
                                    <div className="col-md-10">
                                        <h4><FaList/> &nbsp; সকল ব্যবহারকারী</h4>
                                    </div>
                                    <div className="col-md-2" style={{textAlign: 'right'}}>
                                    <div>
                                        <h4>সর্বমোট ({translateToBanglaDigit(total)})</h4>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit(getUsers)}>
                                    <div className="row">
                                        <div className="col-3">
                                            <input type="text" className="form-control"
                                                   {...register('search')}
                                                   placeholder="সার্চ..."/>
                                        </div>
                                        <div className="col-3">
                                            <select className="form-select"
                                                    aria-label="Default select example" {...register('role_id')}>
                                                <option value="">রোল নির্বাচন করুন</option>
                                                <option value="1">অ্যাডমিন</option>
                                                <option value="2">ব্যবহারকারী</option>
                                                <option value="3">ফোকাল পয়েন্ট</option>
                                                <option value="4">ডাটা এনট্রি</option>
                                            </select>
                                        </div>
                                        <div className="col-3">
                                            <select
                                                {...register('organization_id')}
                                                className="form-select"
                                                onChange={(e) => handleOrganizationChange(e.target.value)}
                                            >
                                                <option value="" style={{color: 'gray'}}>দপ্তর /সংস্থা নির্বাচন করুন</option>
                                                {organizations && organizations.map(org => {
                                                    return (<option key={org.id} value={org.id}>
                                                        {org.name}
                                                    </option>);
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-3">
                                            <Select2Wrapper
                                                value={getValues('institute_id')}
                                                placeholder="প্রতিষ্ঠান নির্বাচন করুন"
                                                onChange={(value) => setValue('institute_id', value)}
                                                options={institutions}
                                            />
                                        </div>

                                        <div className="col-12" style={{ textAlign: 'right' }}>
                                            <button type="submit" className="btn btn-success mt-1 text-right"
                                                    style={{marginRight: '10px'}}>
                                                <AiOutlineSearch/></button>
                                            <button className="btn btn-warning mt-1 text-right" onClick={handleResetBtnClick}>
                                                <FiRefreshCw/>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <div className="row">
                                    <div className="col-12">
                                        <div className='table-responsive pt-4'>
                                            <table className="table table-bordered table-hover">
                                                <thead>
                                                <tr style={{backgroundColor: '#f9f9f9'}}>
                                                    <th scope="col">ক্রমিক নং</th>
                                                    <th scope="col" className="text-center">ছবি</th>
                                                    <th scope="col">নাম</th>
                                                    <th scope="col">পদবি</th>
                                                    <th scope="col">আইডি</th>
                                                    <th scope="col">মোবাইল</th>
                                                    <th scope="col">ইমেইল</th>
                                                    <th scope="col">সংস্থা</th>
                                                    <th scope="col">রোল</th>
                                                    <th scope="col">অ্যাকশন</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    users && users.map((user, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{translateToBanglaDigit(index + from) }</td>
                                                                <td style={{textAlign: 'center'}}>
                                                                    <img onError={onError} style={{width: '80px', height: '80px', border: '2px solid gray', borderRadius: '50px'}} src={user?.image ? asset(user.image) : avatar } />
                                                                </td>
                                                                <td>{user.name}</td>
                                                                <td>{user?.designation_name}</td>
                                                                <td>{user.unique_id}</td>
                                                                <td>{user.phone}</td>
                                                                <td>{user.email}</td>
                                                                <td>{user.organization}</td>
                                                                <td>{user.role}</td>
                                                                <td>
                                                                    <div>
                                                                        {
                                                                           (isAdmin() || permissions?.includes('edit')) &&
                                                                            <button className="btn btn-sm btn-secondary"
                                                                                    onClick={() => handleEdit(user)}>
                                                                                <FaRegEdit/>
                                                                            </button>
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                            <br></br>
                                            <Pagination currentPage={currentPage} totalPages={totalPages}
                                                        onPageChange={setCurrentPage}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <UserEdit editUser={editUser} modalShow={modalShow} setModalShow={setModalShow} getUsers={getUsers} permissions={permissions} />
            </>
        )
    );
}

export default UserList;

