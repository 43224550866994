import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import FieldErrorMessage from "../Utils/FieldErrorMessage";

const LoginForm = ({onSubmit, errors}) => {
    const navigate = useNavigate();
    const {register, setValue, handleSubmit} = useForm();

    useEffect(() => {
        setValue('unique_id', window.unique_id);
        window.unique_id = '';
    }, [navigate])

    return (
        <div className="col-12 col-md-6 login_right">
            <div className="card">
                <div className="card-body">
                    <h3 className='text-center'>Personnel Management Information System (PMIS)</h3>
                    <h4 className='text-center mb-5'>লগইন করুন</h4>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-4">
                            <label className="form-label">কর্মকর্তা/ কর্মচারি নম্বর</label>
                            <input type="number" className="form-control"
                                   {...register("unique_id")} />
                            <FieldErrorMessage errors={errors} name='unique_id'/>
                            <FieldErrorMessage errors={errors} name='message'/>
                        </div>
                        <div className="mb-5">
                            <label className="form-label">পাসওয়ার্ড</label>
                            <input type="password" className="form-control"
                                   {...register("password")} />
                            <FieldErrorMessage errors={errors} name='password'/>
                        </div>
                        <div className='d-flex justify-content-between align-items-center mb-2'>
                            <p className='m-0' style={{cursor: 'pointer'}}
                               onClick={() => navigate('/signup')}>রেজিস্ট্রেশন করুন</p>
                            <button type="submit" className="login_submit rounded border-0">প্রবেশ করুন</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default LoginForm;