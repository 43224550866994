import {useState, useEffect} from 'react';
import axios from 'axios';
import {axiosConfig} from "../helpers/helpers";

const useUserPermissionsById = (id) => {
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState()
    const [permissions, setPermissions] = useState([]);

    useEffect( () => {
        if (!id) return;
         fetchUserPermissions(id);
    }, [id]);

    const fetchUserPermissions = async (id) => {
        try {
            const {data: {data}} = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/user-permissions/${id}`, axiosConfig());
            setPermissions(data);
        } catch (error) {
            setErrors(error);
        } finally {
            setLoading(false);
        }
    }
    return {permissions, errors, loading};
}

export default useUserPermissionsById;