import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import FieldErrorMessage from "../Utils/FieldErrorMessage";
import axios from "axios";
import {axiosConfig, url} from "../../helpers/helpers";
import {toast} from "react-toastify";

const SignUpForm = ({onSubmit, uniqueId, errors}) => {
    const navigate = useNavigate();
    const {register, handleSubmit, setValue} = useForm();
    const [organization, setOrganization] = useState([]);
    const [institutions, setInstitutions] = useState([]);
    const [ministryOrganizationAndInstitution, setMinistryOrganizationAndInstitution] = useState([]);
    let id = uniqueId?.data;

    const getMinistryOrganizationAndInstitution = () => {
        try {
            axios.get(url(`fetch-ministry-organization-and-institution`), axiosConfig())
                .then(({data: {data}}) => {
                    setMinistryOrganizationAndInstitution(data);
                })
                .catch((error) => {
                    console.log(errors);
                })
        } catch (e) {
            toast.error('something went wrong')
        }
    }

    const handleMinistryChange = (id) => {
        let org = organization;
        org = ministryOrganizationAndInstitution.filter(data => data.parent_id == id && data.type === 'organization');
        setOrganization(() => [...org]);
    }

    const handleOrgChange = (id) => {
        let institutes = institutions;
        institutes = ministryOrganizationAndInstitution.filter(data => data.parent_id == id && data.type === 'institution');
        setInstitutions(() => [...institutes]);
        const headOffice = ministryOrganizationAndInstitution.find(data => data.parent_id == id && data.type === 'institution' && data.is_head_office == 1);
        if (typeof headOffice != 'undefined') {
            setValue('institution_id', headOffice.id)
        }
    }

    useEffect(() => {
        getMinistryOrganizationAndInstitution();
    }, []);

    useEffect(() => {
        setValue("unique_id", id);
    }, [id])

    return (
        <div className="col-12 col-md-6 login_right">
            <div className="card">
                <div className="card-body">
                    <h3 className='text-center mb-3 mb-lg-5'>নিবন্ধন করুন</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            <div className="col-xs-auto col-md mb-3">
                                <label className="form-label">কর্মকর্তা/ কর্মচারি নাম<span
                                    className='text-danger'>*</span></label>
                                <input type="name" className="form-control" aria-describedby="nameHelp"
                                       {...register("name")}/>
                                <FieldErrorMessage errors={errors} name='name'/>
                            </div>
                            <div className="col-xs-auto col-md mb-3">
                                <label className="form-label">কর্মকর্তা/ কর্মচারি নম্বর <span
                                    className='text-danger'>*</span></label>
                                <input type='text' className="form-control" disabled placeholder="will be auto generated"/>
                                <FieldErrorMessage errors={errors} name='unique_id'/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-xs-auto col-md mb-3">
                                <label className="form-label">ইমেইল</label>
                                <input type="email" className="form-control" aria-describedby="emailHelp"
                                       {...register("email")}/>
                                <FieldErrorMessage errors={errors} name='email'/>
                            </div>
                            <div className="col-xs-auto col-md mb-3">
                                <label className="form-label">ফোন নম্বর <span
                                    className='text-danger'>*</span></label>
                                <input type="phone" className="form-control" aria-describedby="phoneHelp"
                                       {...register("phone")}/>
                                <FieldErrorMessage errors={errors} name='phone'/>
                            </div>
                        </div>
                        {/*<div className='row'>*/}

                        {/*    /!*<div className="col-xs-auto col-md mb-3">*!/*/}
                        {/*    /!*    <label className="form-label">কর্মবিভাগ <span*!/*/}
                        {/*    /!*        className='text-danger'>*</span></label>*!/*/}
                        {/*    /!*    <select className="form-select" aria-label="Default select example"*!/*/}
                        {/*    /!*            {...register("department")} >*!/*/}
                        {/*    /!*        <option value="">নির্বাচন করুন</option>*!/*/}
                        {/*    /!*        <option value="1">প্রথম</option>*!/*/}
                        {/*    /!*        <option value="2">দ্বিতীয়</option>*!/*/}
                        {/*    /!*        <option value="3">তৃতীয়</option>*!/*/}
                        {/*    /!*        <option value="4">চতুর্থ</option>*!/*/}
                        {/*    /!*        <option value="5">অন্যান্য</option>*!/*/}
                        {/*    /!*    </select>*!/*/}
                        {/*    /!*    <FieldErrorMessage errors={errors} name='department'/>*!/*/}
                        {/*    /!*</div>*!/*/}
                        {/*    */}
                        {/*    /!*<div className="col-xs-auto col-md mb-3">*!/*/}
                        {/*    /!*    <label htmlFor="phone" className="form-label">ফোন নম্বর <span*!/*/}
                        {/*    /!*        className='text-danger'>*</span></label>*!/*/}
                        {/*    /!*    <input type="text" className="form-control"*!/*/}
                        {/*    /!*           {...register("phone")}/>*!/*/}
                        {/*    /!*    <FieldErrorMessage errors={errors} name='phone'/>*!/*/}
                        {/*    /!*</div>*!/*/}
                        {/*</div>*/}
                        <div className='row'>
                            <div className="col-xs-auto col-md mb-3">
                                <label className="form-label">মন্ত্রণালয় নাম <span
                                    className='text-danger'>*</span></label>
                                <select
                                    className="form-select"
                                    {...register(`ministry_id`)}
                                    onChange={(e) => handleMinistryChange(e.target.value)}
                                >
                                    <option value="" style={{color: 'gray'}}>মন্ত্রণালয় নির্বাচন করুন <span
                                        className='text-danger'>*</span></option>
                                    {ministryOrganizationAndInstitution && ministryOrganizationAndInstitution.map(ministry => {
                                        return ministry.parent_id === null ? (
                                            <option key={ministry.id} value={ministry.id}>
                                                {ministry.name}
                                            </option>) : '';
                                    })}
                                </select>
                                <FieldErrorMessage errors={errors} name='ministry_id'/>
                            </div>
                            <div className="col-xs-auto col-md mb-3">
                                <label className="form-label">দপ্তর /সংস্থা নাম <span
                                    className='text-danger'>*</span></label>
                                <select
                                    className="form-select"
                                    {...register(`organization_id`)}
                                    onChange={(e) => handleOrgChange(e.target.value)}
                                >
                                    <option value="" style={{color: 'gray'}}>দপ্তর /সংস্থা নির্বাচন করুন</option>
                                    {organization && organization.map(org => {
                                        return (<option key={org.id} value={org.id}>
                                            {org.name}
                                        </option>);
                                    })}
                                </select>
                                <FieldErrorMessage errors={errors} name='organization_id'/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-xs-auto col-md mb-3">
                                <label className="form-label">প্রতিষ্ঠানের নাম </label>
                                <select
                                    className="form-select"
                                    {...register(`institution_id`)}
                                >
                                    <option value="" style={{color: 'gray'}}>প্রতিষ্ঠান নির্বাচন করুন</option>
                                    {institutions && institutions.map(ins => {
                                        return (<option key={ins.id} value={ins.id}>
                                            {ins.name}
                                        </option>);
                                    })}
                                </select>
                                <FieldErrorMessage errors={errors} name='institution_id'/>
                            </div>
                            <div className="col-xs-auto col-md mb-3">
                                <label className="form-label">জাতীয় পরিচয়পত্র <span
                                    className='text-danger'>*</span></label>
                                <input type="nid" className="form-control" aria-describedby="nidHelp"
                                       {...register("nid")}/>
                                <FieldErrorMessage errors={errors} name='nid'/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-xs-auto col-md-6 mb-3">
                                <label className="form-label">পাসওয়ার্ড <span
                                    className='text-danger'>*</span></label>
                                <input type="password" className="form-control"
                                       {...register("password")}/>
                                <FieldErrorMessage errors={errors} name='password'/>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <p className='m-0' style={{cursor: 'pointer'}} onClick={() => navigate('/login')}>অলরেডি
                                রেজিস্টার?</p>
                            <button type="submit" className="login_submit rounded border-0">নিবন্ধন</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SignUpForm;