import React from 'react';
import {useParams} from 'react-router-dom';
import Loader from '../../../Components/Loader/Loader';
import {BsThreeDotsVertical} from 'react-icons/bs';
import {Dropdown} from "react-bootstrap";
import useEmployeeById from "../../../Hooks/useEmployeeById";
import SmallView from "../ViewPages/SmallView";


const EmployeeViewPage = () => {
    const {id} = useParams();
    const {basicInfo: {data}, loading} = useEmployeeById(id);
    const currentAddress = (field) => {
        const CURRENT = 1;
        let currentAddress = data?.address.find((address) => address.type === CURRENT);
        console.log(currentAddress)
        if (currentAddress) {
            return currentAddress[field];
        }
        return '';
    }
    const permanentAddress = (field) => {
        const PERMANENT = 2;
        let permanentAddress = data?.address.find((address) => address.type === PERMANENT);
        if (permanentAddress) {
            return permanentAddress[field];
        }
        return '';
    }
    if (loading) {
        return <Loader/>
    }

    return (
        <div className="component_container py-2 my-3">
            <div className="">
                <div className="row">
                    <div className="col-lg-12">
                        <Dropdown className="float-end">
                            {/*<PrintButton content={*/}
                            {/*    <SmallView data={data}*/}
                            {/*               currentAddress={currentAddress}*/}
                            {/*               permanentAddress={permanentAddress}*/}
                            {/*    />*/}
                            {/*}/>*/}
                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                <BsThreeDotsVertical/>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href={`/employee-info/${id}/view`}>View</Dropdown.Item>
                                <Dropdown.Item href={`/employee-info/${id}/view-full`}>Full View</Dropdown.Item>
                                {/*<Dropdown.Item*/}
                                {/*    href={`/employee-info/${id}/view-attachments`}>Attachments</Dropdown.Item>*/}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>

                <SmallView data={data}
                           currentAddress={currentAddress}
                           permanentAddress={permanentAddress}
                />
            </div>
        </div>
    );
};

export default EmployeeViewPage;