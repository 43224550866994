import {formatDate, translateToBanglaDigit} from "../../../helpers/helpers";
import Table from "react-bootstrap/Table";
import '../../../App.css'

const SmallView = ({data, currentAddress, permanentAddress}) => {
    return (
        <>
            <div className="row">

                <div className="col-lg-12 mt-3">
                    <div className="card">
                        <h5 className="card-header card-header-colored">ব্যক্তিগত তথ্য</h5>
                        <div className="card-body">
                            <table>
                                <tbody>
                                <tr>
                                    <th style={{width: '70%'}}>নাম (বাংলায়)</th>
                                    <th> &nbsp; : &nbsp; </th>
                                    <td>{data?.name_in_bn}</td>
                                </tr>
                                <tr>
                                    <th>নাম (ইংরেজিতে)</th>
                                    <th> &nbsp; : &nbsp; </th>
                                    <td>{data?.name_in_en}</td>
                                </tr>
                                <tr>
                                    <th>জন্ম তারিখ</th>
                                    <th> &nbsp; : &nbsp; </th>
                                    <td>{translateToBanglaDigit(data?.date_of_birth_view)}</td>
                                </tr>
                                <tr>
                                    <th>মাতার নাম (বাংলায়)</th>
                                    <th> &nbsp; : &nbsp; </th>
                                    <td>{data?.mother_name_in_bn}</td>
                                </tr>
                                <tr>
                                    <th>মাতার নাম (ইংরেজিতে)</th>
                                    <th> &nbsp; : &nbsp; </th>
                                    <td>{data?.mother_name_in_en}</td>
                                </tr>
                                <tr>
                                    <th>পিতার নাম (বাংলায়)</th>
                                    <th> &nbsp; : &nbsp; </th>
                                    <td>{data?.father_name_in_bn}</td>
                                </tr>
                                <tr>
                                    <th>পিতার নাম (ইংরেজিতে)</th>
                                    <th> &nbsp; : &nbsp; </th>
                                    <td>{data?.father_name_in_en}</td>
                                </tr>
                                <tr>
                                    <th>বৈবাহিক অবস্থা</th>
                                    <th> &nbsp; : &nbsp; </th>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th>ব্যাচ নম্বর</th>
                                    <th> &nbsp; : &nbsp; </th>
                                    <td>{data?.batch_no}</td>
                                </tr>
                                <tr>
                                    <th>ক্যাডার</th>
                                    <th> &nbsp; : &nbsp; </th>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 mt-3">
                    <div className="card">
                        <h5 className="card-header card-header-colored">ঠিকানা তথ্য</h5>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-6">
                                    <table style={{width: '100%'}}>
                                        <thead>
                                        <tr style={{borderBottom: '1px solid #ddd'}}>
                                            <th colSpan="3">বর্তমান ঠিকানা</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <th>বিভাগ</th>
                                            <th> &nbsp; : &nbsp; </th>
                                            <td>{currentAddress('division')}</td>
                                        </tr>
                                        <tr>
                                            <th>জেলা</th>
                                            <th> &nbsp; : &nbsp; </th>
                                            <td>{currentAddress('district')}</td>
                                        </tr>
                                        <tr>
                                            <th>উপজেলা</th>
                                            <th> &nbsp; : &nbsp; </th>
                                            <td>{currentAddress('upazila')}</td>
                                        </tr>
                                        <tr>
                                            <th>ডাকঘর</th>
                                            <th> &nbsp; : &nbsp; </th>
                                            <td>{currentAddress('post_office')}</td>
                                        </tr>
                                        <tr>
                                            <th>বাসা নম্বর</th>
                                            <th> &nbsp; : &nbsp; </th>
                                            <td>{currentAddress('house_no')}</td>
                                        </tr>
                                        <tr>
                                            <th>রাস্তা নম্বর</th>
                                            <th> &nbsp; : &nbsp; </th>
                                            <td>{currentAddress('road_no')}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-6">
                                    <table style={{width: '100%'}}>
                                        <thead>
                                        <tr style={{borderBottom: '1px solid #ddd'}}>
                                            <th colSpan="3">স্থায়ী ঠিকানা</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <th>বিভাগ</th>
                                            <th> &nbsp; : &nbsp; </th>
                                            <td>{permanentAddress('division')}</td>
                                        </tr>
                                        <tr>
                                            <th>জেলা</th>
                                            <th> &nbsp; : &nbsp; </th>
                                            <td>{permanentAddress('district')}</td>
                                        </tr>
                                        <tr>
                                            <th>উপজেলা</th>
                                            <th> &nbsp; : &nbsp; </th>
                                            <td>{permanentAddress('upazila')}</td>
                                        </tr>
                                        <tr>
                                            <th>ডাকঘর</th>
                                            <th> &nbsp; : &nbsp; </th>
                                            <td>{permanentAddress('post_office')}</td>
                                        </tr>
                                        <tr>
                                            <th>বাসা নম্বর</th>
                                            <th> &nbsp; : &nbsp; </th>
                                            <td>{permanentAddress('house_no')}</td>
                                        </tr>
                                        <tr>
                                            <th>রাস্তা নম্বর</th>
                                            <th> &nbsp; : &nbsp; </th>
                                            <td>{permanentAddress('road_no')}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 mt-3">
                    <div className="card">
                        <h5 className="card-header card-header-colored">প্রাতিষ্ঠানিক তথ্য</h5>
                        <div className="card-body">
                            <Table bordered responsive={true}>
                                <thead>
                                <tr>
                                    <th>শিক্ষাগত যোগ্যতা</th>
                                    <th>শিক্ষা প্রতিষ্ঠান</th>
                                    <th>বোর্ড</th>
                                    <th>বিভাগ / বিষয় / অর্জিত ডিগ্রি</th>
                                    <th>অর্জিত ফলাফল</th>
                                    <th>পাশের বছর</th>
                                    <th>রোল নাম্বার</th>
                                    <th>সেশন</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data?.hasOwnProperty('educational_infos') && data.educational_infos.map((info, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td>{info.type_text}</td>
                                                <td>{info.institute}</td>
                                                <td>{info.board_text}</td>
                                                <td>{info.department}</td>
                                                <td>{info.grade}</td>
                                                <td>{info.passing_year}</td>
                                                <td>{info.roll_no}</td>
                                                <td>{info.session}</td>
                                            </tr>
                                        </>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 mt-3">
                    <div className="card">
                        <h5 className="card-header card-header-colored">পদোন্নতি তথ্য</h5>
                        <div className="card-body">
                            <Table bordered>
                                <thead>
                                <tr>
                                    <th>পদোন্নতি প্রাপ্ত পদ</th>
                                    <th>মন্ত্রণালয় /দপ্তর /সংস্থা/প্রতিষ্ঠানের নাম</th>
                                    <th>যোগদানের তারিখ</th>
                                    <th>আদেশ নম্বর</th>
                                    <th>আদেশের তারিখ</th>
                                    <th>শেষ কর্মদিবসের তারিখ</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data?.hasOwnProperty('joining_infos') && data.joining_infos.map((info, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td>{info.designation}</td>
                                                <td>{info.ministry_name}</td>
                                                <td>{info.joining_date && translateToBanglaDigit(formatDate(info.joining_date))}</td>
                                                <td>{info.order_no}</td>
                                                <td>{info.order_date && translateToBanglaDigit(formatDate(info.order_date))}</td>
                                                <td>{info.last_working_date && translateToBanglaDigit(formatDate(info.last_working_date))}</td>
                                            </tr>
                                        </>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 mt-3">
                    <div className="card">
                        <h5 className="card-header card-header-colored">যোগদানের তথ্য</h5>
                        <div className="card-body">
                            <Table bordered>
                                <thead>
                                <tr>
                                    <th>পদোন্নতি প্রাপ্ত পদ</th>
                                    <th>মন্ত্রণালয় /দপ্তর /সংস্থা/প্রতিষ্ঠানের নাম</th>
                                    <th>যোগদানের তারিখ</th>
                                    <th>আদেশ নম্বর</th>
                                    <th>আদেশের তারিখ</th>
                                    <th>শেষ কর্মদিবসের তারিখ</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data?.hasOwnProperty('joining_infos') && data.promotion_infos.map((info, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td>{info.designation}</td>
                                                <td>{info.ministry_name}</td>
                                                <td>{info.joining_date && translateToBanglaDigit(formatDate(info.joining_date))}</td>
                                                <td>{info.order_no}</td>
                                                <td>{info.order_date && translateToBanglaDigit(formatDate(info.order_date))}</td>
                                                <td>{info.last_working_date && translateToBanglaDigit(formatDate(info.last_working_date))}</td>
                                            </tr>
                                        </>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default SmallView;