import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import useEmployeeInfo from '../../Hooks/useEmployeeInfo';
import axios from 'axios';
import Loader from '../../Components/Loader/Loader';
import EmployeeResearch from '../../Components/EmployeeInfo/EmployeeResearch';
import { payloadAsFormData, url } from '../../helpers/helpers';
import { ToastContainer, toast } from 'react-toastify';

const EmployeeResearchPage = ({permissions}) => {
    const {id} = useParams();
    const {basicInfo, loading, error} = useEmployeeInfo(id);

    console.log(basicInfo?.data);

    const { register, control, handleSubmit, setValue, formState: {errors}, reset } = useForm({
        defaultValues: {
            research_info: [
                {
                    name: "",
                    publish_date: "",
                    institute: "",
                    comment: "",
                    attachments: ""
                }
            ]
        }
    });

    const {fields: XCResearshList, append: appendXCResearchList, remove: removeXCResearchList} = useFieldArray({
        control,
        name:'research_info'
    });

    useEffect(() => {
        if (basicInfo?.data?.research_infos.length > 0) {
            setValue(`research_info`, basicInfo?.data?.research_infos);
        } else {
            setValue(`research_info`, [
                {
                    name: "",
                    publish_date: "",
                    institute: "",
                    comment: "",
                    attachments: ""
                }
            ]);
        }
    }, [basicInfo?.data]);


    const onSubmit = (data) => {
        let formData = new FormData();
        formData = payloadAsFormData(formData, 'basic_info', basicInfo?.data)

        for (let topIndex = 0; topIndex < data.research_info?.length; topIndex++ ) {
            let payload = data.research_info[topIndex];
            formData = payloadAsFormData(formData, `research_info[${topIndex}]`, payload)
        }

        axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/employee`, formData, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                "Content-Type": "multipart/form-data"
            }
        }).then(response => {
                if (response.data?.message === 'Success') {
                    toast.success('Saved successful.');
                }
            })
            .catch(error => {
                console.error(error);
        });
    }

    const deleteAttachment = async (id) => {
        try {
            const accessToken = localStorage.getItem("accessToken");
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const {data} = await axios.delete(url(`attachment/${id}`), {headers});
            if (data) {
                console.log(data.data)
                toast.success('Attachment deleted successful.')
                window.location.reload()
            }
        } catch (e) {
            toast.error('something went wrong')
        }
    }

    if(loading){
        return <Loader />
    }


    return (
        <div className='component_container py-2 my-3 py-md-5 my-md-5'>
            <ToastContainer />
            <EmployeeResearch
                register={register}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                errors={errors}
                reset={reset}
                XCResearshList={XCResearshList}
                appendXCResearchList={appendXCResearchList}
                removeXCResearchList={removeXCResearchList}
                deleteAttachment={deleteAttachment}
                permissions={permissions}
                userName={basicInfo?.data?.name_in_bn}
            />
        </div>
    );
};

export default EmployeeResearchPage;