import React from 'react';
import {TbFileUpload} from 'react-icons/tb';
import NestedFormIncDec from '../EmployeeCommonSection/ NestedFormIncDec';
import EmployeeInfoHeader from '../EmployeeCommonSection/EmployeeInfoHeader';
import FormSubmitBtn from '../EmployeeCommonSection/FormSubmitBtn';
import FourColumn from '../EmployeeCommonSection/FourColumn';
import ThreeColumn from '../EmployeeCommonSection/ThreeColumn';
import EmployeeHeader from '../EmployeeCommonSection/EmployeeHeader';
import FileUpload from '../Utils/FileUpload';
import FieldErrorMessage from "../Utils/FieldErrorMessage";
import { isAdmin } from '../../helpers/helpers';

const EmployeeChild = (
    {
        onSubmit,
        register,
        handleSubmit,
        errors,
        setErrors,
        reset,
        fields,
        append,
        remove,
        children,
        genders,
        bloodGroups,
        deleteAttachment,
        permissions,
        userName
    }) => {
    return (
        <div className='employee_info'>
            <div className='card shadow border-0 mt-2'>
                <div className='card-header'>
                    <EmployeeHeader userName={userName} e_title="সন্তানদের তথ্য" e_num="22004689"/>
                </div>
                <div className='card-body'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {fields.map((item, index) => {
                            let attachments = item.attachments;
                            return (
                                <div key={item.id}>
                                    <NestedFormIncDec fields={fields} append={append} remove={remove} index={index}/>

                                    <div className='row'>
                                        <div className="col-xs-auto col-md mb-3">
                                            <select className="form-select" aria-label="Default select example"
                                                    {...register(`child.${index}.child_no`)}>
                                                <option value="" style={{color: 'gray'}}>নির্বাচন করুন</option>
                                                {
                                                    children.map(child => <option key={child.id}
                                                                                value={child.id}>{child.bn}</option>)
                                                }
                                            </select>
                                            <FieldErrorMessage errors={errors} name="child_no" prefix="children_info"
                                                            index={index}/>
                                        </div>
                                        <FourColumn/>
                                    </div>

                                    <div className='row'>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">জাতীয় পরিচয়পত্র
                                                নম্বর</label>
                                            <input className="form-control"
                                                {...register(`child.${index}.nid`)}
                                            />
                                            <FieldErrorMessage errors={errors} name="nid" prefix="children_info"
                                                            index={index}/>
                                        </div>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">জন্ম নিবন্ধন নম্বর </label>
                                            <input className="form-control" type='text'
                                                {...register(`child.${index}.birth_certificate_no`)}
                                            />
                                            <FieldErrorMessage errors={errors} name="birth_certificate_no"
                                                            prefix="children_info"
                                                            index={index}/>
                                        </div>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">নাম (বাংলায়) <span
                                                className='text-danger'>*</span></label>
                                            <input className="form-control"
                                                {...register(`child.${index}.name_in_bn`)}
                                            />
                                            <FieldErrorMessage errors={errors} name="name_in_bn" prefix="children_info"
                                                            index={index}/>
                                        </div>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">নাম (ইংরেজিতে) <span
                                                className='text-danger'>*</span></label>
                                            <input className="form-control"
                                                {...register(`child.${index}.name_in_en`)}
                                            />
                                            <FieldErrorMessage errors={errors} name="name_in_en" prefix="children_info"
                                                            index={index}/>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">জন্ম তারিখ</label>
                                            <input className="form-control" type="date"
                                                {...register(`child.${index}.date_of_birth`)}
                                            />
                                        </div>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">রক্তের গ্রুপ</label>
                                            <select className="form-select" aria-label="Default select example"
                                                    {...register(`child.${index}.blood_group`)}
                                            >
                                                <option style={{color: 'gray'}} value="">নির্বাচন করুন</option>
                                                {
                                                    bloodGroups.map(bloodGroup => <option key={bloodGroup.id}
                                                                                        value={bloodGroup.id}>{bloodGroup.bn}</option>)
                                                }
                                            </select>
                                        </div>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="" className='form-label'>জেন্ডার</label>
                                            <select className="form-select"
                                                    aria-label="Default select example" {...register(`child.${index}.gender`)}>
                                                <option value="" style={{color: 'gray'}}>নির্বাচন করুন</option>
                                                {
                                                    genders.map(gender => <option key={gender.id}
                                                                                value={gender.id}>{gender.bn}</option>)
                                                }
                                            </select>
                                        </div>
                                        {/*<div className="col-xs-auto col-md mb-3">*/}
                                        {/*    <label htmlFor="inputAddress" className="form-label">স্থায়ী ঠিকানা</label>*/}
                                        {/*    <input className="form-control"*/}
                                        {/*           {...register(`child.${index}.address`)}*/}
                                        {/*    />*/}
                                        {/*</div>*/}
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="" className='form-label'>প্রতিবন্ধী কিনা <span
                                                className='text-danger'>*</span></label>
                                            <select className="form-select"
                                                    aria-label="Default select example" {...register(`child.${index}.is_autistic`)}>
                                                <option value="" style={{color: 'gray'}}>নির্বাচন করুন</option>
                                                <option value="0">প্রতিবন্ধী নয়</option>
                                                <option value="1">প্রতিবন্ধী</option>
                                            </select>
                                            <FieldErrorMessage errors={errors} name="is_autistic" prefix="children_info"
                                                            index={index}/>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="attachments" className="form-label">প্রমাণক</label>
                                            <FileUpload
                                                title="প্রমাণক সংযুক্ত করুন"
                                                register={register}
                                                registerkey={`child.${index}.attachments`}
                                                attachments={attachments}
                                                deleteAttachment={deleteAttachment}
                                            />
                                        </div>
                                        <div className="col-xs-auto col-md mt-2 btn_file text-center"></div>
                                        <div className="col-xs-auto col-md mt-2 btn_file text-center"></div>
                                        <div className="col-xs-auto col-md mt-2 btn_file text-center"></div>
                                    </div>
                                </div>
                            );
                        })}
                        {((isAdmin() || permissions?.includes('edit'))) &&  <FormSubmitBtn setErrors={setErrors} reset={reset}/> }
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EmployeeChild;