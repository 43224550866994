import React, {useEffect, useState} from "react";
import {useFieldArray, useForm} from "react-hook-form";
import {axiosConfig, payloadAsFormData, url} from "../../helpers/helpers";
import axios from "axios";
import {toast} from "react-toastify";
import NestedFormIncDec from "../EmployeeCommonSection/ NestedFormIncDec";
import FileUpload from "../Utils/FileUpload";
import FourColumn from "../EmployeeCommonSection/FourColumn";
import FieldErrorMessage from "../Utils/FieldErrorMessage";

const Edit = () => {

    const [errors, setErrors] = useState([]);

    const {register, setValue, handleSubmit} = useForm();

    const onSubmit = (data) => {
        axios.post(url('password-change'), data, axiosConfig())
            .then(response => {
                setErrors([]);
                toast.success('Updated Successful');
            })
            .catch(error => {
                console.log(error)
                if (error.response.status === 422) {
                    setErrors(error.response.data.errors);
                }
            });
    }

    return (<>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className='card shadow border-0 mt-2'>
                <div className='card-body'>
                    <div className="row">
                        <div className="col-xs-auto col-md mb-3">
                            <label className="form-label">বর্তমান পাসওয়ার্ড <span
                                className='text-danger'>*</span></label>
                            <input type="password" className="form-control" aria-describedby="passwordHelp"
                                   {...register("current_password")}/>
                            <FieldErrorMessage errors={errors} name='current_password'/>
                        </div>
                        <div className="col-xs-auto col-md mb-3">
                            <label className="form-label">নতুন পাসওয়ার্ড <span
                                className='text-danger'>*</span></label>
                            <input type="password" className="form-control" aria-describedby="new_passwordHelp"
                                   {...register("password")}/>
                            <FieldErrorMessage errors={errors} name='password'/>
                        </div>
                        <div className="col-xs-auto col-md mb-3">
                            <label className="form-label">কনফার্ম নতুন পাসওয়ার্ড <span
                                className='text-danger'>*</span></label>
                            <input type="password" className="form-control"
                                   aria-describedby="password_confirmationHelp"
                                   {...register("password_confirmation")}/>
                            <FieldErrorMessage errors={errors} name='password_confirmation'/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card shadow border-0 mt-2">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <button className="float-end btn btn-sm btn-success">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </>);
}

export default Edit;