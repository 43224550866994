import React from 'react';
import {Link} from 'react-router-dom';
import NestedFormIncDec from '../EmployeeCommonSection/ NestedFormIncDec';
import EmployeeInfoHeader from '../EmployeeCommonSection/EmployeeInfoHeader';
import FormSubmitBtn from '../EmployeeCommonSection/FormSubmitBtn';
import FourColumn from '../EmployeeCommonSection/FourColumn';
import EmployeeHeader from '../EmployeeCommonSection/EmployeeHeader';
import Select2Wrapper from "../Utils/Select2Wrapper";
import FieldErrorMessage from "../Utils/FieldErrorMessage";
import { isAdmin } from '../../helpers/helpers';

const EmployeeTravel = (
    {
        onSubmit,
        register,
        handleSubmit,
        errors,
        fields,
        append,
        remove,
        transfer,
        ministryType,
        countries,
        getValues,
        setErrors,
        setValue,
        reset,
        permissions,
        userName
    }) => {

    return (
        <div className='employee_info'>
            <div className='card shadow border-0 mt-2'>
                <div className='card-header'>
                    <EmployeeHeader userName={userName} e_title="ভ্রমণ সংক্রান্ত তথ্য"/>
                </div>
                <div className='card-body'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {fields.map((item, index) => {
                            return (
                                <div key={item.id}>
                                    <NestedFormIncDec fields={fields} append={append} remove={remove} index={index}/>
                                    <div className='row'>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">ভ্রমণের ধরণ<span
                                                className='text-danger'>*</span></label>
                                            <select className="form-select"
                                                    aria-label="Default select example" {...register(`travell.${index}.type`)}>
                                                <option value="" style={{color: 'gray'}}>নির্বাচন করুন</option>
                                                {
                                                    transfer.map(transfer => <option key={transfer.id}
                                                                                    value={transfer.id}>{transfer.bn}</option>)
                                                }
                                            </select>
                                            <FieldErrorMessage errors={errors} name="type" prefix="travel_info"
                                                            index={index}/>
                                        </div>
                                        <FourColumn/>
                                    </div>
                                    <div className='row'>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">দেশের নাম <span
                                                className='text-danger'>*</span></label>

                                            <Select2Wrapper
                                                value={getValues(`travell.${index}.country_name`)}
                                                onChange={(value) => setValue(`travell.${index}.country_name`, value)}
                                                options={countries}
                                            />
                                            <FieldErrorMessage errors={errors} name="country_name" prefix="travel_info"
                                                            index={index}/>
                                        </div>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">মন্ত্রণালয়ের নাম <span
                                                className='text-danger'>*</span></label>
                                            <select className="form-select"
                                                    aria-label="Default select example" {...register(`travell.${index}.ministry_name`)}>
                                                <option value="" style={{color: 'gray'}}>নির্বাচন করুন</option>
                                                {
                                                    ministryType?.map(ministry => <option key={ministry.id}
                                                                                        value={ministry.id}>{ministry.bn}</option>)
                                                }
                                            </select>
                                            <FieldErrorMessage errors={errors} name="ministry_name" prefix="travel_info"
                                                            index={index}/>
                                        </div>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">ভ্রমণাদেশ অনুমতির
                                                নম্বর <span className='text-danger'>*</span></label>
                                            <input className="form-control"
                                                {...register(`travell.${index}.travel_order_no`)}
                                            />
                                            <FieldErrorMessage errors={errors} name="travel_order_no" prefix="travel_info"
                                                            index={index}/>
                                        </div>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">ভ্রমণের স্থান</label>
                                            <input className="form-control"
                                                {...register(`travell.${index}.travel_place`)}
                                            />
                                        </div>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">ভ্রমণের কারণ</label>
                                            <input className="form-control"
                                                {...register(`travell.${index}.travel_cause`)}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">ভ্রমণ শুরুর তারিখ</label>
                                            <input className="form-control" type="date"
                                                {...register(`travell.${index}.travel_start_date`)}
                                            />
                                        </div>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">ভ্রমণ শেষের তারিখ</label>
                                            <input className="form-control" type="date"
                                                {...register(`travell.${index}.travel_stop_date`)}
                                            />
                                        </div>
                                        <div className="col-xs-auto col-md">
                                            <label htmlFor="inputAddress" className="form-label">মন্তব্য</label>
                                            <input className="form-control"
                                                {...register(`travell.${index}.remarks`)}
                                            />
                                        </div>
                                        <div className="col-xs-auto col-md"></div>
                                        <div className="col-xs-auto col-md"></div>
                                    </div>
                                </div>
                            );
                        })}
                        {((isAdmin() || permissions?.includes('edit'))) &&  <FormSubmitBtn setErrors={setErrors} reset={reset}/> }
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EmployeeTravel;