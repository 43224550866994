import axios from 'axios';
import React from 'react';
import {useState} from 'react';
import {useEffect} from 'react';
import Dashboard from '../../Components/Dashboard/Dashboard';
import Loader from '../../Components/Loader/Loader';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {authUser, axiosConfig, isAdmin, url} from "../../helpers/helpers";

const DashboardPage = ({permissions}) => {
    const navigate = useNavigate()
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isSearching, setIsSearching] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPage] = useState(3);
    const [query, setQuery] = useState('');
    const [nid, setNid] = useState('');
    const [gender, setGender] = useState('');
    const [perPage, setPerPage] = useState(20);
    const [ministry, setMinistry] = useState('');
    const [organization, setOrganization] = useState('');
    const [institution, setInstitution] = useState('');
    const [designation, setDesignation] = useState('');
    const [printableEmployees, setPrintableEmployees] = useState();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [bloodGroup, setBloodGroup] = useState(null);
    const [cadre, setCadre] = useState(null);
    const [religion, setReligion] = useState(null);
    const [classId, setClassId] = useState(null);
    const [married, setMarried] = useState(null);
    const [userStatus, setUserStatus] = useState(1);
    const [percentage, setPercentage] = useState(null);
    const [allData, setAllData] = useState(null);
    const [educationInfo, setEducationInfo] = useState(null);
    const [subject, setSubject] = useState(null);
    const queryParams = new URLSearchParams(useLocation().search);

    useEffect(() => {
        handlePageChange()
    }, [currentPage, perPage]);

    useEffect(() => {
        if (!percentage) {
            return;
        }
        let filterData = allData.data.filter(item => item.percentage < 100)
        if (percentage == 1) {
            filterData = allData.data.filter(item => item.percentage == 100)
        }
        setData({
            ...data,
            data: filterData
        })
    }, [percentage]);

    const handlePageChange = () => {

        let organizationId = organization
        if (!isAdmin()) {
            organizationId = !organization ? authUser()?.organization_id : organization;
        }

        const qGender = queryParams.get('gender_id');
        const qOrg = queryParams.get('organization_id');
        setGender(gender ? gender : qGender)
        setOrganization(organizationId ? organizationId : qOrg)
        
        const searchObject = {
            page: currentPage,
            search: query,
            nid: nid,
            gender: gender ? gender : qGender,
            designation: designation,
            ministry: ministry,
            startDate: startDate,
            endDate: endDate,
            organization: organizationId ? organizationId : qOrg,
            institution: institution,
            bloodGroup: bloodGroup,
            perPage: perPage,
            cadre: cadre,
            religion: religion,
            classId: classId,
            married: married,
            userStatus: userStatus,
            percentage: percentage,
            educationInfo: educationInfo,
            subject: subject,
        };
        // setIsLoading(true);
        setIsSearching(true);
        axios.get(url(`employee`), {...axiosConfig(), params: searchObject})
            .then(response => {
                setData(response.data?.data);
                setAllData(response.data?.data);
                setCurrentPage(response.data?.data?.current_page);
                setTotalPage(Math.ceil(response.data?.data?.total / response.data?.data?.per_page));
                setIsLoading(false);
            })
            .catch(error => {
                console.error(error);
                setIsLoading(false);
                if (error?.response?.status === 401) {
                    navigate('/login')
                }
            }).finally(() => {
                setIsSearching(false);
        });
    };

    const loadPrintableEmployees = async () => {
        try {
            let organizationId = organization
            if (!isAdmin()) {
                organizationId = !organization ? authUser()?.organization_id : organization;
            }
            const searchObject = {
                search: query,
                gender: gender,
                designation: designation,
                ministry: ministry,
                startDate: startDate,
                endDate: endDate,
                organization: organizationId,
                institution: institution,
                bloodGroup: bloodGroup,
                cadre: cadre,
                religion: religion,
                classId: classId,
                married: married,
                userStatus: userStatus,
                percentage: percentage,
                educationInfo: educationInfo,
                subject: subject,
            };
            const {data: {data}} = await axios.get(url(`fetch-printable-employees`), {...axiosConfig(), params: searchObject});
            setPrintableEmployees(data);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (!localStorage.getItem('accessToken')) {
            navigate('/login')
        }
    }, [localStorage.getItem('accessToken')])

    return (
        <>
            {
                isLoading ? (<Loader/>) : (
                    <div className="component_container py-2 my-3">
                        <Dashboard data={data}
                            setData={setData}
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                            handlePageChange={handlePageChange}
                            setCurrentPage={setCurrentPage}
                            query={query}
                            setQuery={setQuery}
                            nid={nid}
                            setNid={setNid}
                            gender={gender}
                            setGender={setGender}
                            designation={designation}
                            setDesignation={setDesignation}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            bloodGroup={bloodGroup}
                            setBloodGroup={setBloodGroup}
                            ministry={ministry}
                            setMinistry={setMinistry}
                            organization={organization}
                            setOrganization={setOrganization}
                            institution={institution}
                            setInstitution={setInstitution}
                            perPage={perPage}
                            setPerPage={setPerPage}
                            printableEmployees={printableEmployees}
                            setPrintableEmployees={setPrintableEmployees}
                            loadPrintableEmployees={loadPrintableEmployees}
                            permissions={permissions}
                            isSearching={isSearching}
                            cadre={cadre}
                            setCadre={setCadre}
                            classId={classId}
                            setClassId={setClassId}
                            married={married}
                            setMarried={setMarried}
                            religion={religion}
                            setReligion={setReligion}
                            userStatus={userStatus}
                            setUserStatus={setUserStatus}
                            percentage={percentage}
                            setPercentage={setPercentage}
                            educationInfo={educationInfo}
                            setEducationInfo={setEducationInfo}
                            subject={subject}
                            setSubject={setSubject}
                        />
                    </div>
                )
            }
        </>
    );
};

export default DashboardPage;