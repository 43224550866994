import React from 'react';
import EmployeeInfoHeader from '../EmployeeCommonSection/EmployeeInfoHeader';

const TravelReport = ({travelInfo}) => {
    const travelData = travelInfo?.data?.map(tr=>tr)[0]
    const eduInfo = travelInfo?.data[0]?.educational_infos[0]

    return (
        <div className='component_container py-2 my-3 py-md-5 my-md-5'>
            <div className='employee_info'>
                <EmployeeInfoHeader e_title="ভ্রমণ সংক্রান্ত তথ্য" e_num="22004689" /> 

                <h5 className='text-center mt-5 fw-bold fs-5'>
                    <ins>শিল্প মন্ত্রণালয়ে কর্মরত অতিরিক্ত সচিবগণের বিগত ০১ বছরের বিদেশ ভ্রমন সংক্রান্ত তথ্য (তালিকা প্রস্তুত ২৯.০৩.২০২৩)</ins>    
                </h5>

                <div className='table-responsive mt-4'>
                    <table className='table table-bordered border-dark text-center' style={{fontSize: '14px'}}>
                        <thead>
                            <tr>
                                <th>ক্রম নং</th>
                                <th>কর্মকর্তার নাম ও পদবী</th>
                                <th>জন্ম তারিখ</th>
                                <th>শিক্ষাগত যোগ্যতা</th>
                                <th>এ মন্ত্রণালয়ে যোগদানের তারিখ</th>
                                <th>এ মন্ত্রণালয় হতে বিদেশ ভ্রমন সংক্রান্ত তথ্য</th>
                                <th>সর্বশেষ ভ্রমণ</th>
                                <th>জনপ্রশাসন / অন্য মন্ত্রণালয়ের মাধ্যমে বিদেশ ভ্রমণ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                travelData?.travel_infos?.map((travel, index)=>{
                                    return (
                                        <tr>
                                            <td>{index+1}</td>
                                            <td>
                                                {travelData?.name_in_en} <br />
                                                <span style={{color: 'gray'}}>{travelData?.designation}</span>
                                            </td>
                                            <td>{travelData?.date_of_birth}</td>
                                            <td>
                                                {
                                                    eduInfo?.type === 1 ? 'এস এস সি / সমমান' :
                                                    eduInfo?.type === 2 ? 'এইচ এস সি / সমমান' :
                                                    eduInfo?.type === 2 ? 'স্নাতক / পাশ কোর্স' : 'স্নাতকোত্তর'
                                                }
                                            </td>
                                            <td>{travelData?.joining_date}</td>
                                            <td>{travel?.country_name}</td>
                                            <td>{travel?.travel_stop_date}</td>
                                            <td>{travel?.ministry_name === 1 ? 'শিল্প মন্ত্রণালয়' : 'অন্য মন্ত্রণালয়'}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default TravelReport;