import React, {useEffect, useState} from "react";
import {useFieldArray, useForm} from "react-hook-form";
import {asset, axiosConfig, payloadAsFormData, url} from "../../helpers/helpers";
import axios from "axios";
import {toast} from "react-toastify";
import NestedFormIncDec from "../EmployeeCommonSection/ NestedFormIncDec";
import FileUpload from "../Utils/FileUpload";
import FourColumn from "../EmployeeCommonSection/FourColumn";
import FieldErrorMessage from "../Utils/FieldErrorMessage";
import avatar from "../../Assets/avatar.png"

const Edit = ({user}) => {

    const [errors, setErrors] = useState([]);

    const {register, setValue, handleSubmit, getValues} = useForm();

    useEffect(() => {
        Object.entries(user).forEach(([key, value]) => setValue(key, value));
    }, [user])

    const onSubmit = (data) => {
        axios.post(url('users'), data, axiosConfig())
            .then(response => {
                setErrors([]);
                toast.success('Updated Successful');
            })
            .catch(error => {
                if (error.response.status === 422) {
                    setErrors(error.response.data.errors);
                }
            });
    }

    return (<>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className='card shadow border-0 mt-2'>
                <div className='card-body'>
                    <div className="row">
                        <div className="col-8">
                            <div className="row">
                                <div className="col-12">
                                    <label className="form-label">ইউনিক আইডি </label>
                                    <input type="text" className="form-control" disabled aria-describedby="nameHelp" value={user?.unique_id}/>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12">
                                    <label className="form-label">নাম </label>
                                    <input type="text" className="form-control" aria-describedby="nameHelp"
                                           {...register("name")}/>
                                    <FieldErrorMessage errors={errors} name='name'/>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12">
                                    <label className="form-label">কর্মবিভাগ <span
                                        className='text-danger'>*</span></label>
                                    <select className="form-select" aria-label="Default select example"
                                            {...register("department")} >
                                        <option value="">নির্বাচন করুন</option>
                                        <option value="1">প্রথম</option>
                                        <option value="2">দ্বিতীয়</option>
                                        <option value="3">তৃতীয়</option>
                                        <option value="4">চতুর্থ</option>
                                    </select>
                                    <FieldErrorMessage errors={errors} name='department'/>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12">
                                    <label className="form-label">ইমেইল <span
                                        className='text-danger'>*</span></label>
                                    <input type="email" className="form-control" aria-describedby="emailHelp"
                                           {...register("email")}/>
                                    <FieldErrorMessage errors={errors} name='email'/>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12">
                                    <label htmlFor="phone" className="form-label">ফোন নম্বর <span
                                        className='text-danger'>*</span></label>
                                    <input type="text" className="form-control"
                                           {...register("phone")}/>
                                    <FieldErrorMessage errors={errors} name='phone'/>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <center>
                                <img style={{height: '247px'}} src={user?.image ? asset(user.image) : avatar} alt=""/>
                            </center>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card shadow border-0 mt-2">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <button className="float-end btn btn-sm btn-success">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </>);
}

export default Edit;