import React from 'react';
import {TbFileUpload} from "react-icons/tb";
import NestedFormIncDec from '../EmployeeCommonSection/ NestedFormIncDec';
import EmployeeInfoHeader from '../EmployeeCommonSection/EmployeeInfoHeader';
import FormSubmitBtn from '../EmployeeCommonSection/FormSubmitBtn';
import EmployeeHeader from '../EmployeeCommonSection/EmployeeHeader';
import FileUpload from '../Utils/FileUpload';
import { isAdmin } from '../../helpers/helpers';

const EmployeeLanguageEfficiency = (
    {
        onSubmit,
        register,
        handleSubmit,
        errors,
        fields,
        append,
        remove,
        reset,
        languageProficiency,
        deleteAttachment,
        permissions,
        userName
    }) => {

    return (
        <div className='employee_info'>
            <div className='card shadow border-0 mt-2'>
                <div className='card-header'>
                    <EmployeeHeader userName={userName} e_title="ভাষাগত দক্ষতা সংক্রান্ত তথ্য"/>
                </div>
                <div className='card-body'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {fields.map((item, index) => {
                            let attachments = item.attachments;
                            return (
                                <div key={item.id}>
                                    <NestedFormIncDec fields={fields} append={append} remove={remove} index={index}/>
                                    <div className='row'>
                                        <div className='col-xs-auto col-md mb-3'>
                                            <label htmlFor="inputAddress" className="form-label">ভাষার নাম<span
                                                className='text-danger'>*</span></label>
                                            <input className="form-control"
                                                    {...register(`language.${index}.language_name`, {required: true})}
                                            />
                                            {errors?.language?.[index]?.language_name &&
                                                <small className='text-danger'>This field is required.</small>}
                                        </div>
                                        <div className="col-xs-auto col-md">
                                            <label htmlFor="inputAddress" className="form-label">দক্ষতার ধরণ<span
                                                className='text-danger'>*</span></label>
                                            <select className="form-select"
                                                    aria-label="Default select example" {...register(`language.${index}.expertness`, {required: true})}>
                                                <option value="" style={{color: 'gray'}}>নির্বাচন করুন</option>
                                                {
                                                    languageProficiency.map(lp => <option key={lp.id}
                                                                                            value={lp.id}>{lp.bn}</option>)
                                                }
                                            </select>
                                            {errors?.language?.[index]?.expertness &&
                                                <small className='text-danger'>This field is required.</small>}
                                        </div>
                                        <div className="col-xs-auto col-md mt-2 btn_file text-center">
                                            <label htmlFor="attachments" className="form-label"></label>
                                            <br/>
                                            <FileUpload
                                                title="প্রমাণক সংযুক্ত করুন"
                                                register={register}
                                                registerkey={`language.${index}.attachments`}
                                                attachments={attachments}
                                                deleteAttachment={deleteAttachment}
                                            />
                                        </div>
                                        <div className='col-xs-auto col-md'></div>
                                        <div className='col-xs-auto col-md'></div>
                                    </div>
                                </div>
                            );
                        })}
                        {((isAdmin() || permissions?.includes('edit'))) &&  <FormSubmitBtn reset={reset}/> }
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EmployeeLanguageEfficiency;