import React from 'react';

const ThreeColumn = () => {
    return (
        <>
            <div className='col-xs-auto col-md'></div>
            <div className='col-xs-auto col-md'></div>
            <div className='col-xs-auto col-md'></div>
        </>
    );
};

export default ThreeColumn;