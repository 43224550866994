import React from 'react';
import './pagination.css';

const Pagination = ({currentPage, totalPages, onPageChange, firstAndLastPageShow}) => {
    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxVisiblePages = 3;

        let startPage = Math.max(currentPage - maxVisiblePages, 1);
        let endPage = Math.min(currentPage + maxVisiblePages, totalPages);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => onPageChange(i)}>
                        {i}
                    </button>
                </li>
            );
        }

        return pageNumbers;
    };

    return (
        <nav aria-label="Pagination navigation example">
            <ul className="pagination justify-content-center">
                {firstAndLastPageShow && <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => onPageChange(1)}>
                        First Page
                    </button>
                </li>}
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => onPageChange(currentPage - 1)}
                    >
                        Previous
                    </button>
                </li>
                {renderPageNumbers()}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => onPageChange(currentPage + 1)}
                    >
                        Next
                    </button>
                </li>
                {firstAndLastPageShow && <li className={`page-item ${totalPages === currentPage ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => onPageChange(totalPages)}>
                        Last Page
                    </button>
                </li>}
            </ul>
        </nav>
    );
};

export default Pagination;