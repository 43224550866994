import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginForm from './LoginForm';
import "./loginSignUp.css";
import LoginSignupLogo from './LoginSignupLogo';

const Login = ({onSubmit, errors}) => {

    useEffect(()=>{
        if (document.body.style.marginLeft = '140px') {
            document.body.style.marginLeft = '0px'
            document.body.style.transition = 'all 0.4s'
        }
    },[document.body.style.marginLeft])

    return (
        <div className='login_container'>
            <div className='clip_path'></div>
            <div className='container'>
                <div className="row gx-2 align-items-center justify-content-center login_content">
                    <LoginSignupLogo />
                    <LoginForm onSubmit={onSubmit} errors={errors}/>
                </div>
            </div>
        </div>
    );
};

export default Login;