import React, { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import EmployeeExtraCurricular from '../../Components/EmployeeInfo/EmployeeExtraCurricular';
import { useParams } from 'react-router-dom';
import useEmployeeInfo from '../../Hooks/useEmployeeInfo';
import axios from 'axios';
import Loader from '../../Components/Loader/Loader';
import { payloadAsFormData, url } from '../../helpers/helpers';
import { ToastContainer, toast } from 'react-toastify';

const EmployeeExtraCurricularPage = ({permissions}) => {
    const {id} = useParams();
    const {basicInfo, loading, error} = useEmployeeInfo(id);

    const { register, control, handleSubmit, setValue, formState: {errors}, reset } = useForm({
        defaultValues: {
            xc_activities: [
                {
                    type: "",
                    start_date: "",
                    end_date: "",
                    work_place: "",
                    work_description: "",
                    associated_person_or_institute: "",
                    attachment: ""
                }
            ]
        }
    });

    const {fields: XCActivitiesList, append: appendXCActivitiesList, remove: removeXCActivitiesList} = useFieldArray({
        control,
        name:'xc_activities'
    });

    useEffect(() => {
        if (basicInfo?.data?.extra_curriculum_infos.length > 0) {
            setValue(`xc_activities`, basicInfo?.data?.extra_curriculum_infos);
        } else {
            setValue(`xc_activities`, [
                {
                    type: "",
                    start_date: "",
                    end_date: "",
                    work_place: "",
                    work_description: "",
                    associated_person_or_institute: "",
                    attachment: ""
                }
            ]);
        }
    }, [basicInfo?.data]);

    // const onSubmit = (data) => {
    //     axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/employee`, {
    //         basic_info: {...basicInfo?.data},
    //         extra_curriculum_info: [...data.xc_activities],
    //         // research_info: [...data.research_info]
    //     }, {
    //         headers: {
    //             authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    //         }
    //     }).then(response => {
    //             if (response.data?.message === 'Success') {
    //                 alert('Posted Successfully')
    //             }
    //         })
    //         .catch(error => {
    //             console.error(error);
    //     });
    // }

    const onSubmit = (data) => {
        let formData = new FormData();
        formData = payloadAsFormData(formData, 'basic_info', basicInfo?.data)

        for (let topIndex = 0; topIndex < data.xc_activities.length; topIndex++ ) {
            let payload = data.xc_activities[topIndex];
            formData = payloadAsFormData(formData, `extra_curriculum_info[${topIndex}]`, payload)
        }

        axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/employee`, formData, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                "Content-Type": "multipart/form-data"
            }
        }).then(response => {
                if (response.data?.message === 'Success') {
                    toast.success('Saved successful.');
                }
            })
            .catch(error => {
                console.error(error);
        });
    }

    const deleteAttachment = async (id) => {
        try {
            const accessToken = localStorage.getItem("accessToken");
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const {data} = await axios.delete(url(`attachment/${id}`), {headers});
            if (data) {
                console.log(data.data)
                toast.success('Attachment deleted successful.')
                window.location.reload()
            }
        } catch (e) {
            toast.error('something went wrong')
        }
    }

    if(loading){
        return <Loader />
    }

    return (
        <div className='component_container py-2 my-3 py-md-5 my-md-5'>
            <ToastContainer />
            <EmployeeExtraCurricular 
                onSubmit={onSubmit}
                register={register}
                handleSubmit={handleSubmit}
                errors={errors}
                reset={reset}
                XCActivitiesList={XCActivitiesList}
                appendXCActivitiesList={appendXCActivitiesList}
                removeXCActivitiesList={removeXCActivitiesList}
                deleteAttachment={deleteAttachment}
                permissions={permissions}
                userName={basicInfo?.data?.name_in_bn}
            />
        </div>
    );
};

export default EmployeeExtraCurricularPage;