import React, { useEffect, useState } from 'react';
import Punishment from '../../Components/Punishment/Punishment';
import { useNavigate } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import axios from 'axios';
import {toast, ToastContainer} from "react-toastify";
import {useParams} from "react-router";
import useEmployeeInfo from "../../Hooks/useEmployeeInfo";
import Loader from '../../Components/Loader/Loader';

const PunishmentPage = ({permissions}) => {
    const {id} = useParams();
    const {basicInfo, loading, error} = useEmployeeInfo(id);
    const navigate = useNavigate();
    const [userId, setUserId] = useState();
    const [users, setUsers] = useState();
    const [userInfo, setUserInfo] = useState();
    const [punishmentType, setPunishmentType] = useState();
    const [dataLoading, setDataLoading] = useState();

    const { register, control, handleSubmit, setValue, formState: { errors } } = useForm({
        defaultValues: {
            punishment: [
                {
                    type: "",
                    reason: "",
                    description: "",
                    duration: "",
                    start_date: "",
                    end_date: "",
                    remarks: ""
                }
            ]
        }
    });

    const {fields, append, remove} = useFieldArray({
        control,
        name: "punishment"
    });

    useEffect(()=>{
        if(userInfo?.disciplinary_infos.length > 0){
            setValue('punishment', userInfo?.disciplinary_infos)
        } else {
            setValue('punishment', [
                {
                    type: "",
                    reason: "",
                    description: "",
                    duration: "",
                    start_date: "",
                    end_date: "",
                    remarks: "",
                }
            ])
        }
    },[userInfo])

    useEffect(() => {
        if (!Object.entries(basicInfo).length) return;
        setUserId(basicInfo?.data?.user_id);
        fetchUserInfo(basicInfo?.data?.user_id)
    }, [basicInfo]);

    const onSubmit = (data) => {
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/employee`, {
            // basic_info: {...basicInfo?.data},
            basic_info: {...userInfo},
            disciplinary_info: [...data.punishment]
        }, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            }
        }).then(response => {
            if (response.data?.message === 'Success') {
                toast.success('Saved successful.');
            }
            })
            .catch(error => {
                console.error(error);
        });
    }

    const fetchUserInfo = async (id) => {
        try {
            const accessToken = localStorage.getItem("accessToken");
            const headers = {
              Authorization: `Bearer ${accessToken}`,
            };
            setDataLoading(true)

            const [userInfo] = await Promise.all([
              axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-user-info/${id}`, {headers}),
            ]);
            setUserInfo(userInfo?.data?.data);
            setDataLoading(false)
            // console.log('user info', userInfo?.data?.data);
        } catch (error) {
            setDataLoading(false)
            console.error(error);
        }
    }

    const fetchPunishmentType = async () => {
        try {
          const accessToken = localStorage.getItem("accessToken");
          const headers = {
            Authorization: `Bearer ${accessToken}`,
          };
          setDataLoading(true)
          const [punishmentType] = await Promise.all([
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-punishment-type`, {headers}),
          ]);
          setDataLoading(false)
          setPunishmentType(punishmentType?.data?.data);
        } catch (error) {
            setDataLoading(false)
          console.error(error);
        }
    };

    useEffect(()=>{
        fetchPunishmentType();
    },[]);

    useEffect(()=>{
        if(!localStorage.getItem('accessToken')){
            navigate('/login')
        }
    },[localStorage.getItem('accessToken')])
    
    if (loading || dataLoading) {
        return <Loader/>
    }
    return (
        <div className='component_container py-2 my-3 py-md-5 my-md-5'>
            <ToastContainer />
            <Punishment
                onSubmit={onSubmit}
                register={register}
                handleSubmit={handleSubmit}
                errors={errors}
                fields={fields}
                append={append}
                remove={remove}
                punishmentType={punishmentType}
                setUsers={setUsers}
                users={users}
                userId={userId}
                permissions={permissions}
                userName={userInfo?.name_in_bn}
            />
        </div>
    );
};

export default PunishmentPage;