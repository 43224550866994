import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import {toast, ToastContainer} from 'react-toastify';
import {authToken, authUser, axiosConfig, url} from "../../../helpers/helpers";
import Ministry from "./Ministry";
import Organization from "./Organization";
import Institution from "./Institution";
import {useForm} from "react-hook-form";
import ministry from "./Ministry";
import Loader from '../../Loader/Loader';

const MinistryOrganizationAndInstitution = ({permissions}) => {
    const navigate = useNavigate();
    const [tab, setTab] = useState('ministry');
    const [ministry, setMinistry] = useState([]);
    const [organization, setOrganization] = useState([]);
    const [institution, setInstitution] = useState([]);
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(true);
    const {register, setValue, handleSubmit} = useForm();
    const axiosHeader = {
        headers: {
            authorization: `Bearer ${authToken()}`,
        }
    }

    const getMinistryOrganizationAndInstitution = () => {
        setLoading(true)
        axios.get(url(`ministry-organization-and-institution`), axiosHeader)
            .then(({data: {data: {ministry, organization, institution}}}) => {
                setMinistry(ministry);
                setOrganization(organization);
                setInstitution(institution);
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
                console.log(error);
            });
    }

    const handleDelete = (id) => {
        if (!window.confirm('Are you sure?')) return;

        axios.delete(url(`ministry-organization-and-institution/${id}`), axiosConfig())
            .then((response) => {
                toast.success('Deleted Successful');
                getMinistryOrganizationAndInstitution();
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleEdit = (data) => {
        Object.entries(data).forEach(([key, value]) => setValue(key, value));
    }
    const onSubmit = (data) => {
        axios.post(url('ministry-organization-and-institution'), data, axiosConfig())
            .then(response => {
                setErrors([]);
                setValue('id', '');
                setValue('name', '');
                setValue('parent_id', '');
                setValue('total_employee', '');
                toast.success('Saved Successful');
                // getMinistryOrganizationAndInstitution();
            })
            .catch(error => {
                if (error.response.status === 422) {
                    setErrors(error.response.data.errors);
                }
            });
    }

    useEffect(() => {
        if (!authToken()) {
            navigate('/login')
        }
    }, [navigate]);

    useEffect(() => {
        getMinistryOrganizationAndInstitution();
    }, [])

    if (loading) {
        return <Loader/>
    }
    return (
        <div className="component_container py-2 my-3">
            <ToastContainer/>
            <div className="row">
                <div className="col-12">
                    <ul className="nav nav-pills mb-2" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="btn btn-sm nav-link active" id="ministry-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#ministry" type="button" role="tab"
                                    aria-controls="ministry"
                                    aria-selected="true"
                                    onClick={() => setTab('ministry')}> মন্ত্রণালয়
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="organization-tab" data-bs-toggle="pill"
                                    data-bs-target="#organization" type="button" role="tab"
                                    aria-controls="organization" aria-selected="false"
                                    onClick={() => setTab('organization')}> দপ্তর/সংস্থা
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="institution-tab" data-bs-toggle="pill"
                                    data-bs-target="#institution" type="button" role="tab"
                                    aria-controls="institution" aria-selected="false"
                                    onClick={() => setTab('institution')}> প্রতিষ্ঠান
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="ministry" role="tabpanel"
                             aria-labelledby="ministry-tab">
                            {
                                tab === 'ministry' && <Ministry
                                    handleSubmit={handleSubmit}
                                    onSubmit={onSubmit}
                                    errors={errors}
                                    setValue={setValue}
                                    register={register}
                                    data={ministry}
                                    handleDelete={handleDelete}
                                    handleEdit={handleEdit}
                                    permissions={permissions}
                                />
                            }

                        </div>
                        <div className="tab-pane fade" id="organization" role="tabpanel"
                             aria-labelledby="organization-tab">
                            {
                                tab === 'organization' && <Organization
                                    handleSubmit={handleSubmit}
                                    onSubmit={onSubmit}
                                    errors={errors}
                                    setValue={setValue}
                                    register={register}
                                    data={organization}
                                    setData={setOrganization}
                                    handleDelete={handleDelete}
                                    handleEdit={handleEdit}
                                    parents={ministry}
                                    permissions={permissions}
                                />
                            }
                        </div>
                        <div className="tab-pane fade" id="institution" role="tabpanel"
                             aria-labelledby="institution-tab">
                            {
                                tab === 'institution' && <Institution
                                    handleSubmit={handleSubmit}
                                    onSubmit={onSubmit}
                                    errors={errors}
                                    setValue={setValue}
                                    register={register}
                                    data={institution}
                                    handleDelete={handleDelete}
                                    handleEdit={handleEdit}
                                    parents={organization}
                                    permissions={permissions}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MinistryOrganizationAndInstitution;