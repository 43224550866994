import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router';
import EmployeeChild from '../../Components/EmployeeInfo/EmployeeChild';
import Loader from '../../Components/Loader/Loader';
import {useParams} from 'react-router-dom';
import {useFieldArray, useForm} from 'react-hook-form';
import useEmployeeInfo from '../../Hooks/useEmployeeInfo';
import axios from 'axios';
import {payloadAsFormData, url} from '../../helpers/helpers';
import {ToastContainer, toast} from 'react-toastify';

const EmployeeChildPage = ({permissions}) => {
    const {id} = useParams();
    const {basicInfo, loading, error} = useEmployeeInfo(id);
    const [children, setChildren] = useState([])
    const [genders, setGenders] = useState([])
    const [bloodGroups, setBloodGroups] = useState([]);
    const [errors, setErrors] = useState([]);

    const {register, control, handleSubmit, setValue, reset} = useForm({
        defaultValues: {
            child: [{
                type: "",
                nid: "",
                birth_certificate_no: "",
                name_in_bn: "",
                name_in_en: "",
                date_of_birth: "",
                blood_group: "",
                gender: "",
                address: "",
                is_autistic: "",
                attachments: ""
            }]
        }
    });

    const {fields, append, remove} = useFieldArray({
        control,
        name: "child"
    });

    useEffect(() => {
        if (basicInfo?.data?.children.length > 0) {
            setValue(`child`, basicInfo?.data?.children);
        } else {
            setValue('child', [{
                type: "",
                nid: "",
                birth_certificate_no: "",
                name_in_bn: "",
                name_in_en: "",
                date_of_birth: "",
                blood_group: "",
                gender: "",
                address: "",
                is_autistic: "",
                attachments: ""
            }])
        }
    }, [basicInfo?.data, children, genders]);

    // const onSubmit = (data) => {
    //     axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/employee`, {
    //         basic_info: {...basicInfo?.data},
    //         children_info: [...data.child]
    //     }, {
    //         headers: {
    //             authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    //         }
    //     }).then(response => {
    //             if (response.data?.message === 'Success') {
    //                 alert('Posted Successfully')
    //             }
    //         })
    //         .catch(error => {
    //             console.error(error);
    //     });
    // }

    const onSubmit = (data) => {
        let formData = new FormData();
        formData = payloadAsFormData(formData, 'basic_info', basicInfo?.data)

        for (let topIndex = 0; topIndex < data.child.length; topIndex++) {
            let payload = data.child[topIndex];
            formData = payloadAsFormData(formData, `children_info[${topIndex}]`, payload)
        }

        axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/employee`, formData, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                "Content-Type": "multipart/form-data"
            }
        }).then(response => {
            if (response.data?.message === 'Success') {
                toast.success('Saved successful.');
                setErrors([]);
            }
        }).catch(error => {
            if (error.response.status === 422) {
                setErrors(error.response.data.errors);
            }
        });
    }

    const deleteAttachment = async (id) => {
        try {
            const accessToken = localStorage.getItem("accessToken");
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const {data} = await axios.delete(url(`attachment/${id}`), {headers});
            if (data) {
                console.log(data.data)
                toast.success('Attachment deleted successfully.')
                window.location.reload();
            }
        } catch (e) {
            toast.error('something went wrong')
        }
    }

    const fetchChildren = async () => {
        try {
            const accessToken = localStorage.getItem("accessToken");
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const [children, gender, bloodGroups] = await Promise.all([
                axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-children`, {headers}),
                axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-gender`, {headers}),
                axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-blood-group`, {headers}),
            ]);
            setChildren(children?.data?.data);
            setGenders(gender?.data?.data);
            setBloodGroups(bloodGroups?.data?.data)
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchChildren();
    }, []);

    if (loading) {
        return <Loader/>
    }

    return (
        <div className='component_container py-2 my-3 py-md-5 my-md-5'>
            <ToastContainer/>
            <EmployeeChild
                onSubmit={onSubmit}
                register={register}
                handleSubmit={handleSubmit}
                errors={errors}
                setErrors={setErrors}
                reset={reset}
                fields={fields}
                append={append}
                remove={remove}
                children={children}
                genders={genders}
                bloodGroups={bloodGroups}
                deleteAttachment={deleteAttachment}
                permissions={permissions}
                userName={basicInfo.data.name_in_bn}
            />
        </div>
    );
};

export default EmployeeChildPage;