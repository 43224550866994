import React from 'react';

const FourColumn = () => {
    return (
        <>
            <div className='col-xs-auto col-md'></div>
            <div className='col-xs-auto col-md'></div>
            <div className='col-xs-auto col-md'></div>
            <div className='col-xs-auto col-md'></div>
        </>
    );
};

export default FourColumn;