import React, {useEffect, useState} from 'react';
import {ProSidebar, Menu, MenuItem, SidebarContent, SidebarFooter, SubMenu} from "react-pro-sidebar";
import {FaList, FaUsers} from "react-icons/fa";
import {SlBadge} from "react-icons/sl";
import {MdOutlineGavel, MdPerson} from "react-icons/md";
import {CgLoadbarDoc} from "react-icons/cg";
import {AiOutlineUserAdd} from "react-icons/ai";
import {FiLogOut, FiHome, FiUser, FiChevronRight, FiList} from "react-icons/fi";
import {RiInformationLine} from "react-icons/ri";
import {FaBriefcase, FaUser, FaCog} from 'react-icons/fa';
import bdLogo from '../../Assets/bdLogo.png';
// import bdLogo from '../../Assets/nn.png';
import './sideNavbar.css'
import 'react-pro-sidebar/dist/css/styles.css';
import axios from "axios";
import {authUser, isAdmin} from "../../helpers/helpers";
import { Link } from 'react-router-dom';

const SideNavbar = ({
                        menuCollapse,
                        navigate,
                        location,
                        handleCheckEmployeeId,
                        handleLogout,
                        employeeId,
                        permissions
                    }) => {
    const [currentEmployeeId, setCurrentEmployeeId] = useState();

    useEffect(() => {
        let user = authUser();
        const config = {headers: {authorization: `Bearer ${localStorage.getItem("accessToken")}`}};
        const url = `${process.env.REACT_APP_BASE_URL}/api/v1/fetch-user-info/${user?.id}`;

        axios.get(url, config)
            .then(({data}) => {
                if (data.data) {
                    setCurrentEmployeeId(data.data.id);
                }
            })
            .catch(error => {
                console.log(error)
            });
    }, []);

    const handlePersonalInfoPage = () => {
        if (!currentEmployeeId) {
            return '/employee-info-edit'
        } else {
            localStorage.setItem("currentUserId", currentEmployeeId);
            return `/employee-info-edit/${currentEmployeeId}`
        }
    }
    const setEditId = () => {
        localStorage.setItem("editId", currentEmployeeId);
    }


    return (
        <>
            <div id="header" data-aos="fade-right">
                <ProSidebar collapsed={menuCollapse} width="250px">
                    <div className="logotext">
                        <p>
                            <a href="/">
                                {
                                    menuCollapse
                                        ? <img style={{width: 43 + '%'}} src={bdLogo} alt=""/>
                                        : <img style={{width: 43 + '%'}} src={bdLogo} alt=""/>
                                }
                            </a>
                        </p>
                        {/* <p>{menuCollapse ? <img className='w-75' src={bdLogo} alt=""/> : <img className='w-75' src={bdLogo} alt=""/> }</p> */}
                        <hr/>
                    </div>
                    <SidebarContent>
                        <Menu id="menu_container" iconShape="square">
                            {
                                (isAdmin() || permissions?.includes('dashboard')) &&
                                <MenuItem
                                    active={location.pathname === `/`}
                                    className='menu_item'
                                    icon={<FiHome/>}>
                                    <Link to='/'>ড্যাশবোর্ড</Link>
                                </MenuItem>
                            }
                            {
                                (isAdmin() || permissions?.includes('personal_info')) &&
                                <MenuItem
                                    active={location.pathname === `/employee-info` || location.pathname === `/employee-info-edit/${currentEmployeeId}`}
                                    className='menu_item'
                                    icon={<MdPerson/>}>
                                    <Link onClick={setEditId} to={handlePersonalInfoPage()}>ব্যক্তিগত তথ্য</Link>
                                </MenuItem>
                            }
                            {/*<MenuItem*/}
                            {/*    active={location.pathname === `/job-information`}*/}
                            {/*    className='menu_item'*/}
                            {/*    onClick={() => navigate(`/job-information`)}*/}
                            {/*    icon={<FaBriefcase/>}>*/}
                            {/*    চাকুরীর তথ্য*/}
                            {/*</MenuItem>*/}
                            {/*<MenuItem*/}
                            {/*    active={location.pathname === `/leave-management`}*/}
                            {/*    className='menu_item'*/}
                            {/*    onClick={() => navigate(`/leave-management`)}*/}
                            {/*    icon={<CgLoadbarDoc/>}>*/}
                            {/*    ছুটি সম্পর্কিত তথ্য*/}
                            {/*</MenuItem>*/}
                            {/*<MenuItem*/}
                            {/*    active={location.pathname === `/prize-info`}*/}
                            {/*    className='menu_item'*/}
                            {/*    onClick={() => navigate(`/prize-info`)}*/}
                            {/*    icon={<SlBadge/>}>*/}
                            {/*    পুরস্কার / সনদপত্র*/}
                            {/*</MenuItem>*/}
                            {/*<MenuItem*/}
                            {/*    active={location.pathname === `/punishment`}*/}
                            {/*    className='menu_item'*/}
                            {/*    onClick={() => navigate(`/punishment`)}*/}
                            {/*    icon={<MdOutlineGavel/>}>*/}
                            {/*    শৃঙ্খলা সম্পর্কিত তথ্য*/}
                            {/*</MenuItem>*/}

                            {/*{*/}
                            {/*    isAdmin() ? <>*/}
                            {
                                (isAdmin() || permissions?.includes('all_info')) &&
                                <MenuItem
                                    active={location.pathname === `/dashboard`}
                                    className='menu_item'
                                    icon={<RiInformationLine/>}>
                                   <Link to='/dashboard'>সকল তথ্য</Link> 
                                </MenuItem>
                            }
                            {
                                (isAdmin() || permissions?.includes('user_list')) &&
                                <MenuItem
                                    active={location.pathname === `/users`}
                                    className='menu_item'
                                    icon={<FaUsers/>}>
                                    <Link to='/users'>ব্যবহারকারী</Link>
                                </MenuItem>
                            }
                            { 
                                (isAdmin() || permissions?.includes('report')) &&
                                <MenuItem
                                    icon={<FiList />}
                                >
                                    <Link to='/employees-report'> রিপোর্ট</Link>
                                </MenuItem>
                            }
                            {
                                (isAdmin() || permissions?.includes('settings')) &&
                                <SubMenu label="Charts" icon={<FaCog/>} title="সেটিংস">
                                    <MenuItem
                                     icon={<FiChevronRight />}
                                    >
                                        <Link to='/ministry-organization-and-institution'>মন্ত্রণালয় /দপ্তর /সংস্থা</Link>
                                    </MenuItem>
                                    <MenuItem
                                        icon={<FiChevronRight />}
                                    >
                                        <Link to='/designation'>পদবি</Link>
                                    </MenuItem>
                                    <MenuItem
                                        icon={<FiChevronRight />}
                                    >
                                        <Link to='/galleries'>গ্যালারী</Link>
                                    </MenuItem>
                                </SubMenu>
                            }
                            <MenuItem
                                active={location.pathname === `/profile`}
                                className='menu_item'
                                    icon={<FiUser/>}>
                                <Link to={'profile'}>প্রোফাইল</Link>
                            </MenuItem>

                        </Menu>
                    </SidebarContent>

                    <SidebarFooter>
                        <Menu iconShape="square">
                            <MenuItem
                                active={location.pathname === "/login"}
                                className='menu_item'
                                onClick={handleLogout}
                                icon={<FiLogOut/>}>
                                লগ আউট
                            </MenuItem>
                        </Menu>
                    </SidebarFooter>
                </ProSidebar>
            </div>
        </>
    );
};

export default SideNavbar;