import React, {useEffect, useState} from 'react';
import EmployeeTransfer from '../../Components/EmployeeInfo/EmployeeTransfer';
import {useParams} from 'react-router-dom';
import {useFieldArray, useForm} from 'react-hook-form';
import useEmployeeInfo from '../../Hooks/useEmployeeInfo';
import axios from 'axios';
import Loader from '../../Components/Loader/Loader';
import {ToastContainer, toast} from 'react-toastify';
import {axiosConfig, payloadAsFormData, url} from '../../helpers/helpers';

const EmployeeTransferPage = ({permissions}) => {
    const {id} = useParams();
    const {basicInfo, loading} = useEmployeeInfo(id);
    const [transfer, setTransfer] = useState([]);
    const [countries, setCountries] = useState([]);
    const [errors, setErrors] = useState([]);
    const [designation, setDesignation] = useState([]);

    const {register, control, handleSubmit, setValue, getValues, reset} = useForm({
        defaultValues: {
            transfer: [
                {
                    type: "",
                    country_id: "",
                    institute: "",
                    place_of_posting: "",
                    office_address: "",
                    designation: "",
                    transfer_order_no: "",
                    transfer_order_date: "",
                    transfer_order_cause: "",
                    type_of_posting: "",
                    type_of_transfer: "",
                    joining_date: "",
                    salary_scale: "",
                    remarks: "",
                    attachments: ""
                }
            ]
        }
    });

    const {fields, append, remove} = useFieldArray({
        control,
        name: "transfer"
    });

    useEffect(() => {
        if (basicInfo?.data?.transfer_infos.length > 0) {
            setValue(`transfer`, basicInfo?.data?.transfer_infos);
        } else {
            setValue(`transfer`, [
                {
                    type: "",
                    country_id: "",
                    institute: "",
                    place_of_posting: "",
                    office_address: "",
                    designation: "",
                    transfer_order_no: "",
                    transfer_order_date: "",
                    transfer_order_cause: "",
                    type_of_posting: "",
                    type_of_transfer: "",
                    joining_date: "",
                    salary_scale: "",
                    remarks: "",
                    attachments: ""
                }
            ]);
        }
    }, [basicInfo?.data, transfer]);


    const getDesignation = () => {
        try {
            axios.get(url(`fetch-designation`), axiosConfig())
                .then(({data: {data}}) => {
                    setDesignation(data)
                })
                .catch((error) => {
                    console.log(errors);
                })
        } catch (e) {
            toast.error('something went wrong')
        }
    }

    const onSubmit = (data) => {
        let formData = new FormData();
        formData = payloadAsFormData(formData, 'basic_info', basicInfo?.data)

        for (let topIndex = 0; topIndex < data.transfer.length; topIndex++) {
            let payload = data.transfer[topIndex];
            formData = payloadAsFormData(formData, `transfer_info[${topIndex}]`, payload)
        }

        axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/employee`, formData, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                "Content-Type": "multipart/form-data"
            }
        }).then(response => {
            if (response.data?.message === 'Success') {
                toast.success('Saved successful.');
                setErrors([]);
            }
        }).catch(error => {
            if (error.response.status === 422) {
                setErrors(error.response.data.errors);
            }
        });
    }

    const deleteAttachment = async (id) => {
        try {
            const accessToken = localStorage.getItem("accessToken");
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const {data} = await axios.delete(url(`attachment/${id}`), {headers});
            if (data) {
                console.log(data.data)
                toast.success('Attachment deleted successful.')
                window.location.reload();
            }
        } catch (e) {
            toast.error('something went wrong')
        }
    }

    const fetchTransfer = async () => {
        try {
            const accessToken = localStorage.getItem("accessToken");
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const [transfer, countries] = await Promise.all([
                axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-transfer-or-travel-type`, {headers}),
                axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-countries`, {headers}),
            ]);
            setTransfer(transfer?.data?.data);
            setCountries(countries?.data?.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchTransfer();
        getDesignation();
    }, [])

    if (loading) {
        return <Loader/>
    }

    return (
        <div className='component_container py-2 my-3 py-md-5 my-md-5'>
            <ToastContainer/>
            <EmployeeTransfer
                onSubmit={onSubmit}
                register={register}
                handleSubmit={handleSubmit}
                errors={errors}
                setErrors={setErrors}
                reset={reset}
                fields={fields}
                append={append}
                remove={remove}
                transfer={transfer}
                countries={countries}
                deleteAttachment={deleteAttachment}
                setValue={setValue}
                getValues={getValues}
                designation={designation}
                permissions={permissions}
                userName={basicInfo?.data?.name_in_bn}
            />
        </div>
    );
};

export default EmployeeTransferPage;