import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import PrizeInfoDetails from '../../Components/PrizeInfo/PrizeInfoDetails';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { payloadAsFormData, url } from '../../helpers/helpers';
import { ToastContainer, toast } from 'react-toastify';
import {useParams} from "react-router";
import useEmployeeInfo from "../../Hooks/useEmployeeInfo";
import Loader from '../../Components/Loader/Loader';

const PrizeInfoDetailsPage = ({permissions}) => {
    const {id} = useParams();
    const {basicInfo, loading, error} = useEmployeeInfo(id);
    const navigate = useNavigate();
    const [userId, setUserId] = useState();
    const [users, setUsers] = useState();
    const [prizeType, setPrizeType] = useState([]);
    const [userInfo, setUserInfo] = useState();
    const [countries, setCountries] = useState();
    const [dataLoading, setDataLoading] = useState(true);

    const { register, control, handleSubmit, setValue, formState: { errors }, reset } = useForm({
        defaultValues: {
            prizeDetails: [
                {
                    type: "",
                    country_name: "",
                    donor_organization_name: "",
                    place_of_award: "",
                    date_of_award: "",
                    subject_of_award: "",
                    attachments: ""
                }
            ]
        }
    });

    const {fields, append, remove} = useFieldArray({
        control,
        name: "prizeDetails"
    });

    useEffect(()=>{
        if(userInfo?.prize_infos.length > 0){
            setValue('prizeDetails', userInfo?.prize_infos)
        } else {
            setValue('prizeDetails', [
                {
                    type: "",
                    country_name: "",
                    donor_organization_name: "",
                    place_of_award: "",
                    date_of_award: "",
                    subject_of_award: "",
                    attachments: ""
                }
            ])
        }
    },[userInfo]);

    useEffect(() => {
        if (!Object.entries(basicInfo).length) return;
        setUserId(basicInfo?.data?.user_id);
        fetchUserInfo(basicInfo?.data?.user_id)
    }, [basicInfo]);

    const onSubmit = (data) => {
        let formData = new FormData();
        formData = payloadAsFormData(formData, 'basic_info', userInfo)

        for (let topIndex = 0; topIndex < data.prizeDetails.length; topIndex++ ) {
            let payload = data.prizeDetails[topIndex];
            formData = payloadAsFormData(formData, `prize_info[${topIndex}]`, payload)
        }

        axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/employee`, formData, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                "Content-Type": "multipart/form-data"
            }
        }).then(response => {
                if (response.data?.message === 'Success') {
                    toast.success('Saved successful.');
                }
            })
            .catch(error => {
                console.error(error);
        });
    }

    const deleteAttachment = async (id) => {
        try {
            const accessToken = localStorage.getItem("accessToken");
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const {data} = await axios.delete(url(`attachment/${id}`), {headers});
            if (data) {
                console.log(data.data)
                toast.success('Attachment deleted successful.')
                window.location.reload()
            }
        } catch (e) {
            toast.error('something went wrong')
        }
    }

    const fetchPrizeType = async () => {
        try {
          const accessToken = localStorage.getItem("accessToken");
          const headers = {
            Authorization: `Bearer ${accessToken}`,
          };
            setDataLoading(true)
          const [prizeTypes, countries] = await Promise.all([
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-prize-type`, {headers}),
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-countries`, {headers}),
          ]);
          setDataLoading(false)
          setPrizeType(prizeTypes?.data?.data);
          setCountries(countries?.data?.data);
        } catch (error) {
          setDataLoading(false)
          console.error(error);
        }
      };

    useEffect(()=>{
        fetchPrizeType();
    },[]);

    const fetchUserInfo = async (id) => {
        try {
            const accessToken = localStorage.getItem("accessToken");
            const headers = {
              Authorization: `Bearer ${accessToken}`,
            }; 
            setDataLoading(true)
            const [userInfo] = await Promise.all([
              axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-user-info/${id}`, {headers}),
            ]);
            setDataLoading(false)
            setUserInfo(userInfo?.data?.data);
        } catch (error) {
            setDataLoading(false)
            console.error(error);
        }
    }

    useEffect(()=>{
        if(!localStorage.getItem('accessToken')){
            navigate('/login')
        }
    },[localStorage.getItem('accessToken')])
    if (loading || dataLoading) {
        return <Loader/>
    }
    return (
        <div className='component_container py-2 my-3 py-md-5 my-md-5'>
            <ToastContainer />
            <PrizeInfoDetails
                onSubmit={onSubmit}
                register={register}
                handleSubmit={handleSubmit}
                errors={errors}
                fields={fields}
                append={append}
                remove={remove}
                prizeType={prizeType}
                users={users}
                setUsers={setUsers}
                countries={countries}
                reset={reset}
                userId={userId}
                deleteAttachment={deleteAttachment}
                permissions={permissions}
                userName={userInfo?.name_in_bn}
            />
        </div>
    );
};

export default PrizeInfoDetailsPage;