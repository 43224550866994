import React from 'react';
import FormSubmitBtn from '../EmployeeCommonSection/FormSubmitBtn';
import FourColumn from '../EmployeeCommonSection/FourColumn';
import EmployeeHeader from '../EmployeeCommonSection/EmployeeHeader';
import FileUpload from '../Utils/FileUpload';
import NestedFormIncDec from "../EmployeeCommonSection/ NestedFormIncDec";
import FieldErrorMessage from "../Utils/FieldErrorMessage";
import { isAdmin } from '../../helpers/helpers';

const EmployeeSpouse = ({
                onSubmit,
                register,
                handleSubmit,
                errors,
                fields,
                append,
                remove,
                bloodGroups,
                deleteAttachment,
                reset,
                permissions,
                userName
            }) => {
    return (
        <div className='employee_info'>
            <div className='card shadow border-0 mt-2'>
                <div className='card-header'>
                    <EmployeeHeader userName={userName} e_title="স্বামী / স্ত্রীর তথ্য"/>
                </div>
                <div className='card-body'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {fields.map((item, index) => {
                            let attachments = item.attachments;
                            return (
                                    <div className='mt-2' key={item.id}>
                                        <NestedFormIncDec fields={fields} append={append} remove={remove} index={index}/>
                                        <div className='row'>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">জাতীয় পরিচয়পত্র নম্বর </label>
                                                <input type="text" className="form-control"
                                                    {...register(`spouse_info.${index}.nid`)}
                                                />
                                                <FieldErrorMessage errors={errors} prefix="spouse_info" index={index}
                                                                name="nid"/>
                                            </div>
                                            <FourColumn/>
                                        </div>

                                        <div className='row'>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">নাম (বাংলায়) <span
                                                    className='text-danger'>*</span></label>
                                                <input type="text" className="form-control"
                                                    {...register(`spouse_info.${index}.name_in_bn`)} />
                                                <FieldErrorMessage errors={errors} prefix="spouse_info" index={index}
                                                                name="name_in_bn"/>
                                            </div>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">নাম (ইংরেজিতে) <span
                                                    className='text-danger'>*</span></label>
                                                <input type="text" className="form-control"
                                                    {...register(`spouse_info.${index}.name_in_en`)} />
                                                <FieldErrorMessage errors={errors} prefix="spouse_info" index={index}
                                                                name="name_in_en"/>
                                            </div>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">রক্তের গ্রুপ</label>
                                                <select id='blood_group' className="form-select"
                                                        aria-label="Default select example"
                                                        {...register(`spouse_info.${index}.blood_group`)}>
                                                    <option style={{color: 'gray'}}>নির্বাচন করুন</option>
                                                    {bloodGroups?.map(bloodGroup => <option key={bloodGroup.id}
                                                                                            value={bloodGroup.id}>{bloodGroup.bn}</option>)}
                                                </select>
                                            </div>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">পেশা (যদি থাকে)</label>
                                                <input type="text" className="form-control"
                                                    {...register(`spouse_info.${index}.occupation`)}/>
                                            </div>
                                            <div className="col-xs-auto col-md"></div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">মাতার নাম (বাংলায়)</label>
                                                <input type="text" className="form-control"
                                                    {...register(`spouse_info.${index}.mother_name_in_bn`)}/>
                                                <FieldErrorMessage errors={errors} prefix="spouse_info" index={index}
                                                                name="mother_name_in_bn"/>
                                            </div>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">মাতার নাম (ইংরেজিতে)</label>
                                                <input type="text" className="form-control"
                                                    {...register(`spouse_info.${index}.mother_name_in_en`)} />
                                                <FieldErrorMessage errors={errors} prefix="spouse_info" index={index}
                                                                name="mother_name_in_en"/>
                                            </div>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">পিতার নাম (বাংলায়)</label>
                                                <input type="text" className="form-control"
                                                    {...register(`spouse_info.${index}.father_name_in_bn`)} />
                                                <FieldErrorMessage errors={errors} prefix="spouse_info" index={index}
                                                                name="father_name_in_bn"/>
                                            </div>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">পিতার নাম (ইংরেজিতে)</label>
                                                <input type="text" className="form-control"
                                                    {...register(`spouse_info.${index}.father_name_in_en`)} />
                                                <FieldErrorMessage errors={errors} prefix="spouse_info" index={index}
                                                                name="father_name_in_en"/>
                                            </div>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">পাসপোর্ট নাম্বার (যদি থাকে)</label>
                                                <input type="text" className="form-control"
                                                    {...register(`spouse_info.${index}.passport_no`)}/>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">মোবাইল নম্বর (যদি থাকে)</label>
                                                <input type="tel" className="form-control"
                                                    {...register(`spouse_info.${index}.phone`)} />
                                            </div>
                                            <FourColumn/>
                                        </div>
                                        <div className='row'>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">বাসা নম্বর (যদি থাকে)</label>
                                                <input type="text" className="form-control"
                                                    {...register(`spouse_info.${index}.house_no`)}/>
                                            </div>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">রাস্তা নম্বর (যদি থাকে)</label>
                                                <input type="text" className="form-control"
                                                    {...register(`spouse_info.${index}.road_no`)}/>
                                            </div>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">গ্রাম/শহর</label>
                                                <input type="text" className="form-control"
                                                    {...register(`spouse_info.${index}.village`)}/>
                                            </div>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">পৌরসভা</label>
                                                <input type="text" className="form-control"
                                                    {...register(`spouse_info.${index}.municipality`)} />
                                            </div>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">ডাকঘর</label>
                                                <input type="text" className="form-control"
                                                    {...register(`spouse_info.${index}.post_office`)}/>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">উপজেলা</label>
                                                <input type="text" className="form-control"
                                                    {...register(`spouse_info.${index}.upazila`)}/>
                                            </div>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">জেলা</label>
                                                <input type="text" className="form-control"
                                                    {...register(`spouse_info.${index}.district`)}/>
                                            </div>
                                            <div className="col-xs-auto col-md">
                                                <label className="form-label">বিভাগ</label>
                                                <input type="text" className="form-control"
                                                    {...register(`spouse_info.${index}.division`)} />
                                            </div>
                                            <div className="col-xs-auto col-md"></div>
                                            <div className="col-xs-auto col-md"></div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-xs-auto col-md">
                                                <label htmlFor="attachments" className="form-label"></label>
                                                <br/>
                                                <FileUpload
                                                    title="প্রমাণক সংযুক্ত করুন"
                                                    register={register}
                                                    registerkey={`spouse_info.${index}.attachments`}
                                                    attachments={attachments}
                                                    deleteAttachment={deleteAttachment}
                                                />
                                            </div>
                                            <FourColumn/>
                                        </div>
                                    </div>
                                )
                        })}
                        {((isAdmin() || permissions?.includes('edit'))) && <FormSubmitBtn reset={reset} /> }
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EmployeeSpouse;