import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import ReactApexChart from 'react-apexcharts';
import {asset, authUser, axiosConfig, isAdmin, translateToBanglaDigit, url} from "../../helpers/helpers";
import axios from "axios";
import galleryImg01 from '../../Assets/gallery/01.png'
import galleryImg02 from '../../Assets/gallery/02.png'
import galleryImg03 from '../../Assets/gallery/03.png'
import galleryImg04 from '../../Assets/gallery/04.png'
import ChartInfoDetailTable from "./ChartInfoDetailTable";
import Select2Wrapper from "../Utils/Select2Wrapper";
import Loader from "../Loader/Loader";




const Home = () => {
    const [pieChartSeries, setPieChartSeries] = useState([0, 0, 0]);
    const [totalEmployees, setTotalEmployees] = useState({
        total_employees: 0, total_female_employees: 0, total_male_employees: 0
    });
    const [organizationCurrentPointData, setOrganizationCurrentPointData] = useState([]);
    const [organizationId, setOrganizationId] = useState(null);
    const [organizations, setOrganizations] = useState([]);
    const [institutionId, setInstitutionId] = useState(null);
    const [institutions, setInstitutions] = useState([]);
    const [organizationChartData, setOrganizationChartData] = useState([]);
    const [setting, setSetting] = useState({});

    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const pieChartOptions = {
        dataLabels: {
            enabled: true, formatter: function (val) {
                return Number(val).toFixed(2); //translateToBanglaDigit();
            },
        },
        legend: {
            position: 'bottom',
        },
        labels: ['অন্যান্য', 'নারীর সংখ্যা', 'পুরুষের সংখ্যা'],
        series: pieChartSeries,
        colors: ['#1a4564', '#ab2e98', '#6628c3'],
        chart: {
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    handleDataPointChange(config.w.config.labels[config.dataPointIndex])
                }
            }
        },
        plotOptions: {
            pie: {
                donut: {
                    size: "45%"
                }
            }
        },
    };

    const getGallery = () => {
        axios.get(url(`gallery`), axiosConfig())
            .then(({data}) => {
                setSetting(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const fetchChartData = (organization_id = '', institution_id = '') => {
        setLoading(true);

     
        if (!isAdmin()) {
            organization_id = !organizationId ? authUser()?.organization_id : organizationId;
            // institution_id = !institutionId ? authUser()?.institution_id : institutionId;
        }

        const searchQuery = new URLSearchParams({
            organization_id: organization_id,
            institution_id: institution_id
        }).toString();
        const url = `${process.env.REACT_APP_BASE_URL}/api/v1/fetch-pie-chart-data?${searchQuery}`;
        axios.get(url, axiosConfig())
            .then(({data: {data: {chart_data, organization_chart_data}}}) => {
                const {total_female_employees, total_male_employees, total_others_employees} = chart_data
                setPieChartSeries([Number(total_others_employees), Number(total_female_employees), Number(total_male_employees)])
                setTotalEmployees(chart_data);
                setOrganizationChartData(organization_chart_data);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('accessToken');
                }
                console.log(error)
            }).finally(() => {
            setLoading(false);
        });
    }
    const fetchMinistryOrganizationAndInstitution = () => {
            axios.get(url(`fetch-ministry-organization-and-institution-user-wise`), axiosConfig())
                .then(({data: {data}}) => {
                    if (data) {
                        setOrganizations(
                            data.filter(data => data.type === 'organization')
                        );
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
    }
    const handleDataPointChange = (type) => {
        if (type === 'অন্যান্য') {
            setOrganizationCurrentPointData(Object.values(organizationChartData[3] ?? []));
        }
        if (type === 'নারীর সংখ্যা') {
            setOrganizationCurrentPointData(Object.values(organizationChartData[2] ?? []));
        }
        if (type === 'পুরুষের সংখ্যা') {
            setOrganizationCurrentPointData(Object.values(organizationChartData[1] ?? []));
        }
    }

    function fetchInstitutions(orgId = '') {
        axios.get(url(`ministry-organization-and-institution?organization_id=${orgId}`), axiosConfig())
            .then(({data: {data: {institution}}}) => {
                const institutions = [];
                institution.forEach(item => {
                    institutions.push({
                        label: item.name,
                        value: item.id,
                    });
                })
                setInstitutions([...institutions]);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleOrganizationChange = (orgId) => {
        if (orgId == organizationId) return;
        fetchInstitutions(orgId);
        setOrganizationId(orgId);
        fetchChartData(orgId);
    }

    const handleInstitutionChange = (instId) => {
        if (instId == institutionId) return;
        setInstitutionId(instId);
        fetchChartData(organizationId, instId);
    }
    useEffect(() => {
        if (organizationId) {
            fetchInstitutions(organizationId);
        }
    }, [organizationId]);

    useEffect(() => {
        let proSidebar = document.getElementsByClassName('pro-sidebar')
        if (proSidebar[0].style.width > '150px') {
            document.body.style.marginLeft = '140px'
            document.body.style.transition = 'all 0.4s'
        } else {
            document.body.style.marginLeft = '0px'
            document.body.style.transition = 'all 0.4s'
        }

        fetchChartData();
        fetchMinistryOrganizationAndInstitution();
        if (!isAdmin()) {
            setOrganizationId(authUser()?.organization_id);
        }
        getGallery()
    }, [])

    useEffect(() => {
        let sumOfAllData = [];
        for (let gender_id = 1; gender_id <= 3; gender_id++) {
            Object.values(organizationChartData[gender_id] ?? []).forEach(data => {
                const index = sumOfAllData.findIndex(item => parseInt(item['organization_id']) === parseInt(data['organization_id']))
                if (index !== -1) {
                    sumOfAllData[index].value = sumOfAllData[index].value + data.value;
                } else {
                    sumOfAllData.push(data);
                }
            });
        }
        const final = sumOfAllData.sort(function (a, b) {
            return a.sort - b.sort;
        });
        setOrganizationCurrentPointData(final);
    }, [organizationChartData])

    useEffect(() => {
        if (!localStorage.getItem('accessToken')) {
            navigate('/login')
        }
    }, [localStorage.getItem('accessToken')])

    if (loading) {
        return <Loader/>
    }

    return (

        <div className='component_container py-2 my-3 '>
            <div className="row">
                <div className="col-12">
                    <div className="row" style={{margin: '0px'}}>
                        <div className="col-md-6">
                            {
                                <Select2Wrapper
                                    placeholder="সকল সংস্থা"
                                    value={organizationId}
                                    onChange={(organizationId) => handleOrganizationChange(organizationId)}
                                    options={organizations}
                                />
                            }
                        </div>
                        <div className="col-md-6">
                            {
                                <Select2Wrapper
                                    placeholder="সকল প্রতিষ্ঠান"
                                    value={institutionId}
                                    onChange={(institution_id) => handleInstitutionChange(institution_id)}
                                    options={institutions}
                                />
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="row dashboard-card">
                                <div className="col-lg-15">
                                    <div className="card m-2">
                                        <div className="card-content">
                                            <div className="card-body">
                                                <Link to='/dashboard'>
                                                    <div className="media d-flex">
                                                        <div className="media-body float-start">
                                                            <h3>{translateToBanglaDigit(totalEmployees.total_employees)}</h3>
                                                            <span>কর্মকর্তা /কর্মচারী</span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-15">
                                    <div className="card m-2">
                                        <div className="card-content">
                                            <div className="card-body">
                                                <Link to='/dashboard?gender_id=1'>
                                                    <div className="media d-flex">
                                                        <div className="media-body float-start">
                                                            <h3>{translateToBanglaDigit(totalEmployees.total_male_employees)}</h3>
                                                            <span>পুরুষ</span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-15">
                                    <div className="card m-2">
                                        <div className="card-content">
                                            <div className="card-body">
                                                <Link to='/dashboard?gender_id=2'>
                                                    <div className="media d-flex">
                                                        <div className="media-body float-start">
                                                            <h3>{translateToBanglaDigit(totalEmployees.total_female_employees)}</h3>
                                                            <span>নারী</span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-15">
                                    <div className="card m-2">
                                        <div className="card-content">
                                            <div className="card-body">
                                                <Link to='/ministry-organization-and-institution'>
                                                    <div className="media d-flex">
                                                        <div className="media-body float-start">
                                                            <h3>{ translateToBanglaDigit(13) }</h3>
                                                            <span>দপ্তর/অধিদপ্তর</span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-15">
                                    <div className="card m-2">
                                        <div className="card-content">
                                            <div className="card-body">
                                                <Link to='/ministry-organization-and-institution'>
                                                    <div className="media d-flex">
                                                        <div className="media-body float-start">
                                                            <h3>{ translateToBanglaDigit(1) } </h3>
                                                            <span>মন্ত্রণালয় /বিভাগ</span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="card m-2">
                        <div className="card-content">
                            <div className="card-body" style={{minHeight: 210 + 'px'}}>
                                <div className="row">
                                    <div className="col-3">
                                        <ReactApexChart options={pieChartOptions}
                                                        series={pieChartOptions.series}
                                                        type="donut"
                                                        height={275}/>
                                    </div>
                                    <div className="col-9">
                                        <ChartInfoDetailTable data={organizationCurrentPointData} title="দপ্তর /সংস্থা"/>
                                        {/*<ChartInfoDetailTable data={institutionCurrentPointData} title="প্রতিষ্ঠান"/>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row gallery mt-2">
                <div className="col-12">
                    <h4>Gallery</h4>
                </div>
                <div className="col-3">
                    <div className="gallery-img-section">
                        <img src={ setting.image_one ? asset(setting.image_one) : galleryImg01} alt=""/>
                    </div>
                </div>
                <div className="col-3">
                    <div className="gallery-img-section">
                        <img src={ setting.image_two ? asset(setting.image_two) : galleryImg02} alt=""/>
                    </div>
                </div>
                <div className="col-3">
                    <div className="gallery-img-section">
                        <img src={ setting.image_three ? asset(setting.image_three) : galleryImg03} alt=""/>
                    </div>
                </div>
                <div className="col-3">
                    <div className="gallery-img-section">
                        <img src={ setting.image_four ? asset(setting.image_four) : galleryImg04} alt=""/>
                    </div>
                </div>
                <div className="col-6"></div>
            </div>
        </div>);
};

export default Home;