import {useEffect, useState} from "react";
import {Controller} from "react-hook-form";
import Select from "react-select";

const ReactMultiSelect = ({name, options, onSelect, control}) => {
    return (
        <Controller
            name={name}
            control={control}
            render={(
                {
                    field: {onChange, value, name, ref},
                }) => (
                <Select
                    isMulti
                    name={name}
                    isSearchable={true}
                    options={options}
                    classNamePrefix="select"
                    onChange={(e) => {
                        onChange(e.value);
                        onSelect && onSelect(e);
                    }}
                    value={value}
                    ref={ref}
                />
            )}
        />
    );
}


export default ReactMultiSelect;
