import React from 'react';
import FormSubmitBtn from '../EmployeeCommonSection/FormSubmitBtn';
import FourColumn from '../EmployeeCommonSection/FourColumn';
import './employeePersonalInfo.css'
import FileUpload from "../Utils/FileUpload";
import FieldErrorMessage from "../Utils/FieldErrorMessage";
import Header from "./Header";
import {AiOutlineEye} from "react-icons/ai";
import {useNavigate} from 'react-router-dom';
import {useParams} from "react-router";
import {isAdmin} from "../../helpers/helpers";

const EmployeeInfo = (
    {
        permissions,
        onSubmit,
        register,
        handleSubmit,
        errors,
        setErrors,
        genders,
        cadres,
        married,
        classes,
        bloodGroups,
        religion,
        setUsers,
        users,
        setUserId,
        userId,
        attachments,
        setValue,
        getValues,
        reset,
        deleteAttachment,
        userName,
        percentage
    }) => {
    const {id} = useParams();
    const navigate = useNavigate();

    return (
        <div className='employee_info'>
            <form className='' onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                <div className='card shadow border-0 mt-2'>
                    <div className='card-header'>
                        <Header register={register} setUsers={setUsers} users={users} setUserId={setUserId}
                                userId={userId}
                                setValue={setValue}
                                getValues={getValues}
                                errors={errors} 
                                e_title="কর্মকর্তা / কর্মচারীর তথ্য"
                                userName={userName}
                                percentage={percentage}
                                />
                    </div>
                    <div className='card-body position-relative'>
                        {(id && (isAdmin() || permissions?.includes('view'))) && <button className="btn btn-sm btn-secondary float-end position-absolute"
                                 style={{top: '10px', right: '10px'}}
                                 title="ভিউ করুন"
                                 onClick={() => navigate(`/employee-info/${id}/view-full`)}>
                            <AiOutlineEye/> View
                        </button>}
                        <div className='row mt-4'>
                            <div className="col-xs-auto col-md mb-3">
                                <label htmlFor="inputEmail4" className="form-label">জাতীয় পরিচয়পত্র নম্বর <span
                                    className='text-danger'>*</span></label>
                                <input type="text" id='nid' className="form-control"
                                       {...register("nid")}/>
                                {errors.nid?.message ?
                                    <small className='text-danger'>{errors.nid?.message}</small>
                                    :
                                    errors.nid && <small className='text-danger'>This field is required.</small>
                                }
                            </div>
                            <div className="col-xs-auto col-md mb-3">
                                <label htmlFor="inputAddress" className="form-label">নাম (বাংলায়) <span
                                    className='text-danger'>*</span></label>
                                <input type="text" id='name_in_bn' className="form-control"
                                       {...register("name_in_bn")}/>
                                <FieldErrorMessage errors={errors} name="name_in_bn" prefix="basic_info"/>
                            </div>
                            <div className="col-xs-auto col-md mb-3">
                                <label htmlFor="inputAddress" className="form-label">নাম (ইংরেজিতে) <span
                                    className='text-danger'>*</span></label>
                                <input type="text" id='name_in_en' className="form-control"
                                       {...register("name_in_en")}/>
                                <FieldErrorMessage errors={errors} name="name_in_en" prefix="basic_info"/>
                            </div>
                            <div className="col-xs-auto col-md mb-3">
                                <label htmlFor="inputAddress" className="form-label">জন্ম তারিখ <span
                                    className='text-danger'>*</span></label>
                                <input type="date" id='date_of_birth' className="form-control"
                                       {...register("date_of_birth")}/>
                                <FieldErrorMessage errors={errors} name="date_of_birth" prefix="basic_info"/>
                            </div>
                            
                        </div>

                        <div className='row'>
                            <div className="col-xs-auto col-md mb-3">
                                <label htmlFor="inputAddress" className="form-label">রক্তের গ্রুপ</label>
                                <select id='blood_group' className="form-select" aria-label="Default select example"
                                        {...register("blood_group")}>
                                    <option style={{color: 'gray'}} value="">নির্বাচন করুন</option>
                                    {
                                        bloodGroups.map(bloodGroup => <option key={bloodGroup.id}
                                                                              value={bloodGroup.id}>{bloodGroup.bn}</option>)
                                    }
                                </select>
                            </div>
                            <div className="col-xs-auto col-md mb-3">
                                <label htmlFor="inputAddress" className="form-label">কোটা</label>
                                <select id='class' className="form-select" aria-label="Default select example"
                                        {...register("class")}>
                                    <option value="" style={{color: 'gray'}}>নির্বাচন করুন</option>
                                    {
                                        classes.map(item => <option key={item.id} value={item.id}>{item.bn}</option>)
                                    }
                                </select>
                            </div>
                            <div className="col-xs-auto col-md mb-3">
                                <label htmlFor="inputAddress" className="form-label">মাতার নাম (বাংলায়) <span
                                    className='text-danger'>*</span></label>
                                <input type="text" id='mother_name_in_bn' className="form-control"
                                       {...register("mother_name_in_bn")}/>
                                <FieldErrorMessage errors={errors} name="mother_name_in_bn" prefix="basic_info"/>
                            </div>
                            <div className="col-xs-auto col-md mb-3">
                                <label htmlFor="inputAddress" className="form-label">মাতার নাম (ইংরেজিতে) <span
                                    className='text-danger'>*</span></label>
                                <input type="text" id='mother_name_in_en' className="form-control"
                                       {...register("mother_name_in_en")}/>
                                <FieldErrorMessage errors={errors} name="mother_name_in_en" prefix="basic_info"/>
                            </div>
                          
                        </div>

                        <div className='row'>
                            <div className="col-xs-auto col-md mb-3">
                                <label htmlFor="inputAddress" className="form-label">পিতার নাম (বাংলায়) <span
                                    className='text-danger'>*</span></label>
                                <input type="text" id='father_name_in_bn' className="form-control"
                                       {...register("father_name_in_bn")}/>
                                <FieldErrorMessage errors={errors} name="father_name_in_bn" prefix="basic_info"/>
                            </div>
                            <div className="col-xs-auto col-md mb-3">
                                <label htmlFor="inputAddress" className="form-label">পিতার নাম (ইংরেজিতে) <span
                                    className='text-danger'>*</span></label>
                                <input type="text" id='father_name_in_en' className="form-control"
                                       {...register("father_name_in_en")}/>
                                <FieldErrorMessage errors={errors} name="father_name_in_en" prefix="basic_info"/>
                            </div>
                            <div className="col-xs-auto col-md mb-3">
                                <label htmlFor="inputAddress" className="form-label">জেন্ডার</label>
                                <select id='gander' className="form-select" aria-label="Default select example"
                                        {...register("gander")}>
                                    <option value="" style={{color: 'gray'}}>নির্বাচন করুন</option>
                                    {
                                        genders.map(gender => <option key={gender.id}
                                                                      value={gender.id}>{gender.bn}</option>)
                                    }
                                </select>
                            </div>
                            <div className="col-xs-auto col-md mb-3">
                                <label htmlFor="inputAddress" className="form-label">পাসপোর্ট নাম্বার (যদি থাকে)</label>
                                <input type="text" id='passport_no' className="form-control"
                                       {...register("passport_no")}/>
                            </div>
                            
                        </div>
                        <div className='row'>
                            <div className="col-xs-auto col-md mb-3">
                                <label htmlFor="marital_status" className="form-label">বৈবাহিক অবস্থা <span
                                    className='text-danger'>*</span></label>
                                <select id='marital_status' className="form-select" aria-label="Default select example"
                                        {...register("marital_status")}>
                                    <option value="" style={{color: 'gray'}}>নির্বাচন করুন</option>
                                    {
                                        married.map((value) => {
                                            return <option key={value.id}
                                                           value={value.id}>{value.bn}
                                            </option>
                                        })
                                    }
                                </select>
                                <FieldErrorMessage errors={errors} name="marital_status" prefix="basic_info"/>
                            </div>
                            <div className="col-xs-auto col-md mb-3">
                                <label htmlFor="inputAddress" className="form-label">ধর্ম </label>
                                <select id='religion' className="form-select" aria-label="Default select example"
                                        {...register("religion")}>
                                    <option value="" style={{color: 'gray'}}>নির্বাচন করুন</option>
                                    {
                                        religion.map(value => <option key={value.id}
                                                                      value={value.id}>{value.bn}</option>)
                                    }
                                </select>
                                <FieldErrorMessage errors={errors} name="religion" prefix="basic_info"/>
                            </div>
                            <div className="col-xs-auto col-md mb-3">
                                <label htmlFor="attachments" className="form-label">প্রমাণক</label>
                                <FileUpload
                                    title="প্রমাণক সংযুক্ত করুন"
                                    register={register}
                                    attachments={attachments}
                                    deleteAttachment={deleteAttachment}
                                />
                            </div>
                            <div className="col-xs-auto col-md mb-3">
                                <label htmlFor="inputAddress" className="form-label">ছবি </label>
                                <input type="file" id='image' className="form-control"
                                       {...register("image")}/>
                                <small className="text-danger text-center">Max file upload size 1MB</small>
                                <FieldErrorMessage errors={errors} name="image" prefix="basic_info"/>
                            </div>
                        </div>
                        {((isAdmin() || permissions?.includes('edit'))) && <FormSubmitBtn reset={reset} setErrors={setErrors}/> }
                    </div>
                </div>
            </form>
        </div>
    );
};

export default EmployeeInfo;