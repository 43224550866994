import React, {useEffect, useState} from "react";
import FieldErrorMessage from "../../Utils/FieldErrorMessage";
import {FaRegEdit} from "react-icons/fa";
import {authToken, isAdmin, translateToBanglaDigit, url} from "../../../helpers/helpers";
import axios from "axios";

const Institution = ({
                         handleSubmit,
                         onSubmit,
                         errors,
                         register,
                         setValue,
                         data,
                         parents,
                         handleDelete,
                         handleEdit,
                         permissions
                     }) => {
    const [institution, setInstitution] = useState([]);

    useEffect(() => {
        setValue('type', 'institution');
    }, [])

    useEffect(() => {
        if (data && data.length) {
            setInstitution([...data]);
        }
    }, [data])

    const handleChange = (value) => {
        axios.get(url(`ministry-organization-and-institution?organization_id=${value}`),
            {
                headers: {
                    authorization: `Bearer ${authToken()}`,
                }
            })
            .then(({data: {data: {institution}}}) => {
                setInstitution(institution);
            })
            .catch(error => {
                console.log(error);
            });
    }
    const totalEmployeeData = (institutes=[], property=null) => {
        return institutes.reduce((total, ins) => total + (parseInt(ins[property]) > 0 ? parseInt(ins[property]) : 0) , 0);
    }

    return (
        <>
            <div className='card shadow border-0 mt-2'>
                <div className='card-body'>
                    <div className="row">
                        <div className="col-4">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-12">
                                        <label htmlFor="phone" className="form-label">দপ্তর/সংস্থা <span
                                            className='text-danger'>*</span></label>
                                        <select className="form-select"
                                                {...register('parent_id')}>
                                            <option value="" style={{color: 'gray'}}>নির্বাচন করুন</option>
                                            {
                                                parents && parents.map(parent => {
                                                    return (
                                                        <option key={parent.id} value={parent.id}>{parent.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <FieldErrorMessage errors={errors} name='parent_id'/>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <label htmlFor="name" className="form-label">নাম <span
                                            className='text-danger'>*</span></label>
                                        <input type="text" className="form-control"
                                               {...register("name")}/>
                                        <FieldErrorMessage errors={errors} name='name'/>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <label htmlFor="total_employee" className="form-label">মোট জনবল</label>
                                        <input type="number" className="form-control"
                                               {...register("total_employee")}/>
                                        <FieldErrorMessage errors={errors} name='total_employee'/>
                                    </div>
                                    <div className="col-12 mt-4">
                                        <div className="form-group">
                                            <input id="is_head_office" style={{marginRight: '8px'}}
                                                type="checkbox" {...register("is_head_office")} />
                                            <label htmlFor="is_head_office">প্রধান কার্যালয়</label>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <button className="btn btn-sm btn-success">সংরক্ষণ করুন</button>
                            </form>
                        </div>
                        <div className="col-8">
                            <select className="form-select" onChange={(e) => handleChange(e.target.value)}>
                                <option value="" style={{color: 'gray'}}>দপ্তর/সংস্থা নির্বাচন করুন</option>
                                {
                                    parents && parents.map(parent => {
                                        return (
                                            <option key={parent.id} value={parent.id}>{parent.name}</option>
                                        )
                                    })
                                }
                            </select>
                            <div className='table-responsive pt-4'>
                                <table className="table table-bordered table-hover">
                                    <thead>
                                    <tr style={{backgroundColor: '#f9f9f9'}}>
                                        <th scope="col" style={{textAlign: 'center'}}>ক্রমিক নং</th>
                                        <th scope="col">নাম</th>
                                        <th scope="col">দপ্তর/সংস্থা</th>
                                        <th scope="col">এন্ট্রিকৃত ডাটা</th>
                                        <th scope="col">মোট জনবল</th>
                                        <th scope="col">প্রধান কার্যালয়</th>
                                        <th scope="col">অ্যাকশন</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        institution && institution.map((ministry, index) => {
                                            return (
                                                <tr>
                                                    <td style={{textAlign: 'center'}}>{translateToBanglaDigit(index + 1)}</td>
                                                    <td>{ministry.name}</td>
                                                    <td>{ministry.parent?.name}</td>
                                                    <td>{translateToBanglaDigit(ministry?.institute_employees_count)}</td>
                                                    <td>{translateToBanglaDigit(ministry?.total_employee)}</td>
                                                    <td>
                                                       {
                                                        (ministry.is_head_office == 1) && <span className="badge bg-success">হ্যাঁ</span>
                                                       } 
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {
                                                                (isAdmin() || permissions?.includes('edit')) &&
                                                                <button className="btn btn-sm btn-secondary"
                                                                        onClick={() => handleEdit(ministry)}>
                                                                    <FaRegEdit/>
                                                                </button>
                                                            }

                                                            {/* &nbsp;
                                                            {
                                                                (isAdmin() || permissions?.includes('delete')) &&
                                                                <button className="btn btn-sm btn-secondary"
                                                                        onClick={() => handleDelete(ministry.id)}>
                                                                    <MdDeleteOutline/>
                                                                </button>
                                                            } */}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                       <tr>
                                       <td style={ {textAlign:'right'}} colSpan={3}>মোট</td>
                                        <td style={ {textAlign:'center'}}>{ translateToBanglaDigit(totalEmployeeData(institution, 'institute_employees_count')) }</td>
                                        <td style={ {textAlign:'center'}}>{ translateToBanglaDigit(totalEmployeeData(institution, 'total_employee')) }</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Institution;