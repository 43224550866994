import React from 'react';
import {TbFileUpload} from "react-icons/tb";
import NestedFormIncDec from '../EmployeeCommonSection/ NestedFormIncDec';
import EmployeeInfoHeader from '../EmployeeCommonSection/EmployeeInfoHeader';
import FormSubmitBtn from '../EmployeeCommonSection/FormSubmitBtn';
import ThreeColumn from '../EmployeeCommonSection/ThreeColumn';
import FileUpload from '../Utils/FileUpload';
import { isAdmin } from '../../helpers/helpers';
import Select2Wrapper from '../Utils/Select2Wrapper';
import EmployeeHeader from '../EmployeeCommonSection/EmployeeHeader';

const LeaveManagement = (
    {
        onSubmit,
        register,
        setValue,
        getValues,
        handleSubmit,
        errors,
        fields,
        append,
        remove,
        setUsers,
        users,
        leaveTypes,
        setUserId,
        userId,
        deleteAttachment,
        permissions,
        userName
    }) => {

    return (
        <div className='employee_info'>
            <div className='card shadow border-0 mt-2' >
                <div className='card-header'>
                    <EmployeeHeader register={register} setUsers={setUsers} users={users} setUserId={setUserId}
                                        userId={userId}
                                        errors={errors} e_title="ছুটি সম্পর্কিত তথ্য" userName={userName} />
                </div>
                <div className='card-body'>
                    <form className='pt-4' onSubmit={handleSubmit(onSubmit)}>
                        {fields.map((item, index) => {
                            let attachments = item.attachments;

                            return (
                                <div key={item.id}>
                                    <NestedFormIncDec fields={fields} append={append} remove={remove} index={index}/>
                                    <div className='row'>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label className="form-label">ছুটির ধরণ<span
                                                className='text-danger'>*</span></label>
                                            <Select2Wrapper
                                                value={getValues(`leave.${index}.reason`)}
                                                onChange={(value) => {
                                                    setValue(`leave.${index}.reason`, value);
                                                }}
                                                options={leaveTypes}
                                            />
                                            {errors?.leave?.[index]?.reason &&
                                                <h6 className='text-danger h6'>This field is required.</h6>}
                                        </div>
                                        <div className='col-xs-auto col-md mb-3'>
                                            <label className="form-label">ছুটি শুরুর তারিখ<span
                                                className='text-danger'>*</span></label>
                                            <input className="form-control" type="date"
                                                    {...register(`leave.${index}.start_date`, {required: true})}
                                            />
                                            {errors?.leave?.[index]?.start_date &&
                                                <h6 className='text-danger h6'>This field is required.</h6>}
                                        </div>
                                        <div className='col-xs-auto col-md mb-3'>
                                            <label className="form-label">ছুটি শেষের তারিখ<span
                                                className='text-danger'>*</span></label>
                                            <input className="form-control" type="date"
                                                    {...register(`leave.${index}.end_date`, {required: true})}
                                            />
                                            {errors?.leave?.[index]?.end_date &&
                                                <h6 className='text-danger h6'>This field is required.</h6>}
                                        </div>
                                        <div className='col-xs-auto col-md mb-3'>
                                            <label className="form-label">ছুটির আদেশ নং<span
                                                className='text-danger'>*</span></label>
                                            <input className="form-control"
                                                    {...register(`leave.${index}.order_no`, {required: true})}
                                            />
                                            {errors?.leave?.[index]?.order_no &&
                                                <h6 className='text-danger h6'>This field is required.</h6>}
                                        </div>
                                        <div className='col-xs-auto col-md mb-3'>
                                            <label className="form-label">ছুটির আদেশের তারিখ<span
                                                className='text-danger'>*</span></label>
                                            <input className="form-control" type="date"
                                                    {...register(`leave.${index}.order_date`, {required: true})}
                                            />
                                            {errors?.leave?.[index]?.order_date &&
                                                <h6 className='text-danger h6'>This field is required.</h6>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-xs-auto col-md'>
                                            <label className="form-label">মন্তব্য</label>
                                            <input className="form-control"
                                                    {...register(`leave.${index}.remarks`)}
                                            />
                                        </div>
                                        <div className="col-xs-auto col-md mt-2 btn_file text-center">
                                            <label htmlFor="attachments" className="form-label"></label>
                                            <br/>
                                            <FileUpload
                                                title="প্রমাণক সংযুক্ত করুন"
                                                register={register}
                                                registerkey={`leave.${index}.attachments`}
                                                attachments={attachments}
                                                deleteAttachment={deleteAttachment}
                                            />
                                        </div>
                                        <ThreeColumn/>
                                    </div>
                                </div>
                            );
                        })}
                        {((isAdmin() || permissions?.includes('edit'))) &&  <FormSubmitBtn/> }
                    </form>
                </div>
            </div>
        </div>
    );
};

export default LeaveManagement;