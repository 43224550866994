import React, {useEffect} from "react";

const FieldErrorMessage = ({errors, name, prefix, index = null}) => {
    let msg;
    if (index !== null) {
        msg = errors[`${prefix}.${index}.${name}`]
    } else if (prefix) {
        msg = errors[`${prefix}.${name}`]
    } else {
        msg = errors[name] ? errors[name] : '';
    }

    return (
        <>
            {msg && <small className='text-danger'>{msg}</small>}
        </>
    );
}

export default FieldErrorMessage;