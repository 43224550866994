import React from 'react';
import {TbFileUpload} from "react-icons/tb";
import NestedFormIncDec from '../EmployeeCommonSection/ NestedFormIncDec';
import FormSubmitBtn from '../EmployeeCommonSection/FormSubmitBtn';
import EmployeeHeader from '../EmployeeCommonSection/EmployeeHeader';
import FileUpload from '../Utils/FileUpload';
import { isAdmin } from '../../helpers/helpers';

const EmployeeResearch = (
    {
        register,
        handleSubmit,
        onSubmit,
        errors,
        reset,
        XCResearshList,
        appendXCResearchList,
        removeXCResearchList,
        deleteAttachment,
        permissions,
        userName
    }) => {

    return (
        <div className='employee_info'>
            <div className='card shadow border-0 mt-2'>
                <div className='card-header'>
                    <EmployeeHeader userName={userName} e_title="প্রকাশনা ও গবেষণা বিষয়ক তথ্য" e_num="22004689"/>
                </div>
                <div className='card-body'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {XCResearshList.map((item, index) => {
                            let attachments = item.attachments;
                            return (
                                <div className='row mt-4' key={item.id}>
                                    <NestedFormIncDec fields={XCResearshList} append={appendXCResearchList}
                                                    remove={removeXCResearchList} index={index}/>
                                    <div className='col-xs-auto col-md mb-3'>
                                        <label htmlFor="inputAddress" className="form-label">প্রকাশিত বিষয়ের নাম<span
                                            className='text-danger'>*</span></label>
                                        <input className="form-control"
                                            {...register(`research_info.${index}.name`, {required: true})}
                                        />
                                        {errors?.research_info?.[index]?.name &&
                                            <small className='text-danger'>This field is required.</small>}
                                    </div>
                                    <div className='col-xs-auto col-md mb-3'>
                                        <label htmlFor="inputAddress" className="form-label">প্রকাশের তারিখ<span
                                            className='text-danger'>*</span></label>
                                        <input className="form-control" type="date"
                                            {...register(`research_info.${index}.publish_date`, {required: true})}
                                        />
                                        {errors?.research_info?.[index]?.publish_date &&
                                            <small className='text-danger'>This field is required.</small>}
                                    </div>
                                    <div className='col-xs-auto col-md mb-3'>
                                        <label htmlFor="inputAddress" className="form-label">প্রকাশক প্রতিষ্ঠানের
                                            নাম<span className='text-danger'>*</span></label>
                                        <input className="form-control"
                                            {...register(`research_info.${index}.institute`, {required: true})}
                                        />
                                        {errors?.research_info?.[index]?.institute &&
                                            <small className='text-danger'>This field is required.</small>}
                                    </div>
                                    <div className='col-xs-auto col-md'>
                                        <label htmlFor="inputAddress" className="form-label">মন্তব্য</label>
                                        <input className="form-control"
                                            {...register(`research_info.${index}.comment`)}
                                        />
                                    </div>
                                    <div className="col-xs-auto col-md mt-2 btn_file text-center">
                                        <label htmlFor="attachments" className="form-label"></label>
                                        <br/>
                                        <FileUpload
                                            title="প্রমাণক সংযুক্ত করুন"
                                            register={register}
                                            registerkey={`research_info.${index}.attachments`}
                                            attachments={attachments}
                                            deleteAttachment={deleteAttachment}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                        {((isAdmin() || permissions?.includes('edit'))) &&  <FormSubmitBtn reset={reset}/> }
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EmployeeResearch;