import React, {useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import PrimaryTopNavbar from '../../Components/Navigation/PrimaryTopNavbar';
import SecondaryTopNavbar from '../../Components/Navigation/SecondaryTopNavbar';

const TopNavbar = ({menuIconClick}) => {
    const topnav = useRef();
    const location = useLocation()
    const [pathTitle, setPathTitle] = useState("ব্যক্তিগত তথ্য সংগ্রহ");
    const viewPageUrlMatchPattern = /^\/employee-info\/([^/]+)\/view$/;
    const fullViewPageUrlMatchPattern = /^\/employee-info\/([^/]+)\/view-full$/;
    const attachmentViewPageUrlMatchPattern = /^\/employee-info\/([^/]+)\/view-attachments$/;
    const employeeId = localStorage.getItem("editId")

    useEffect(() => {
        const topSecondaryNavDisabledFor = {
            // '/prize-info': "পুরস্কার / সনদপত্র",
            // '/leave-management': "ছুটি সম্পর্কিত তথ্য",
            // '/punishment': "শৃঙ্খলা সম্পর্কিত তথ্য",
            '/dashboard': "কর্মকর্তা কর্মচারী তথ্য",
            '/travelreport': "ভ্রমণ সংক্রান্ত রিপোর্ট",
            '/': "ড্যাশবোর্ড",
            '/404': "Not Found",
            // '/job-information': "চাকুরীর তথ্য",
            '/users': "ব্যবহারকারী",
            '/profile': "প্রোফাইল",
            '/ministry-organization-and-institution': "মন্ত্রণালয় /দপ্তর /সংস্থা",
            '/designation': "পদবি",
            '/galleries': "গ্যালারী",
            '/employees-report': " রিপোর্ট",
        };

        const isPatternMatched = String(location.pathname).match(viewPageUrlMatchPattern) ||
            String(location.pathname).match(fullViewPageUrlMatchPattern) ||
            String(location.pathname).match(attachmentViewPageUrlMatchPattern);
        if (topnav.current) {
            topnav.current.style.display = 'none';
        }
        if (topSecondaryNavDisabledFor.hasOwnProperty(location.pathname)) {
            setPathTitle(topSecondaryNavDisabledFor[location.pathname])
        } else if (isPatternMatched) {
            setPathTitle("ব্যক্তিগত তথ্য সংগ্রহ")
        } else {
            setPathTitle("ব্যক্তিগত তথ্য সংগ্রহ")
            if (topnav.current) {
                topnav.current.style.display = 'block'
            }
        }
    }, [location.pathname]);

    const handleCheckEmployeeId = () => {
        if (!employeeId) {
            window.location.replace('/employee-info');
        } else {
            window.history.replace(`/employee-info-edit/${employeeId}`);
        }
    }

    return (
        <div data-aos="fade-down">
            <PrimaryTopNavbar pathTitle={pathTitle} menuIconClick={menuIconClick}/>
            <SecondaryTopNavbar
                location={location}
                topnav={topnav}
                employeeId={employeeId}
                handleCheckEmployeeId={handleCheckEmployeeId}
            />
        </div>
    );
};

export default TopNavbar;