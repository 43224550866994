import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {useFieldArray, useForm} from 'react-hook-form';
import EmployeeTravel from '../../Components/EmployeeInfo/EmployeeTravel';
import useEmployeeInfo from '../../Hooks/useEmployeeInfo';
import Loader from '../../Components/Loader/Loader';
import {toast, ToastContainer} from "react-toastify";

const EmployeeTravellPage = ({permissions}) => {
    const {id} = useParams()
    const {basicInfo, loading} = useEmployeeInfo(id);
    const [transfer, setTransfer] = useState([])
    const [ministryType, setMinistryType] = useState();
    const [countries, setCountries] = useState([]);
    const [errors, setErrors] = useState([]);

    const {register, control, handleSubmit, getValues, setValue, reset} = useForm({
        defaultValues: {
            travell: [
                {
                    type: "",
                    ministry_name: "",
                    country_name: "",
                    travel_order_no: "",
                    travel_place: "",
                    travel_cause: "",
                    travel_start_date: "",
                    travel_stop_date: "",
                    remarks: "",
                    tr_file: ""
                }
            ]
        }
    });

    const {fields, append, remove} = useFieldArray({
        control,
        name: "travell"
    });


    useEffect(() => {
        if (basicInfo?.data?.travel_infos.length > 0) {
            setValue(`travell`, basicInfo?.data?.travel_infos);
        } else {
            setValue(`travell`, [
                {
                    type: "",
                    ministry_name: "",
                    country_name: "",
                    travel_order_no: "",
                    travel_place: "",
                    travel_cause: "",
                    travel_start_date: "",
                    travel_stop_date: "",
                    remarks: "",
                    tr_file: ""
                }
            ]);
        }
    }, [basicInfo?.data, transfer, ministryType]);

    const onSubmit = (data) => {
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/employee`, {
            basic_info: {...basicInfo?.data},
            travel_info: [...data.travell]
        }, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            }
        }).then(response => {
            if (response.data?.message === 'Success') {
                toast.success('Saved Successfully');
                setErrors([]);
            }
        }).catch(error => {
            if (error.response.status === 422) {
                setErrors(error.response.data.errors);
            }
        });
    }

    const fetchDropDownData = async () => {
        try {
            const accessToken = localStorage.getItem("accessToken");
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const [transfer, ministryType, countries] = await Promise.all([
                axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-travel-type`, {headers}),
                axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-ministry-type`, {headers}),
                axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-countries`, {headers}),
            ]);
            setTransfer(transfer?.data?.data);
            setMinistryType(ministryType?.data?.data);
            setCountries(countries?.data?.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchDropDownData();
    }, [])

    if (loading) {
        return <Loader/>
    }

    return (
        <div className='component_container py-2 my-3 py-md-5 my-md-5'>
            <ToastContainer/>
            <EmployeeTravel
                onSubmit={onSubmit}
                register={register}
                handleSubmit={handleSubmit}
                errors={errors}
                setErrors={setErrors}
                reset={reset}
                fields={fields}
                append={append}
                remove={remove}
                transfer={transfer}
                ministryType={ministryType}
                getValues={getValues}
                setValue={setValue}
                countries={countries}
                permissions={permissions}
                userName={basicInfo?.data?.name_in_bn}
            />
        </div>
    );
};

export default EmployeeTravellPage;