import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import FieldErrorMessage from "../Utils/FieldErrorMessage";
import Button from "react-bootstrap/Button";
import {useForm} from 'react-hook-form';
import axios from "axios";
import {axiosConfig, isAdmin, url} from "../../helpers/helpers";
import {toast} from "react-toastify";
import ReactMultiSelect from "../Utils/ReactMultiSelect";

const UserEdit = ({modalShow, setModalShow, editUser, getUsers, permissions}) => {
    const [errors, setErrors] = useState({});
    const [organizations, setOrganizations] = useState([]);
    const [institutes, setInstitutes] = useState([]);
    const [instituteList, setInstituteList] = useState([]);
    const [isShowPermissionTab, SetPermissionTabVisibility] = useState(true);
    const {control, register, handleSubmit, setValue, getValues, watch, reset} = useForm();
    const onSubmit = (data) => {
        axios.post(url('users'), data, axiosConfig())
            .then(response => {
                getUsers();
                setModalShow(false);
                toast.success('Updated Successful');
                resetForm();
            })
            .catch(error => {
                if (error.response.status === 422) {
                    setErrors(error.response.data.errors);
                }
            });
    }
    const resetForm = () => {
        setErrors([]);
        reset();
    }
    const fetchOrganization = () => {
        axios.get(url(`fetch-ministry-organization-and-institution-user-wise`), axiosConfig())
            .then(({data: {data}}) => {
                const filterData = data?.filter(data => data.type == 'organization').map(item => {
                    return { label: item.label, value: item.value }
                })
                const filterInstitutes = data?.filter(data => data.type == 'institution').map(item => {
                    return { label: item.label, value: item.value, parent_id: item.parent_id }
                })
                setOrganizations(filterData);
                setInstitutes(filterInstitutes);
                setInstituteList(filterInstitutes);
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const handleOrganizationChange = (ids) => {
        setValue('organizations', ids)
        const filterData = ids.map(item => {
            return item.value
        })
        let instituteList = institutes.filter(data => filterData.includes(data.parent_id));
        setInstituteList(instituteList);
    }

    useEffect(() => {
        fetchOrganization();
        if (!editUser) return;
        Object.entries(editUser).forEach(([key, value]) => setValue(key, value));
        SetPermissionTabVisibility(parseInt(editUser.role_id) !== 1);
    }, [modalShow, editUser, setValue]);

    const role_id = watch("role_id");

    useEffect(() => {
        console.log("Current role_id:", role_id);
        if (role_id === "3") {
            setValue("permissions.dashboard", true);
            setValue("permissions.personal_info", true);
            setValue("permissions.all_info", true);
            setValue("permissions.user_list", true);
            setValue("permissions.edit", true);
            setValue("permissions.view", true);
        }
        if (role_id === "4") {
            setValue("permissions.personal_info", true);
            setValue("permissions.all_info", true);
            setValue("permissions.edit", true);
            setValue("permissions.view", true);
        }
    }, [role_id, setValue]);

    return <>
        <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton onHide={() => resetForm()}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        পরিবর্তন করুন ({editUser.name})
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-xs-auto col-md mb-3">
                            <label className="form-label">ইউজার আইডি<span
                                className='text-danger'>*</span></label>
                            <input type="text" className="form-control" aria-describedby="nameHelp" disabled
                                value={editUser.unique_id} />
                            <FieldErrorMessage errors={errors} name='name'/>
                        </div>
                        <div className="col-xs-auto col-md mb-3">
                            <label className="form-label">নাম <span
                                className='text-danger'>*</span></label>
                            <input type="text" className="form-control" aria-describedby="nameHelp"
                                   {...register("name")}/>
                            <FieldErrorMessage errors={errors} name='name'/>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-xs-auto col-md mb-3">
                            <label className="form-label">কর্মবিভাগ </label>
                            <select className="form-select" aria-label="Default select example"
                                    {...register("department")} >
                                <option value="">নির্বাচন করুন</option>
                                <option value="1">প্রথম</option>
                                <option value="2">দ্বিতীয়</option>
                                <option value="3">তৃতীয়</option>
                                <option value="4">চতুর্থ</option>
                            </select>
                            <FieldErrorMessage errors={errors} name='department'/>
                        </div>
                        <div className="col-xs-auto col-md mb-3">
                            <label className="form-label">সংস্থা</label>
                            <input className="form-control" type="text" disabled={true} value={editUser.organization}/>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-xs-auto col-md mb-3">
                            <label className="form-label">রোল <span
                                className='text-danger'>*</span></label>
                            <select disabled={isAdmin() ? false : true} className="form-select" aria-label="Default select example"
                                    {...register("role_id")} >
                                <option value="">নির্বাচন করুন</option>
                                <option value="1">অ্যাডমিন</option>
                                <option value="2">ব্যবহারকারী</option>
                                <option value="3">ফোকাল পয়েন্ট</option>
                                <option value="4">ডাটা এনট্রি</option>
                            </select>
                            <FieldErrorMessage errors={errors} name='role_id'/>
                        </div>
                        <div className="col-xs-auto col-md mb-3">
                            <label className="form-label">ইমেইল <span
                                className='text-danger'>*</span></label>
                            <input type="email" className="form-control" aria-describedby="emailHelp"
                                   {...register("email")}/>
                            <FieldErrorMessage errors={errors} name='email'/>
                        </div>
                        {/* <div className="col-xs-auto col-md mb-3">
                            <label htmlFor="phone" className="form-label">ফোন নম্বর</label>
                            <input type="text" className="form-control"
                                   {...register("phone")}/>
                            <FieldErrorMessage errors={errors} name='phone'/>
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col-xs-auto col-md mb-3">
                            <label htmlFor="new_password" className="form-label">নতুন পাসওয়ার্ড লিখুন</label>
                            <input type="password" className="form-control"
                                   {...register("new_password")}/>
                            <FieldErrorMessage errors={errors} name='new_password'/>
                        </div>
                        <div className="col-xs-auto col-md mb-3">
                            <label htmlFor="new_password_confirmation"
                                   className="form-label">পাসওয়ার্ড নিশ্চিত করুন</label>
                            <input type="password" className="form-control"
                                   {...register("new_password_confirmation")}/>
                            <FieldErrorMessage errors={errors} name='new_password_confirmation'/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-auto col-md mb-3">
                            <label htmlFor="organizations" className="form-label">সংস্থা নির্বাচন করুন</label>
                            <ReactMultiSelect
                                control={control}
                                name={'organizations'}
                                options={organizations}
                                errors={errors}
                                onSelect={handleOrganizationChange}
                            />
                            <FieldErrorMessage errors={errors} name='organizations'/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-auto col-md mb-3">
                            <label htmlFor="institutions" className="form-label">প্রতিষ্ঠান নির্বাচন করুন</label>
                            <ReactMultiSelect
                                control={control}
                                name={'institutions'}
                                options={instituteList}
                                errors={errors}
                                onSelect={(value) => setValue('institutions', value) }
                            />
                            <FieldErrorMessage errors={errors} name='institutions'/>
                        </div>
                    </div>
                    {
                        isShowPermissionTab &&
                        <>
                            <h5 className="bg-secondary p-2 text-light text-center fw-bolder">পারমিশন</h5>
                            <div className="row">
                                <div className="col" style={{borderRight: '2px solid green'}}>
                                    <h6>মেনু পারমিশন</h6>
                                    {
                                        (isAdmin() || permissions?.includes('dashboard')) &&
                                        <div className="form-group">
                                            <input id="dashboardPremission" style={{marginRight: '8px'}}
                                                type="checkbox" {...register("permissions.dashboard")} />
                                            <label htmlFor="dashboardPremission"> ড্যাশবোর্ড</label>
                                        </div>
                                    }
                                    {
                                        (isAdmin() || permissions?.includes('personal_info')) &&
                                        <div className="form-group">
                                            <input id="personaInfoPremission" style={{marginRight: '8px'}}
                                                type="checkbox" {...register("permissions.personal_info")} />
                                            <label htmlFor="personaInfoPremission"> ব্যক্তিগত তথ্য</label>
                                        </div>
                                    }
                                    {
                                        (isAdmin() || permissions?.includes('all_info')) &&
                                        <div className="form-group">
                                            <input id="allInfoPremission" style={{marginRight: '8px'}}
                                                type="checkbox" {...register("permissions.all_info")} />
                                            <label htmlFor="allInfoPremission"> সকল তথ্য</label>
                                        </div>
                                    }
                                    {
                                        (isAdmin() || permissions?.includes('settings')) &&
                                        <div className="form-group">
                                            <input id="settingsPremission" style={{marginRight: '8px'}}
                                                type="checkbox" {...register("permissions.settings")} />
                                            <label htmlFor="settingsPremission"> সেটিংস</label>
                                        </div>
                                    }
                                    {
                                        (isAdmin() || permissions?.includes('user_list')) &&
                                        <div className="form-group">
                                            <input id="userListPremission" style={{marginRight: '8px'}}
                                                type="checkbox" {...register("permissions.user_list")} />
                                            <label htmlFor="userListPremission"> ব্যবহারকারী</label>
                                        </div>
                                    }
                                </div>
                                <div className="col">
                                    <h6>রুটিন পারমিশন</h6>
                                    {
                                        (isAdmin() || permissions?.includes('edit')) &&
                                        <div className="form-group">
                                            <input id="editPremission" style={{marginRight: '8px'}}
                                                type="checkbox" {...register("permissions.edit")} />
                                            <label htmlFor="editPremission"> এডিট</label>
                                        </div>
                                    }
                                    {
                                        (isAdmin() || permissions?.includes('delete')) &&
                                        <div className="form-group">
                                            <input id="deletePremission" style={{marginRight: '8px'}}
                                                type="checkbox" {...register("permissions.delete")} />
                                            <label htmlFor="deletePremission"> ডিলিট</label>
                                        </div>
                                    }
                                    {
                                        (isAdmin() || permissions?.includes('view')) &&
                                        <div className="form-group">
                                            <input id="viewPremission" style={{marginRight: '8px'}}
                                                type="checkbox" {...register("permissions.view")} />
                                            <label htmlFor="viewPremission"> ভিউ</label>
                                        </div>
                                    }
                                    {
                                        (isAdmin() || permissions?.includes('status_change')) &&
                                        <div className="form-group">
                                            <input id="statusChangePremission" style={{marginRight: '8px'}}
                                                type="checkbox" {...register("permissions.status_change")} />
                                            <label htmlFor="statusChangePremission"> অ্যাক্টিভ/ইন-অ্যাক্টিভ </label>
                                        </div>
                                    }
                                    {
                                        (isAdmin() || permissions?.includes('separation_status')) &&
                                        <div className="form-group">
                                            <input id="statusChangePremission" style={{marginRight: '8px'}}
                                                type="checkbox" {...register("permissions.separation_status")} />
                                            <label htmlFor="statusChangePremission"> অ্যাক্টিভ/সেপারেশন </label>
                                        </div>
                                    }
                                    {
                                        (isAdmin() || permissions?.includes('report')) &&
                                        <div className="form-group">
                                            <input id="statusChangePremission" style={{marginRight: '8px'}}
                                                type="checkbox" {...register("permissions.report")} />
                                            <label htmlFor="statusChangePremission">রিপোর্ট </label>
                                        </div>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" className="btn btn-success">Submit</Button>
                </Modal.Footer>
            </form>
        </Modal>
    </>
}

export default UserEdit;