import React, {useEffect, useState} from "react";
import FieldErrorMessage from "../../Utils/FieldErrorMessage";
import {MdDeleteOutline} from "react-icons/md";
import {FaRegEdit} from "react-icons/fa";
import {isAdmin, translateToBanglaDigit} from "../../../helpers/helpers";

const Ministry = ({handleSubmit, onSubmit, errors, register, setValue, data, handleDelete, handleEdit, permissions}) => {

    useEffect(() => {
        setValue('type', 'ministry');
    }, [])

    return (
        <>
            <div className='card shadow border-0 mt-2'>
                <div className='card-body'>
                    <div className="row">
                        <div className="col-4">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <label htmlFor="phone" className="form-label">নাম <span
                                    className='text-danger'>*</span></label>
                                <input type="text" className="form-control"
                                       {...register("name")}/>
                                <FieldErrorMessage errors={errors} name='name'/>
                                <br/>
                                <button className="btn btn-sm btn-success">সংরক্ষণ করুন</button>
                            </form>
                        </div>
                        <div className="col-8">
                            <div className='table-responsive pt-4'>
                                <table className="table table-bordered table-hover">
                                    <thead>
                                    <tr style={{backgroundColor: '#f9f9f9'}}>
                                        <th scope="col" style={{textAlign: 'center'}}>ক্রমিক নং</th>
                                        <th scope="col">নাম</th>
                                        <th scope="col">এন্ট্রিকৃত ডাটা</th>
                                        <th scope="col">অ্যাকশন</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        data && data.map((ministry, index) => {
                                            return (
                                                <tr>
                                                    <td style={{textAlign: 'center'}}>{translateToBanglaDigit(index + 1)}</td>
                                                    <td>{ministry.name}</td>
                                                    <td className="text-center">{translateToBanglaDigit(ministry?.ministry_employees_count)}</td>

                                                    <td>
                                                        <div>
                                                            {
                                                                (isAdmin() || permissions?.includes('edit')) &&
                                                                <button className="btn btn-sm btn-secondary"
                                                                        onClick={() => handleEdit(ministry)}>
                                                                    <FaRegEdit/>
                                                                </button>
                                                            }
                                                            {/* &nbsp;
                                                            {
                                                                (isAdmin() || permissions?.includes('delete')) &&
                                                                <button className="btn btn-sm btn-secondary"
                                                                        onClick={() => handleDelete(ministry.id)}>
                                                                    <MdDeleteOutline/>
                                                                </button>
                                                            } */}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Ministry;