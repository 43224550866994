import {BsDownload} from "react-icons/bs";
import {asset} from "../../../helpers/helpers";

const AttachmentsPage = ({attachments}) => {
    return (
        <>
            <table width="100%">
                <tbody>
                {
                    attachments.map((attachment, index) => {
                        return (
                            <tr key={index}>
                                <td width="85%">{attachment.name}</td>
                                <td width="15%">
                                    <div>
                                        <a className="btn btn-sm btn-primary m-1 download-button" target="_blank"
                                           href={asset(attachment.path)}>
                                            <BsDownload/>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>

        </>
    );
}

export default AttachmentsPage;