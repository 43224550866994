import React from "react";
import Table from "react-bootstrap/Table";
import {translateToBanglaDigit} from "../../helpers/helpers";
import { Link } from "react-router-dom";

const ChartInfoDetailTable = ({data, title}) => {
    console.log(data)

    return (
        <>
            {
                (data && data.length > 0) && <>
                    <Table className="text-center" bordered>
                        <thead>
                        <tr style={{background: '#683091', color: '#fff'}}>
                            <td colSpan={data && data.length}>{title}</td>
                        </tr>
                        <tr>
                            {
                                data && data.map((value, index) => {
                                    return (
                                        <td key={index}>{value.key}</td>
                                    )
                                })
                            }
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                                {
                                    data && data.map((value, index) => {
                                        return (
                                            <td key={index} >
                                                <Link to={`/dashboard?organization_id=${value.organization_id}`} style={{textDecoration: 'none'}}>
                                                    {translateToBanglaDigit(value.value)}
                                                </Link>
                                            </td>
                                        )
                                    })
                                }
                        </tr>
                        </tbody>
                    </Table>
                </>
            }
        </>
    )
}

export default ChartInfoDetailTable;