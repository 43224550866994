import React, {useEffect, useState} from "react";
import FieldErrorMessage from "../Utils/FieldErrorMessage";
import {toast, ToastContainer} from "react-toastify";
import {set, useForm} from "react-hook-form";
import {authToken, axiosConfig, url} from "../../helpers/helpers";
import axios from "axios";

const Gallery = ({permissions}) => {
    const [errors, setErrors] = useState([]);
    const {register, setValue, handleSubmit} = useForm({ defaultValues: {
        "image_two": null,
        "image_three": null,
        "image_four": null,
        "image_one": null
    }});
    const [setting, setSetting] = useState({
        "image_two": null,
        "image_three": null,
        "image_four": null,
        "image_one": null,
    });
    const axiosHeader = {
        headers: {
            authorization: `Bearer ${authToken()}`,
        }
    }

    const getGallery = () => {
        axios.get(url(`gallery`), axiosHeader)
            .then(({data}) => {
                setSetting(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const onSubmit = (data) => {
        let formData = new FormData();
        formData.append('id', setting?.id)
        Object.keys(data).map(key => {
            if (data[key]) {
                formData.append(key, data[key][0])
            }
        })
        axios.post(url('gallery'), formData, axiosConfig())
            .then(response => {
                getGallery();
                setErrors([]);
                toast.success('Saved Successful');
            })
            .catch(error => {
                if (error.response.status === 422) {
                    setErrors(error.response.data.errors);
                }
            });
    }
    
    useEffect(() => {
        getGallery();
    }, [])

    return (
        <>
            <div className="component_container py-2 my-3">
                <ToastContainer/>
                <div className="row">
                    <div className="col-12">
                        <div className='card mt-2'>
                            <div className="card-header pt-3">
                                <h4>গ্যালারী</h4>
                            </div>
                            <div className='card-body'>
                              <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        <div className="col-3">
                                            <label htmlFor="inputAddress" className="form-label">ছবি (১)</label>
                                            <input type="file" id='image_one' className="form-control"
                                                {...register("image_one")}/>
                                            <small className="text-danger text-center">Max file upload size 1MB</small>
                                            <FieldErrorMessage errors={errors} name="image_one"/>
                                        </div>
                                        <div className="col-3">
                                            <label htmlFor="inputAddress" className="form-label">ছবি (২)</label>
                                            <input type="file" id='image_two' className="form-control"
                                                {...register("image_two")}/>
                                            <small className="text-danger text-center">Max file upload size 1MB</small>
                                            <FieldErrorMessage errors={errors} name="image_two"/>
                                        </div>
                                        <div className="col-3">
                                            <label htmlFor="inputAddress" className="form-label">ছবি (৩)</label>
                                            <input type="file" id='image_three' className="form-control"
                                                {...register("image_three")}/>
                                            <small className="text-danger text-center">Max file upload size 1MB</small>
                                            <FieldErrorMessage errors={errors} name="image_three"/>
                                        </div>
                                        <div className="col-3">
                                            <label htmlFor="inputAddress" className="form-label">ছবি (৪)</label>
                                            <input type="file" id='image_four' className="form-control"
                                                {...register("image_four")}/>
                                            <small className="text-danger text-center">Max file upload size 1MB</small>
                                            <FieldErrorMessage errors={errors} name="image_four"/>
                                        </div>
                                       
                                        <div className="col-12  mt-4">
                                            <div style={{textAlign: 'right'}}>
                                                <button className=" btn btn-sm btn-success">সংরক্ষণ করুন</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Gallery;