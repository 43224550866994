import React from 'react';
import {TbFileUpload} from "react-icons/tb";
import NestedFormIncDec from '../EmployeeCommonSection/ NestedFormIncDec';
import EmployeeInfoHeader from '../EmployeeCommonSection/EmployeeInfoHeader';
import FormSubmitBtn from '../EmployeeCommonSection/FormSubmitBtn';
import FourColumn from '../EmployeeCommonSection/FourColumn';
import EmployeeHeader from '../EmployeeCommonSection/EmployeeHeader';
import FileUpload from '../Utils/FileUpload';
import { isAdmin } from '../../helpers/helpers';

const PrizeInfoDetails = (
    {
        onSubmit,
        register,
        handleSubmit,
        errors,
        fields,
        append,
        remove,
        prizeType,
        users,
        setUsers,
        reset,
        countries,
        setUserId,
        userId,
        deleteAttachment,
        permissions,
        userName
    }) => {

    return (
        <div className='employee_info'>
            <div className='card shadow border-0 mt-2'>
                <div className='card-header'>
                    <EmployeeHeader register={register} setUsers={setUsers} users={users} setUserId={setUserId} userId={userId}
                            errors={errors} e_title="পুরস্কার সম্পর্কিত তথ্য" userName={userName} />
                </div>
                <div className='card-body'>
                    <form className='pt-4' onSubmit={handleSubmit(onSubmit)}>
                        {fields.map((item, index) => {
                            let attachments = item.attachments;
                            return (
                                <div key={item.id}>
                                    <NestedFormIncDec fields={fields} append={append} remove={remove} index={index}/>
                                    <div className='row'>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">পুরস্কারের ধরণ <span
                                                className='text-danger'>*</span></label>
                                            <select className="form-select" aria-label="Default select example"
                                                    {...register(`prizeDetails.${index}.type`, {required: true})}>
                                                <option value="" style={{color: 'gray'}}>নির্বাচন করুন</option>
                                                {
                                                    prizeType?.map(prize => <option key={prize.id}
                                                                                    value={prize.id}>{prize.bn}</option>)
                                                }
                                            </select>
                                            {errors?.prizeDetails?.[index]?.type &&
                                                <h6 className='text-danger h6'>This field is required.</h6>}
                                        </div>
                                        <FourColumn/>
                                    </div>

                                    <div className='row'>
                                        <div className='col-xs-auto col-md mb-3'>
                                            <label htmlFor="inputAddress" className="form-label">দেশের নাম<span
                                                className='text-danger'>*</span></label>
                                            <select className="form-select" aria-label="Default select example"
                                                    {...register(`prizeDetails.${index}.country_name`, {required: true})}>
                                                <option value="" style={{color: 'gray'}}>নির্বাচন করুন</option>
                                                {
                                                    countries?.map(country => <option key={country.id}
                                                                                        value={country.id}>{country.bn}</option>)
                                                }
                                            </select>
                                            {errors?.prizeDetails?.[index]?.country_name &&
                                                <h6 className='text-danger h6'>This field is required.</h6>}
                                        </div>
                                        <div className='col-xs-auto col-md mb-3'>
                                            <label htmlFor="inputAddress" className="form-label">দাতা প্রতিষ্ঠানের
                                                নাম</label>
                                            <input className="form-control"
                                                    {...register(`prizeDetails.${index}.donor_organization_name`)}
                                            />
                                        </div>
                                        <div className='col-xs-auto col-md mb-3'>
                                            <label htmlFor="inputAddress" className="form-label">পুরস্কার প্রদানের
                                                স্থান</label>
                                            <input className="form-control"
                                                    {...register(`prizeDetails.${index}.place_of_award`)}
                                            />
                                        </div>
                                        <div className='col-xs-auto col-md mb-3'>
                                            <label htmlFor="inputAddress" className="form-label">পুরস্কার প্রদানের
                                                তারিখ<span className='text-danger'>*</span></label>
                                            <input className="form-control" type="date"
                                                    {...register(`prizeDetails.${index}.date_of_award`, {required: true})}
                                            />
                                            {errors?.prizeDetails?.[index]?.date_of_award &&
                                                <h6 className='text-danger h6'>This field is required.</h6>}
                                        </div>
                                        <div className='col-xs-auto col-md mb-3'>
                                            <label htmlFor="inputAddress" className="form-label">পুরস্কার প্রাপ্তির
                                                বিষয়<span className='text-danger'>*</span></label>
                                            <input className="form-control"
                                                    {...register(`prizeDetails.${index}.subject_of_award`, {required: true})}
                                            />
                                            {errors?.prizeDetails?.[index]?.subject_of_award &&
                                                <h6 className='text-danger h6'>This field is required.</h6>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-xs-auto col-md btn_file text-center">
                                            <label htmlFor="attachments" className="form-label"></label>
                                            <br/>
                                            <FileUpload
                                                title="প্রমাণক সংযুক্ত করুন"
                                                register={register}
                                                registerkey={`prizeDetails.${index}.attachments`}
                                                attachments={attachments}
                                                deleteAttachment={deleteAttachment}
                                            />
                                        </div>
                                        <FourColumn/>
                                    </div>
                                </div>
                            );
                        })}
                        {((isAdmin() || permissions?.includes('edit'))) &&  <FormSubmitBtn reset={reset}/> }
                    </form>
                </div>
            </div>
        </div>
    );
};

export default PrizeInfoDetails;