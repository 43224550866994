import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { AiOutlineInfo, AiOutlineInfoCircle } from 'react-icons/ai';
import './tooltips.css';

function TooltipsPercentage() {
  return (
    <>
        <OverlayTrigger
          key='top'
          placement='top'
          overlay={
            <Tooltip id={`tooltip-top`} style={{textAlign: 'left !important'}}>
                <ul className='mt-3'>
                    <li  style={{textAlign: 'left !important'}}>ব্যক্তিগত তথ্য (২০%)</li>
                    <li>কর্মকর্তা/কর্মচারীর ঠিকানা (২০%)</li>
                    <li>প্রাতিষ্ঠানিক শিক্ষার তথ্য (২০%)</li>
                    <li>বর্তমান চাকুরির তথ্য (২০%)</li>
                    <li>যোগদানের তথ্য (২০%)</li>
                </ul>
            </Tooltip>
          }
        >
          <Button variant='' style={{backgroundColor: 'rgb(104, 48, 145)', color: 'white'}} className='btn-sm text-center'>
            <AiOutlineInfoCircle /><small className='mt-1'> প্রোফাইল %</small>
          </Button>
        </OverlayTrigger>
    </>
  );
}

export default TooltipsPercentage;