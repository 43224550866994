import React from 'react';
import {useParams} from 'react-router-dom';
import useEmployeeInfo from '../../../Hooks/useEmployeeInfo';
import Loader from '../../../Components/Loader/Loader';
import {formatDate, translateToBanglaDigit} from "../../../helpers/helpers";
import Table from "react-bootstrap/Table";
import {BsThreeDotsVertical} from 'react-icons/bs';
import {Dropdown} from "react-bootstrap";
import AttachmentsPage from "./AttachmentsPage";
import useEmployeeById from "../../../Hooks/useEmployeeById";


const EmployeeAttachmentViewPage = () => {
    const {id} = useParams();
    const {basicInfo: {data}, loading} = useEmployeeById(id);
    const currentAddress = (field) => {
        const CURRENT = 1;
        let currentAddress = data.address.find((address) => address.type === CURRENT);
        if (currentAddress) {
            return currentAddress[field];
        }
        return '';
    }
    const permanentAddress = (field) => {
        const PERMANENT = 2;
        let permanentAddress = data.address.find((address) => address.type === PERMANENT);
        if (permanentAddress) {
            return permanentAddress[field];
        }
        return '';
    }
    if (loading) {
        return <Loader/>
    }

    return (
        <div className="component_container py-2 my-3">
            <div className="">
                <div className="row">
                    <div className="col-lg-12">
                        <Dropdown className="float-end">
                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                <BsThreeDotsVertical/>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href={`/employee-info/${id}/view`}>View</Dropdown.Item>
                                <Dropdown.Item href={`/employee-info/${id}/view-full`}>Full View</Dropdown.Item>
                                {/*<Dropdown.Item*/}
                                {/*    href={`/employee-info/${id}/view-attachments`}>Attachments</Dropdown.Item>*/}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="row employee_info">
                    <div className="col-lg-6">
                        <div className="col-lg-12 mt-3">
                            <div className="card">
                                <h5 className="card-header">সাধারণ তথ্য</h5>
                                <div className="card-body">
                                    <Table bordered>
                                        <thead>
                                        <tr>
                                            <th>সংযুক্তি</th>
                                            <th>:</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            data?.hasOwnProperty('attachments') &&
                                            <AttachmentsPage attachments={data.attachments}/>
                                        }
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <th className="text-center" colSpan={2}>সংযুক্তি পাওয়া যায়নি।</th>
                                        </tr>
                                        </tfoot>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 mt-3">
                            <div className="card">
                                <h5 className="card-header">স্বামী / স্ত্রীর তথ্য</h5>
                                <div className="card-body">
                                    <Table bordered>
                                        <thead>
                                        <tr>
                                            <th>সংযুক্তি</th>
                                            <th>:</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            data?.spouse?.hasOwnProperty('attachments') &&
                                            <AttachmentsPage attachments={data?.spouse?.attachments}/>
                                        }
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <th className="text-center" colSpan={2}>সংযুক্তি পাওয়া যায়নি।</th>
                                        </tr>
                                        </tfoot>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 mt-3">
                            <div className="card">
                                <h5 className="card-header">এক্সট্রা-কারিকুলার সংক্রান্ত তথ্য</h5>
                                <div className="card-body">
                                    <Table bordered>
                                        <thead>
                                        <tr>
                                            <th>সংযুক্তি</th>
                                            <th>:</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data?.hasOwnProperty('extra_curriculum_infos') && data.extra_curriculum_infos.map((info, topIndex) => {
                                            return (
                                                <AttachmentsPage key={topIndex} attachments={info.attachments}/>
                                            );
                                        })}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <th className="text-center" colSpan={2}>সংযুক্তি পাওয়া যায়নি।</th>
                                        </tr>
                                        </tfoot>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 mt-3">
                            <div className="card">
                                <h5 className="card-header">প্রকাশনা ও গবেষণা বিষয়ক তথ্য</h5>
                                <div className="card-body">
                                    <Table bordered>
                                        <thead>
                                        <tr>
                                            <th>সংযুক্তি</th>
                                            <th>:</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data?.hasOwnProperty('research_infos') && data.research_infos.map((info, topIndex) => {
                                            return (
                                                <AttachmentsPage key={topIndex} attachments={info.attachments}/>
                                            );
                                        })}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <th className="text-center" colSpan={2}>সংযুক্তি পাওয়া যায়নি।</th>
                                        </tr>
                                        </tfoot>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 mt-3">
                            <div className="card">
                                <h5 className="card-header">পুরস্কার সম্পর্কিত তথ্য</h5>
                                <div className="card-body">
                                    <Table bordered>
                                        <thead>
                                        <tr>
                                            <th>সংযুক্তি</th>
                                            <th>:</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data?.hasOwnProperty('prize_infos') && data.prize_infos.map((info, topIndex) => {
                                            return (
                                                <AttachmentsPage key={topIndex} attachments={info.attachments}/>
                                            );
                                        })}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <th className="text-center" colSpan={2}>সংযুক্তি পাওয়া যায়নি।</th>
                                        </tr>
                                        </tfoot>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 mt-3">
                            <div className="card">
                                <h5 className="card-header">ছুটি সম্পর্কিত তথ্য</h5>
                                <div className="card-body">
                                    <Table bordered>
                                        <thead>
                                        <tr>
                                            <th>সংযুক্তি</th>
                                            <th>:</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data?.hasOwnProperty('leave_infos') && data.leave_infos.map((info, topIndex) => {
                                            return (
                                                <AttachmentsPage key={topIndex} attachments={info.attachments}/>
                                            );
                                        })}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <th className="text-center" colSpan={2}>সংযুক্তি পাওয়া যায়নি।</th>
                                        </tr>
                                        </tfoot>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="col-lg-12 mt-3">
                            <div className="card">
                                <h5 className="card-header">পদোন্নতি তথ্য</h5>
                                <div className="card-body">
                                    <Table bordered>
                                        <thead>
                                        <tr>
                                            <th>সংযুক্তি</th>
                                            <th>:</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data?.hasOwnProperty('promotion_infos') && data.promotion_infos.map((info, topIndex) => {
                                            return (
                                                <AttachmentsPage key={topIndex} attachments={info.attachments}/>
                                            );
                                        })}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <th className="text-center" colSpan={2}>সংযুক্তি পাওয়া যায়নি।</th>
                                        </tr>
                                        </tfoot>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 mt-3">
                            <div className="card">
                                <h5 className="card-header">যোগদানের তথ্য</h5>
                                <div className="card-body">
                                    <Table bordered>
                                        <thead>
                                        <tr>
                                            <th>সংযুক্তি</th>
                                            <th>:</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data?.hasOwnProperty('joining_infos') && data.joining_infos.map((info, topIndex) => {
                                            return (
                                                <AttachmentsPage key={topIndex} attachments={info.attachments}/>
                                            );
                                        })}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <th className="text-center" colSpan={2}>সংযুক্তি পাওয়া যায়নি।</th>
                                        </tr>
                                        </tfoot>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 mt-3">
                            <div className="card">
                                <h5 className="card-header">প্রাতিষ্ঠানিক শিক্ষা</h5>
                                <div className="card-body">
                                    <Table bordered>
                                        <thead>
                                        <tr>
                                            <th>সংযুক্তি</th>
                                            <th>:</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data?.hasOwnProperty('educational_infos') && data.educational_infos.map((info, topIndex) => {
                                            return (
                                                <AttachmentsPage key={topIndex} attachments={info.attachments}/>
                                            );
                                        })}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <th className="text-center" colSpan={2}>সংযুক্তি পাওয়া যায়নি।</th>
                                        </tr>
                                        </tfoot>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 mt-3">
                            <div className="card">
                                <h5 className="card-header">সন্তানদের শিক্ষা</h5>
                                <div className="card-body">
                                    <Table bordered>
                                        <thead>
                                        <tr>
                                            <th>সংযুক্তি</th>
                                            <th>:</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data?.hasOwnProperty('children') && data.children.map((info, topIndex) => {
                                            return (
                                                <AttachmentsPage key={topIndex} attachments={info.attachments}/>
                                            );
                                        })}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <th className="text-center" colSpan={2}>সংযুক্তি পাওয়া যায়নি।</th>
                                        </tr>
                                        </tfoot>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 mt-3">
                            <div className="card">
                                <h5 className="card-header">বদলি সংক্রান্ত তথ্য</h5>
                                <div className="card-body">
                                    <Table bordered>
                                        <thead>
                                        <tr>
                                            <th>সংযুক্তি</th>
                                            <th>:</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data?.hasOwnProperty('transfer_infos') && data.transfer_infos.map((info, topIndex) => {
                                            return (
                                                <AttachmentsPage key={topIndex} attachments={info.attachments}/>
                                            );
                                        })}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <th className="text-center" colSpan={2}>সংযুক্তি পাওয়া যায়নি।</th>
                                        </tr>
                                        </tfoot>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 mt-3">
                            <div className="card">
                                <h5 className="card-header">ভ্রমণ সংক্রান্ত তথ্য</h5>
                                <div className="card-body">
                                    <Table bordered>
                                        <thead>
                                        <tr>
                                            <th>সংযুক্তি</th>
                                            <th>:</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data?.hasOwnProperty('travel_infos') && data.travel_infos.map((info, topIndex) => {
                                            return (
                                                <AttachmentsPage key={topIndex} attachments={info.attachments}/>
                                            );
                                        })}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <th className="text-center" colSpan={2}>সংযুক্তি পাওয়া যায়নি।</th>
                                        </tr>
                                        </tfoot>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 mt-3">
                            <div className="card">
                                <h5 className="card-header">ভাষাগত দক্ষতা সংক্রান্ত তথ্য</h5>
                                <div className="card-body">
                                    <Table bordered>
                                        <thead>
                                        <tr>
                                            <th>সংযুক্তি</th>
                                            <th>:</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data?.hasOwnProperty('language_expertness_infos') && data.language_expertness_infos.map((info, topIndex) => {
                                            return (
                                                <AttachmentsPage key={topIndex} attachments={info.attachments}/>
                                            );
                                        })}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <th className="text-center" colSpan={2}>সংযুক্তি পাওয়া যায়নি।</th>
                                        </tr>
                                        </tfoot>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 mt-3">
                            <div className="card">
                                <h5 className="card-header">প্রশিক্ষণ সংক্রান্ত তথ্য</h5>
                                <div className="card-body">
                                    <Table bordered>
                                        <thead>
                                        <tr>
                                            <th>সংযুক্তি</th>
                                            <th>:</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data?.hasOwnProperty('training_infos') && data.training_infos.map((info, topIndex) => {
                                            return (
                                                <AttachmentsPage key={topIndex} attachments={info.attachments}/>
                                            );
                                        })}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <th className="text-center" colSpan={2}>সংযুক্তি পাওয়া যায়নি।</th>
                                        </tr>
                                        </tfoot>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmployeeAttachmentViewPage;