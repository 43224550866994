import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import useEmployeeInfo from '../../Hooks/useEmployeeInfo';
import {useParams} from 'react-router-dom';
import FieldErrorMessage from "../Utils/FieldErrorMessage";
import Select2Wrapper from "../Utils/Select2Wrapper";
import '../../App.css';
import TooltipsPercentage from '../Utils/TooltipsPercentage';

const Header = ({e_title, register, errors, setUsers, users, setUserId, userId, setValue, getValues, userName= null, id, percentage=null}) => {

    const handleUniqueId = (e) => {
        setUserId(e.target.value);
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-user-wise-unique-id`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            }
        })
            .then(response => {
                setUsers(response.data?.data)
            }).catch(error => {
            console.error(error)
        })
    }, [])

    return (
        <>
        <div className='d-inline d-md-flex justify-content-between'>
            <h4 style={{color: '#683091', fontWeight: 'bold', fontSize: '22px'}}>{e_title} &nbsp;</h4>
            <div style={{marginLeft: '20px !important'}}  className='ml-2'>
                <TooltipsPercentage></TooltipsPercentage>
            </div>

            <div className='ms-auto w-15'>
                { userName && <h6 style={{color: '#683091', fontWeight: 'bold', fontSize: '22px' }}>{ `${userName}` }</h6> }
                <FieldErrorMessage errors={errors} name="user_id" prefix="basic_info"/>
            </div>
        </div>
        </>
    );
};

export default Header;