import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import Loader from '../../Components/Loader/Loader';
import useEmployeeInfo from '../../Hooks/useEmployeeInfo';
import EmployeeEducation from '../../Components/EmployeeInfo/EmployeeEducation';
import {payloadAsFormData, url} from "../../helpers/helpers";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {basicInfoFormatter} from "../../helpers/formatter";

const EmployeeEducationPage = ({permissions}) => {
    const {id} = useParams();
    const {basicInfo, loading, error} = useEmployeeInfo(id);
    const [degrees, setDegrees] = useState([])
    const [exams, setExams] = useState([])
    const [eduData, setEduData] = useState();
    const [eduId, setEduId] = useState()
    const [eduSubject, setEduSubject] = useState([])
    const [eduIndex, setEduIndex] = useState()
    const [eduBoard, setEduBoard] = useState()

    const { register, control, handleSubmit, setValue, formState: { errors }, reset } = useForm({
        defaultValues: {
            education: [{ type: "", exam_name: "", board: "", institute: "", department: "", grade: "", result: "", passing_year: "", roll_no: "", session: "", attachments: ""  }]
        }
    });
    const {fields, append, remove} = useFieldArray({
        control,
        name: "education"
    });

    useEffect(() => {
        if (basicInfo?.data?.educational_infos.length > 0) {
            setValue(`education`, basicInfo?.data?.educational_infos);
        } else {
            setValue(`education`, [{ type: "", exam_name: "", board: "", institute: "", department: "", grade: "", result: "", passing_year: "", roll_no: "", session: "", attachments: ""  }]);
        }
    }, [basicInfo?.data, degrees, exams]);

    const onSubmit = (data) => {
        let formData = new FormData();
        formData = payloadAsFormData(formData, 'basic_info', basicInfo?.data)

        for (let topIndex = 0; topIndex < data.education.length; topIndex++ ) {
            let payload = data.education[topIndex];
            formData = payloadAsFormData(formData, `educational_info[${topIndex}]`, payload)
        }

        axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/employee`, formData, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                "Content-Type": "multipart/form-data"
            }
        }).then(response => {
                if (response.data?.message === 'Success') {
                    toast.success('Saved successful.');
                }
            })
            .catch(error => {
                console.error(error);
        });
    }

    const deleteAttachment = async (id) => {
        try {
            const accessToken = localStorage.getItem("accessToken");
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const {data} = await axios.delete(url(`attachment/${id}`), {headers});
            if (data) {
                console.log(data.data)
                toast.success('Attachment deleted successful.')
                window.location.reload()
            }
        } catch (e) {
            toast.error('something went wrong')
        }
    }

    const fetchDropdownData = async () => {
        try {
          const accessToken = localStorage.getItem("accessToken");
          const headers = {
            Authorization: `Bearer ${accessToken}`,
          };
    
          const [eduData] = await Promise.all([
              axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-educational-qualification`, {headers}),
          ]);
          setEduData(eduData?.data?.data);
        } catch (error) {
          console.error(error);
        }
    };

    useEffect(()=>{
        fetchDropdownData();
    },[])

    const fetchEduSubject = async () => {
        try {
            const accessToken = localStorage.getItem("accessToken");
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
        
            const [eduIdInfo, eduBoard] = await Promise.all([
                axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-educational-qualification/${eduId}`, {headers}),
                axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-education-board`, {headers}),
            ]);

            setEduSubject(prevState => ({
                ...prevState,
                [eduIndex]: eduIdInfo?.data?.data
            }));

            setEduBoard(prevState => ({
                ...prevState,
                [eduIndex]: eduBoard?.data?.data
            }));

        } catch (error) {
          console.error(error);
        }
    };

    useEffect(()=>{
        fetchEduSubject()
    }, [eduId, setEduSubject, eduIndex])

    if(loading){
        return <Loader />
    }

    return (
        <div className='component_container py-2 my-3 py-md-5 my-md-5'>
            <ToastContainer />
            <EmployeeEducation
                onSubmit={onSubmit}
                register={register}
                handleSubmit={handleSubmit}
                errors={errors}
                fields={fields}
                append={append}
                remove={remove}
                degrees={degrees}
                exams={exams}
                eduData={eduData}
                setEduId={setEduId}
                eduSubject={eduSubject}
                setEduIndex={setEduIndex}
                eduBoard={eduBoard}
                reset={reset}
                deleteAttachment={deleteAttachment}
                permissions={permissions}
                userName={ basicInfo?.data?.name_in_bn}
            />
        </div>
    );
};

export default EmployeeEducationPage;