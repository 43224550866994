import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LeaveManagement from '../../Components/LeaveManagement/LeaveManagement';
import { useFieldArray, useForm } from 'react-hook-form';
import axios from 'axios';
import {authUser, payloadAsFormData, url} from '../../helpers/helpers';
import { ToastContainer, toast } from 'react-toastify';
import {useParams} from "react-router";
import useEmployeeInfo from "../../Hooks/useEmployeeInfo";
import Loader from '../../Components/Loader/Loader';

const LeaveManagementPage = ({permissions}) => {
    const {id} = useParams()
    const {basicInfo: {data}, loading, error} = useEmployeeInfo(id);
    const navigate = useNavigate();
    const [userId, setUserId] = useState();
    const [users, setUsers] = useState();
    const [leaveTypes, setLeaveTypes] = useState([]);
    const [userInfo, setUserInfo] = useState();
    const [dataLoading, setDataLoading] = useState(true);

    const { register, control, handleSubmit, setValue, getValues, formState: { errors } } = useForm({
        defaultValues: {
            leave: [
                {
                    reason: "",
                    start_date: "",
                    end_date: "",
                    order_no: "",
                    order_date: "",
                    remarks: "",
                    attachments: ""
                }
            ]
        }
    });

    const {fields, append, remove} = useFieldArray({
        control,
        name: "leave"
    });
    useEffect(() => {
        if (!data || typeof data === 'undefined') return;
        setUserId(data.user_id)
        fetchUserInfo(data.user_id)
    }, [data]);

    const onSubmit = (data) => {
        let formData = new FormData();
        formData = payloadAsFormData(formData, 'basic_info', userInfo)
        for (let topIndex = 0; topIndex < data.leave.length; topIndex++ ) {
            let payload = data.leave[topIndex];
            formData = payloadAsFormData(formData, `leave_info[${topIndex}]`, payload)
        }

        axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/employee`, formData, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                "Content-Type": "multipart/form-data"
            }
        }).then(response => {
                if (response.data?.message === 'Success') {
                    toast.success('Saved successful.');
                }
            })
            .catch(error => {
                console.error(error);
        });
    }

    const deleteAttachment = async (id) => {
        try {
            const accessToken = localStorage.getItem("accessToken");
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const {data} = await axios.delete(url(`attachment/${id}`), {headers});
            if (data) {
                toast.success('Attachment deleted successful.')
                window.location.reload()
            }
        } catch (e) {
            toast.error('something went wrong')
        }
    }

    const fetchUserInfo = async (id) => {
        if (!id) return;
        try {
            const accessToken = localStorage.getItem("accessToken");
            const headers = {
              Authorization: `Bearer ${accessToken}`,
            };
            setDataLoading(true)
            const [userInfo] = await Promise.all([
              axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-user-info/${id}`, {headers}),
            ]);
            const userData = userInfo?.data?.data;
            setUserInfo(userData)
            if(userData?.leave_infos.length > 0){
                setValue('leave', userData?.leave_infos)
            } else {
                setValue('leave', [
                    {
                        reason: "",
                        start_date: "",
                        end_date: "",
                        order_no: "",
                        order_date: "",
                        remarks: "",
                        attachments: ""
                    }
                ])
            }
            setDataLoading(false)

        } catch (error) {
            console.error(error);
        }
    }

    const fetchLeaveTypes = async () => {
        try {
            const accessToken = localStorage.getItem("accessToken");
            const headers = {
              Authorization: `Bearer ${accessToken}`,
            };

            setDataLoading(true)
            const [userInfo] = await Promise.all([
              axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-leave-types`, {headers}),
            ]);

            setDataLoading(false)
            setLeaveTypes(userInfo?.data.data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(()=>{
        fetchLeaveTypes();
    }, [])

    useEffect(()=>{
        if(!localStorage.getItem('accessToken')){
            navigate('/login')
        }
    },[localStorage.getItem('accessToken')])

    return (
        <div>
            <div style={{display: (loading || dataLoading) ? "block" : "none"}}>
                <Loader/>
            </div>
            <ToastContainer/>
            <div style={{display: (loading || dataLoading) ? "none" : "block"}} className="component_container py-2 my-3">
                <LeaveManagement
                    onSubmit={onSubmit}
                    register={register}
                    setValue={setValue}
                    getValues={getValues}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    fields={fields}
                    append={append}
                    remove={remove}
                    setUsers={setUsers}
                    users={users}
                    userId={userId}
                    deleteAttachment={deleteAttachment}
                    permissions={permissions}
                    leaveTypes={leaveTypes}
                    userName={data?.name_in_bn}
                />
            </div>
        </div>
    );
};

export default LeaveManagementPage;