import React, {useEffect, useState} from "react";
import {useFieldArray, useForm} from "react-hook-form";
import {axiosConfig, isAdmin, payloadAsFormData, url} from "../../helpers/helpers";
import axios from "axios";
import {toast} from "react-toastify";
import NestedFormIncDec from "../EmployeeCommonSection/ NestedFormIncDec";
import FileUpload from "../Utils/FileUpload";
import FieldErrorMessage from "../Utils/FieldErrorMessage";
import FormSubmitBtn from "../EmployeeCommonSection/FormSubmitBtn";
import Select2Wrapper from "../Utils/Select2Wrapper";
import EmployeeHeader from "../EmployeeCommonSection/EmployeeHeader";

const PromotionInformation = ({userInfo, userId, employeeId, deleteAttachment, permissions}) => {
    const [initialValues] = useState([{
        id: "",
        employee_id: "",
        designation: "",
        ministry_name: "",
        joining_date: "",
        order_no: "",
        order_date: "",
        last_working_date: "",
        grade: "",
        salary_scale: "",
        attachments: ""
    }]);

    const [errors, setErrors] = useState([]);
    const [ministryOrganizationAndInstitution, setMinistryOrganizationAndInstitution] = useState([]);
    const [designation, setDesignation] = useState([]);

    const {control, register,getValues, setValue, handleSubmit} = useForm({
        defaultValues: {
            promotionInformation: initialValues
        }
    });

    const {fields, append, remove} = useFieldArray({
        control, name: "promotionInformation"
    });

    useEffect(() => {
        if (userInfo?.hasOwnProperty('promotion_infos') && userInfo.promotion_infos.length > 0) {
            setValue('promotionInformation', userInfo.promotion_infos);
        }
    }, [userInfo]);
    useEffect(() => {
        getMinistryOrganizationAndInstitution();
        getDesignation();
    }, []);
    const getMinistryOrganizationAndInstitution = () => {
        try {
            axios.get(url(`fetch-ministry-organization-and-institution`), axiosConfig())
                .then(({data: {data}}) => {
                    setMinistryOrganizationAndInstitution(data);
                })
                .catch((error) => {
                    console.log(errors);
                })
        } catch (e) {
            toast.error('something went wrong')
        }
    }
    const getDesignation = () => {
        try {
            axios.get(url(`fetch-designation`), axiosConfig())
                .then(({data: {data}}) => {
                    setDesignation(data)
                })
                .catch((error) => {
                    console.log(errors);
                })
        } catch (e) {
            toast.error('something went wrong')
        }
    }
    const onSubmit = (data) => {
        let formData = new FormData();

        if (!employeeId) {
            toast.warning('Select Employee First!');
            return;
        }

        for (let topIndex = 0; topIndex < data.promotionInformation.length; topIndex++) {
            let payload = data.promotionInformation[topIndex];
            payload['employee_id'] = employeeId;
            formData = payloadAsFormData(formData, `promotion_info[${topIndex}]`, payload)
        }

        axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/promotion-info`, formData, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("accessToken")}`, "Content-Type": "multipart/form-data"
            }
        }).then(response => {
            if (response.data?.message === 'Success') {
                toast.success('Saved successful.');
            }
        }).catch(error => {
            if (error.response.status === 422) {
                setErrors(error.response.data.errors);
            }
        });
    }

    return (<>
        <div className="employee_info">
            <div className='card shadow border-0 mt-2'>
                <div className="card-header">
                    <EmployeeHeader e_title="পদোন্নতি"  userName={userInfo?.name_in_bn} e_num="2200443689"/>
                </div>
                <div className='card-body'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {fields.map((field, index) => {
                            let attachments = field?.attachments;
                            return (
                                <div key={field.id}>
                                    <input type="hidden"
                                            className="form-control"
                                            {...register(`promotionInformation.${index}.employee_id`)}
                                    />
                                    <NestedFormIncDec fields={fields} append={append} remove={remove} index={index}/>
                                    <div className='row'>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label className="form-label">পদবি <span
                                                className='text-danger'>*</span></label>
                                            {/*<input type="text"*/}
                                            {/*       className="form-control"*/}
                                            {/*       {...register(`promotionInformation.${index}.designation`)}*/}
                                            {/*/>*/}
                                            <Select2Wrapper
                                                placeholder="পদবি নির্বাচন করুন"
                                                value={getValues(`promotionInformation.${index}.designation`)}
                                                onChange={(value) => setValue(`promotionInformation.${index}.designation`, value)}
                                                options={designation}
                                            />
                                            <FieldErrorMessage errors={errors} name="designation" prefix="promotion_info"
                                                                index={index}/>
                                        </div>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label className="form-label">মন্ত্রণালয় /দপ্তর /সংস্থা/প্রতিষ্ঠানের
                                                নাম <span
                                                    className='text-danger'>*</span></label>
                                            {/*<input type="text"*/}
                                            {/*       className="form-control"*/}
                                            {/*       {...register(`promotionInformation.${index}.ministry_name`)}*/}
                                            {/*/>*/}
                                            <Select2Wrapper
                                                value={getValues(`promotionInformation.${index}.ministry_name`)}
                                                onChange={(value) => setValue(`promotionInformation.${index}.ministry_name`, value)}
                                                options={ministryOrganizationAndInstitution}
                                            />
                                            <FieldErrorMessage errors={errors} name="ministry_name" prefix="promotion_info"
                                                                index={index}/>
                                        </div>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label className="form-label">যোগদানের তারিখ </label>
                                            <input type="date" className="form-control"
                                                    {...register(`promotionInformation.${index}.joining_date`)}
                                            />
                                        </div>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label className="form-label">আদেশ নম্বর <span
                                                className='text-danger'>*</span></label>
                                            <input type="text"
                                                    className="form-control"
                                                    {...register(`promotionInformation.${index}.order_no`)}
                                            />
                                            <FieldErrorMessage errors={errors} name="order_no" prefix="promotion_info"
                                                                index={index}/>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-3 mb-3">
                                            <label className="form-label">আদেশের তারিখ </label>
                                            <input type="date" className="form-control"
                                                    {...register(`promotionInformation.${index}.order_date`)}
                                            />
                                        </div>
                                        <div className="col-3 mb-3">
                                            <label className="form-label">শেষ কর্মদিবসের তারিখ </label>
                                            <input type="date" className="form-control"
                                                    {...register(`promotionInformation.${index}.last_working_date`)}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label className="form-label">প্রমাণক</label>
                                            <FileUpload
                                                title="প্রমাণক সংযুক্ত করুন"
                                                register={register}
                                                registerkey={`promotionInformation.${index}.attachments`}
                                                attachments={attachments}
                                                deleteAttachment={deleteAttachment}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        {((isAdmin() || permissions?.includes('edit'))) &&  <FormSubmitBtn/> }
                    </form>
                </div>
            </div>
        </div>
    </>);
}

export default PromotionInformation;