import React, {useRef} from 'react';
import {useParams} from 'react-router-dom';
import Loader from '../../../Components/Loader/Loader';
import {asset, formatDate, translateToBanglaDigit} from "../../../helpers/helpers";
import Table from "react-bootstrap/Table";
import {BsThreeDotsVertical} from 'react-icons/bs';
import {Dropdown} from "react-bootstrap";
import useEmployeeById from "../../../Hooks/useEmployeeById";
import '../../../App.css'
import AttachmentsPage from "./AttachmentsPage";
import {FaPrint} from "react-icons/fa";
import {useReactToPrint} from "react-to-print";
import avatar from "../../../Assets/avatar.png"
import bdLogo from "../../../Assets/bdLogo.png";


const EmployeeFullViewPage = () => {
    const {id} = useParams();
    const printRef = useRef();
    const {basicInfo: {data}, loading} = useEmployeeById(id);
    const currentAddress = (field) => {
        const CURRENT = 1;
        let currentAddress = data.address.find((address) => address.type === CURRENT);
        if (currentAddress) {
            return currentAddress[field];
        }
        return '';
    }
    const permanentAddress = (field) => {
        const PERMANENT = 2;
        let permanentAddress = data.address.find((address) => address.type === PERMANENT);
        if (permanentAddress) {
            return permanentAddress[field];
        }
        return '';
    }
    const handlePrint = () => {
        print();
    }
    const print = useReactToPrint({
        content: () => printRef.current,
    });

    if (loading) {
        return <Loader/>
    }

    return (
        <div className="component_container py-2 my-3">
            <div className="">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="float-end d-flex justify-content-between">
                            <button className="btn btn-primary" style={{marginRight: '10px'}} onClick={handlePrint}>
                                <FaPrint/>
                            </button>
                            <Dropdown>
                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                    <BsThreeDotsVertical/>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href={`/employee-info/${id}/view`}>View</Dropdown.Item>
                                    <Dropdown.Item href={`/employee-info/${id}/view-full`}>Full View</Dropdown.Item>
                                    {/*<Dropdown.Item*/}
                                    {/*    href={`/employee-info/${id}/view-attachments`}>Attachments</Dropdown.Item>*/}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
              
                <div className="row employee_info" ref={printRef}>
                    <div className="col-12">
                        <center>
                            <img className="mb-2" style={{width: 100 + "px"}} src={bdLogo} alt=""/>
                            <br/>
                            <strong>Personal Management Information System</strong>
                            <br/>
                            <strong>(PMIS)</strong>
                            <br/>
                        </center>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <div className="card">
                            <h5 className="card-header card-header-colored">ব্যক্তিগত তথ্য</h5>
                            <div className="card-body">
                                <table width="100%">
                                    <tbody>
                                    <tr>
                                        <th style={{width: '9%'}} className="py-1">নাম (বাংলায়)</th>
                                        <th className="th-text-middle" style={{width: '1%'}}>:</th>
                                        <td style={{width: '30%'}}>{data?.name_in_bn}</td>
                                        <th style={{width: '9%'}}>নাম (ইংরেজিতে)</th>
                                        <th style={{width: '1%'}}>:</th>
                                        <td style={{width: '30%'}}>{data?.name_in_en}</td>
                                        <td style={{width: '20%', verticalAlign: 'top', textAlign: 'right' }} rowSpan={9}>
                                            <img style={{width: '180px', height: '180px'}} src={data?.image ? asset(data.image) : avatar} alt=""/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="py-1">জন্ম তারিখ</th>
                                        <th className="th-text-middle">:</th>
                                        <td>{translateToBanglaDigit(data?.date_of_birth_view)}</td>
                                        <th>মাতার নাম (বাংলায়)</th>
                                        <th className="th-text-middle">:</th>
                                        <td>{data?.mother_name_in_bn}</td>
                                    </tr>
                                    <tr>
                                        <th className="py-1">মাতার নাম (ইংরেজিতে)</th>
                                        <th className="th-text-middle">:</th>
                                        <td>{data?.mother_name_in_en}</td>
                                        <th>পিতার নাম (বাংলায়)</th>
                                        <th className="th-text-middle">:</th>
                                        <td>{data?.father_name_in_bn}</td>
                                    </tr>
                                    <tr>
                                        <th className="py-1">পিতার নাম (ইংরেজিতে)</th>
                                        <th className="th-text-middle">:</th>
                                        <td>{data?.father_name_in_en}</td>
                                        <th>বৈবাহিক অবস্থা</th>
                                        <th className="th-text-middle">:</th>
                                        <td>{data?.marital_status_text}</td>
                                    </tr>
                                    <tr>
                                        <th className="py-1">ব্যাচ নম্বর</th>
                                        <th className="th-text-middle">:</th>
                                        <td>{data?.current_job_info?.batch_no}</td>
                                        <th>ক্যাডার</th>
                                        <th className="th-text-middle">:</th>
                                        <td>{data?.current_job_info?.cadre === 1 ? "ক্যাডার" :
                                            data?.current_job_info?.cadre === 2 ? "নন-ক্যাডার" :
                                                "অন্যান্য"}</td>
                                    </tr>
                                    <tr>
                                        <th className="py-1">রক্তের গ্রুপ</th>
                                        <th className="th-text-middle">:</th>
                                        <td>{data?.blood_group_text}</td>
                                        <th>শ্রেণি</th>
                                        <th className="th-text-middle">:</th>
                                        <td>{data?.current_job_info?.class}</td>
                                    </tr>
                                    <tr>
                                        <th className="py-1">জেন্ডার</th>
                                        <th className="th-text-middle">:</th>
                                        <td>{data?.gander_text}</td>
                                        <th>পাসপোর্ট নাম্বার</th>
                                        <th className="th-text-middle">:</th>
                                        <td>{data?.passport_no}</td>
                                    </tr>
                                    <tr>
                                        <th className="py-1">কর্মস্থল</th>
                                        <th className="th-text-middle">:</th>
                                        <td>{data?.current_job_info?.ministry_name}</td>
                                        <th className="py-1">পি আর এল তারিখ</th>
                                        <th className="th-text-middle">:</th>
                                        <td>{translateToBanglaDigit(formatDate(data?.current_job_info?.prl_date))}</td>
                                    </tr>
                                    </tbody>
                                </table>

                                <Table bordered className="mt-2">
                                    <thead>
                                    <tr>
                                        <th>সংযুক্তি</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>
                                            {
                                                data?.hasOwnProperty('attachments') &&
                                                <AttachmentsPage attachments={data.attachments}/>
                                            }
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <div className="card">
                            <h5 className="card-header card-header-colored">ঠিকানা তথ্য</h5>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6">
                                        <table style={{width: '100%'}}>
                                            <thead>
                                            <tr style={{borderBottom: '1px solid #ddd'}}>
                                                <th colSpan="3">বর্তমান ঠিকানা</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <th width={{width: '17%'}} className="py-1">বিভাগ</th>
                                                <th className="th-text-middle" style={{width: '3%'}}>:</th>
                                                <td style={{width: '80%'}}>{currentAddress('division_name')}</td>
                                            </tr>
                                            <tr>
                                                <th className="py-1">জেলা</th>
                                                <th className="th-text-middle">:</th>
                                                <td>{currentAddress('district_name')}</td>
                                            </tr>
                                            <tr>
                                                <th className="py-1">উপজেলা</th>
                                                <th className="th-text-middle">:</th>
                                                <td>{currentAddress('upazila_name')}</td>
                                            </tr>
                                            <tr>
                                                <th className="py-1">ডাকঘর</th>
                                                <th className="th-text-middle">:</th>
                                                <td>{currentAddress('post_office')}</td>
                                            </tr>
                                            <tr>
                                                <th className="py-1">বাসা নম্বর</th>
                                                <th className="th-text-middle">:</th>
                                                <td>{currentAddress('house_no')}</td>
                                            </tr>
                                            <tr>
                                                <th className="py-1">রাস্তা নম্বর</th>
                                                <th className="th-text-middle">:</th>
                                                <td>{currentAddress('road_no')}</td>
                                            </tr>
                                            <tr>
                                                <th className="py-1">ওয়ার্ড নং</th>
                                                <th className="th-text-middle">:</th>
                                                <td>{currentAddress('word')}</td>
                                            </tr>
                                            <tr>
                                                <th className="py-1">গ্রাম/শহর</th>
                                                <th className="th-text-middle">:</th>
                                                <td>{currentAddress('village')}</td>
                                            </tr>
                                            <tr>
                                                <th className="py-1">পৌরসভা</th>
                                                <th className="th-text-middle">:</th>
                                                <td>{currentAddress('municipality')}</td>
                                            </tr>
                                            <tr>
                                                <th className="py-1">পোস্টাল কোড</th>
                                                <th className="th-text-middle">:</th>
                                                <td>{currentAddress('postal_code')}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-6">
                                        <table style={{width: '100%'}}>
                                            <thead>
                                            <tr style={{borderBottom: '1px solid #ddd'}}>
                                                <th colSpan="3">স্থায়ী ঠিকানা</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <th width={{width: '17%'}} className="py-1">বিভাগ</th>
                                                <th style={{width: '3%'}} className="th-text-middle">:</th>
                                                <td style={{width: '80%'}}>{permanentAddress('division_name')}</td>
                                            </tr>
                                            <tr>
                                                <th className="py-1">জেলা</th>
                                                <th className="th-text-middle">:</th>
                                                <td>{permanentAddress('district_name')}</td>
                                            </tr>
                                            <tr>
                                                <th className="py-1">উপজেলা</th>
                                                <th className="th-text-middle">:</th>
                                                <td>{permanentAddress('upazila_name')}</td>
                                            </tr>
                                            <tr>
                                                <th className="py-1">ডাকঘর</th>
                                                <th className="th-text-middle">:</th>
                                                <td>{permanentAddress('post_office')}</td>
                                            </tr>
                                            <tr>
                                                <th className="py-1">বাসা নম্বর</th>
                                                <th className="th-text-middle">:</th>
                                                <td>{permanentAddress('house_no')}</td>
                                            </tr>
                                            <tr>
                                                <th className="py-1">রাস্তা নম্বর</th>
                                                <th className="th-text-middle">:</th>
                                                <td>{permanentAddress('road_no')}</td>
                                            </tr>
                                            <tr>
                                                <th className="py-1">ওয়ার্ড নং</th>
                                                <th className="th-text-middle">:</th>
                                                <td>{permanentAddress('word')}</td>
                                            </tr>
                                            <tr>
                                                <th className="py-1">গ্রাম/শহর</th>
                                                <th className="th-text-middle">:</th>
                                                <td>{permanentAddress('village')}</td>
                                            </tr>
                                            <tr className="py-1">
                                                <th className="py-1">পৌরসভা</th>
                                                <th className="th-text-middle">:</th>
                                                <td>{permanentAddress('municipality')}</td>
                                            </tr>
                                            <tr>
                                                <th className="py-1">পোস্টাল কোড</th>
                                                <th className="th-text-middle">:</th>
                                                <td>{permanentAddress('postal_code')}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <div className="card">
                            <h5 className="card-header card-header-colored">স্বামী / স্ত্রীর তথ্য</h5>
                            <div className="card-body">
                                {
                                        data?.hasOwnProperty('spouse_infos') && data.spouse_infos.map((info, index) => {
                                        return (
                                            <>
                                                <div className="row mb-3">
                                                    <div className="col-6">
                                                        <table style={{width: '100%'}}>
                                                            <thead>
                                                            <tr style={{borderBottom: '1px solid #ddd'}}>
                                                                <th colSpan="3" className="py-1">ব্যক্তিগত তথ্য</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                <th width={{width: '20%'}} className="py-1">জাতীয় পরিচয়পত্র নম্বর</th>
                                                                <th style={{width: '3%'}}  className="th-text-middle">:</th>
                                                                <td style={{width: '77%'}}>{info?.nid}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="py-1">নাম (বাংলায়)</th>
                                                                <th className="th-text-middle">:</th>
                                                                <td>{info?.name_in_bn}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="py-1">নাম (ইংরেজিতে)</th>
                                                                <th className="th-text-middle">:</th>
                                                                <td>{info?.name_in_en}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="py-1">রক্তের গ্রুপ</th>
                                                                <th className="th-text-middle">:</th>
                                                                <td>{info?.blood_group_text}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="py-1">পেশা</th>
                                                                <th className="th-text-middle">:</th>
                                                                <td>{info?.occupation}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="py-1">মাতার নাম (বাংলায়)</th>
                                                                <th className="th-text-middle">:</th>
                                                                <td>{info?.mother_name_in_bn}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="py-1">মাতার নাম (ইংরেজিতে)</th>
                                                                <th className="th-text-middle">:</th>
                                                                <td>{info?.mother_name_in_en}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="py-1">পিতার নাম (বাংলায়)</th>
                                                                <th className="th-text-middle">:</th>
                                                                <td>{info?.father_name_in_bn}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="py-1">পিতার নাম (ইংরেজিতে)</th>
                                                                <th className="th-text-middle">:</th>
                                                                <td>{info?.father_name_in_en}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="py-1">মোবাইল নম্বর</th>
                                                                <th className="th-text-middle">:</th>
                                                                <td>{info?.phone}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="col-6">
                                                        <table style={{width: '100%'}}>
                                                            <thead>
                                                            <tr style={{borderBottom: '1px solid #ddd'}}>
                                                                <th colSpan="3" className="py-1">ঠিকানা</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                <th width={{width: '20%'}} className="py-1">বাসা নম্বর</th>
                                                                <th style={{width: '3%'}} className="th-text-middle">:</th>
                                                                <td style={{width: '77%'}}>{info?.house_no}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="py-1">রাস্তা নম্বর</th>
                                                                <th className="th-text-middle">:</th>
                                                                <td>{info?.road_no}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="py-1">গ্রাম/শহর</th>
                                                                <th className="th-text-middle">:</th>
                                                                <td>{info?.village}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="py-1">পৌরসভা</th>
                                                                <th className="th-text-middle">:</th>
                                                                <td>{info?.municipality}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="py-1">ডাকঘর</th>
                                                                <th className="th-text-middle">:</th>
                                                                <td>{info?.post_office}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="py-1">উপজেলা</th>
                                                                <th className="th-text-middle">:</th>
                                                                <td>{info?.upazila}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="py-1">জেলা</th>
                                                                <th className="th-text-middle">:</th>
                                                                <td>{info?.district}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="py-1">বিভাগ</th>
                                                                <th className="th-text-middle">:</th>
                                                                <td>{info?.division}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>সংযুক্তি</th>
                                                                <th className="th-text-middle">:</th>
                                                                <td><AttachmentsPage attachments={info.attachments}/>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </>)
                                        })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <div className="card">
                            <h5 className="card-header card-header-colored">এক্সট্রা-কারিকুলার সংক্রান্ত তথ্য</h5>
                            <div className="card-body">
                                <Table bordered responsive={true}>
                                    <thead>
                                    <tr>
                                        <th>কাজের ধরণ</th>
                                        <th>কাজ শুরুর তারিখ</th>
                                        <th>কাজ শেষের তারিখ</th>
                                        <th>কাজের স্থান</th>
                                        <th>কাজের বর্ণনা</th>
                                        <th>সহযোগী ব্যক্তি বা প্রতিষ্ঠান</th>
                                        <th>সংযুক্তি</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data?.hasOwnProperty('extra_curriculum_infos') && data.extra_curriculum_infos.map((info, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td>{info.type}</td>
                                                    <td>{translateToBanglaDigit(formatDate(info.start_date))}</td>
                                                    <td>{translateToBanglaDigit(formatDate(info.end_date))}</td>
                                                    <td>{info.work_place}</td>
                                                    <td>{info.work_description}</td>
                                                    <td>{info.associated_person_or_institute}</td>
                                                    <td><AttachmentsPage attachments={info.attachments}/></td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <div className="card">
                            <h5 className="card-header card-header-colored">প্রকাশনা ও গবেষণা বিষয়ক তথ্য</h5>
                            <div className="card-body">
                                <Table bordered responsive={true}>
                                    <thead>
                                    <tr>
                                        <th>প্রকাশিত বিষয়ের নাম</th>
                                        <th>প্রকাশের তারিখ</th>
                                        <th>প্রকাশক প্রতিষ্ঠানের নাম</th>
                                        <th>মন্তব্য</th>
                                        <th>সংযুক্তি</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data?.hasOwnProperty('research_infos') && data.research_infos.map((info, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td>{info.name}</td>
                                                    <td>{translateToBanglaDigit(formatDate(info.publish_date))}</td>
                                                    <td>{info.institute}</td>
                                                    <td>{info.comment}</td>
                                                    <td><AttachmentsPage attachments={info.attachments}/></td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <div className="card">
                            <h5 className="card-header card-header-colored">পুরস্কার সম্পর্কিত তথ্য</h5>
                            <div className="card-body">
                                <Table bordered responsive={true}>
                                    <thead>
                                    <tr>
                                        <th>পুরস্কারের ধরণ</th>
                                        <th>দেশের নাম</th>
                                        <th>দাতা প্রতিষ্ঠানের নাম</th>
                                        <th>পুরস্কার প্রদানের স্থান</th>
                                        <th>পুরস্কার প্রদানের তারিখ</th>
                                        <th>পুরস্কার প্রাপ্তির বিষয়</th>
                                        <th>সংযুক্তি</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data?.hasOwnProperty('prize_infos') && data.prize_infos.map((info, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td>{info.type_text}</td>
                                                    <td>{info.country_text}</td>
                                                    <td>{info.donor_organization_name}</td>
                                                    <td>{info.place_of_award}</td>
                                                    <td>{translateToBanglaDigit(formatDate(info.date_of_award))}</td>
                                                    <td>{info.subject_of_award}</td>
                                                    <td><AttachmentsPage attachments={info.attachments}/></td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <div className="card">
                            <h5 className="card-header card-header-colored">ছুটি সম্পর্কিত তথ্য</h5>
                            <div className="card-body">
                                <Table bordered responsive={true}>
                                    <thead>
                                    <tr>
                                        <th>ছুটির ধরণ</th>
                                        <th>ছুটি শুরুর তারিখ</th>
                                        <th>ছুটি শেষের তারিখ</th>
                                        <th>ছুটির আদেশ নং</th>
                                        <th>ছুটির আদেশের তারিখ</th>
                                        <th>মন্তব্য</th>
                                        <th>সংযুক্তি</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data?.hasOwnProperty('leave_infos') && data.leave_infos.map((info, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td>{info.reason}</td>
                                                    <td>{translateToBanglaDigit(formatDate(info.start_date))}</td>
                                                    <td>{translateToBanglaDigit(formatDate(info.end_date))}</td>
                                                    <td>{info.order_no}</td>
                                                    <td>{translateToBanglaDigit(formatDate(info.order_date))}</td>
                                                    <td>{info.remarks}</td>
                                                    <td><AttachmentsPage attachments={info.attachments}/></td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <div className="card">
                            <h5 className="card-header card-header-colored">শৃঙ্খলা সম্পর্কিত তথ্য</h5>
                            <div className="card-body">
                                <Table bordered responsive={true}>
                                    <thead>
                                    <tr>
                                        <th>ধরণ</th>
                                        <th>দন্ডের কারণ</th>
                                        <th>দন্ডের বিবরণ</th>
                                        <th>দন্ডের সময়কাল</th>
                                        <th>দন্ডের শুরুর তারিখ</th>
                                        <th>দন্ডের শেষের তারিখ</th>
                                        <th>মন্তব্য</th>
                                        {/*<th>সংযুক্তি</th>*/}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data?.hasOwnProperty('disciplinary_infos') && data.disciplinary_infos.map((info, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td>{info.type_text}</td>
                                                    <td>{info.reason}</td>
                                                    <td>{info.description}</td>
                                                    <td>{info.duration}</td>
                                                    <td>{translateToBanglaDigit(formatDate(info.start_date))}</td>
                                                    <td>{translateToBanglaDigit(formatDate(info.end_date))}</td>
                                                    <td>{info.remarks}</td>
                                                    {/*<td><AttachmentsPage attachments={info.attachments}/></td>*/}
                                                </tr>
                                            </>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <div className="card">
                            <h5 className="card-header card-header-colored">বর্তমান চাকুরির তথ্য</h5>
                            <div className="card-body">
                                <Table bordered responsive={true}>
                                    <thead>
                                    <tr>
                                        <th>পদোন্নতি প্রাপ্ত পদ</th>
                                        <th>মন্ত্রণালয় /দপ্তর /সংস্থা/প্রতিষ্ঠানের নাম</th>
                                        <th>যোগদানের তারিখ</th>
                                        <th>শ্রেণি</th>
                                        <th>পি আর এল তারিখ</th>
                                        <th>প্রাপ্ত গ্রেড</th>
                                        <th>ক্যাডার</th>
                                        <th>ব্যাচ নম্বর</th>
                                        <th>সংযুক্তি</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data?.hasOwnProperty('current_job_info') &&
                                        <tr>
                                            <td>{data?.current_job_info?.designation_name}</td>
                                            <td>{data?.current_job_info?.ministry_name}</td>
                                            <td>{data?.current_job_info?.joining_date && translateToBanglaDigit(formatDate(data?.current_job_info?.joining_date))}</td>
                                            <td>{data?.current_job_info?.class}</td>
                                            <td>{data?.current_job_info?.prl_date && translateToBanglaDigit(formatDate(data?.current_job_info?.prl_date))}</td>
                                            <td>{data?.current_job_info?.grade}</td>
                                            <td>{data?.current_job_info?.cadre_name}</td>
                                            <td>{data?.current_job_info?.batch_no}</td>
                                            <td><AttachmentsPage attachments={data?.current_job_info?.attachments}/></td>
                                        </tr>
                                    }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <div className="card">
                            <h5 className="card-header card-header-colored">যোগদানের তথ্য</h5>
                            <div className="card-body">
                                <Table bordered responsive={true}>
                                    <thead>
                                    <tr>
                                        <th>পদোন্নতি প্রাপ্ত পদ</th>
                                        <th>মন্ত্রণালয় /দপ্তর /সংস্থা/প্রতিষ্ঠানের নাম</th>
                                        <th>যোগদানের তারিখ</th>
                                        <th>আদেশ নম্বর</th>
                                        <th>আদেশের তারিখ</th>
                                        <th>শেষ কর্মদিবসের তারিখ</th>
                                        <th>প্রাপ্ত গ্রেড</th>
                                        <th>বেতন স্কেল</th>
                                        <th>সংযুক্তি</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data?.hasOwnProperty('joining_infos') && data.joining_infos.map((info, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td>{info.designation_name}</td>
                                                    <td>{info.ministry_name}</td>
                                                    <td>{info.joining_date && translateToBanglaDigit(formatDate(info.joining_date))}</td>
                                                    <td>{info.order_no}</td>
                                                    <td>{info.order_date && translateToBanglaDigit(formatDate(info.order_date))}</td>
                                                    <td>{info.last_working_date && translateToBanglaDigit(formatDate(info.last_working_date))}</td>
                                                    <td>{info.grade}</td>
                                                    <td>{info.salary_scale}</td>
                                                    <td><AttachmentsPage attachments={info.attachments}/></td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <div className="card">
                            <h5 className="card-header card-header-colored">পদোন্নতি তথ্য</h5>
                            <div className="card-body">
                                <Table bordered responsive={true}>
                                    <thead>
                                    <tr>
                                        <th>পদোন্নতি প্রাপ্ত পদ</th>
                                        <th>মন্ত্রণালয় /দপ্তর /সংস্থা/প্রতিষ্ঠানের নাম</th>
                                        <th>যোগদানের তারিখ</th>
                                        <th>আদেশ নম্বর</th>
                                        <th>আদেশের তারিখ</th>
                                        <th>শেষ কর্মদিবসের তারিখ</th>
                                        <th>সংযুক্তি</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data?.hasOwnProperty('promotion_infos') && data.promotion_infos.map((info, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td>{info.designation_name}</td>
                                                    <td>{info.ministry_name}</td>
                                                    <td>{info.joining_date && translateToBanglaDigit(formatDate(info.joining_date))}</td>
                                                    <td>{info.order_no}</td>
                                                    <td>{info.order_date && translateToBanglaDigit(formatDate(info.order_date))}</td>
                                                    <td>{info.last_working_date && translateToBanglaDigit(formatDate(info.last_working_date))}</td>
                                                    <td><AttachmentsPage attachments={info.attachments}/></td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <div className="card">
                            <h5 className="card-header card-header-colored">প্রাতিষ্ঠানিক তথ্য</h5>
                            <div className="card-body">
                                <Table bordered responsive={true}>
                                    <thead>
                                    <tr>
                                        <th>শিক্ষাগত যোগ্যতা</th>
                                        <th>শিক্ষা প্রতিষ্ঠান</th>
                                        {/* <th>শিক্ষাগত যোগ্যতা</th> */}
                                        <th>বোর্ড</th>
                                        <th>বিভাগ / বিষয় / অর্জিত ডিগ্রি</th>
                                        <th>অর্জিত ফলাফল</th>
                                        <th>পাশের বছর</th>
                                        <th>রোল নাম্বার</th>
                                        <th>সেশন</th>
                                        <th>সংযুক্তি</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data?.hasOwnProperty('educational_infos') && data.educational_infos.map((info, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td>{info.type_text}</td>
                                                    <td>{info.institute}</td>
                                                    {/* <td>{info.exam_name_text}</td> */}
                                                    <td>{info.board_text}</td>
                                                    <td>{info.department}</td>
                                                    <td>{info.grade}</td>
                                                    <td>{info.passing_year}</td>
                                                    <td>{info.roll_no}</td>
                                                    <td>{info.session}</td>
                                                    <td><AttachmentsPage attachments={info.attachments}/></td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <div className="card">
                            <h5 className="card-header card-header-colored">সন্তানদের শিক্ষা</h5>
                            <div className="card-body">
                                <Table bordered responsive={true}>
                                    <thead>
                                    <tr>
                                        <th>জাতীয় পরিচয়পত্র নম্বর</th>
                                        <th>জন্ম নিবন্ধন নম্বর</th>
                                        <th>নাম (বাংলায়)</th>
                                        <th>নাম (ইংরেজিতে)</th>
                                        <th>জন্ম তারিখ</th>
                                        <th>রক্তের গ্রুপ</th>
                                        <th>জেন্ডার</th>
                                        <th>স্থায়ী ঠিকানা</th>
                                        <th>প্রতিবন্ধী কিনা</th>
                                        <th>সংযুক্তি</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data?.hasOwnProperty('children') && data.children.map((info, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td>{info.nid}</td>
                                                    <td>{info.birth_certificate_no}</td>
                                                    <td>{info.name_in_bn}</td>
                                                    <td>{info.name_in_en}</td>
                                                    <td>{translateToBanglaDigit(formatDate(info.date_of_birth))}</td>
                                                    <td>{info.blood_group_text}</td>
                                                    <td>{info.gender_text}</td>
                                                    <td>{info.address}</td>
                                                    <td>{info.is_autistic ? 'হ্যা' : 'না'}</td>
                                                    <td><AttachmentsPage attachments={info.attachments}/></td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <div className="card">
                            <h5 className="card-header card-header-colored">বদলি সংক্রান্ত তথ্য</h5>
                            <div className="card-body">
                                <Table bordered responsive={true}>
                                    <thead>
                                    <tr>
                                        <th>বদলির ধরণ</th>
                                        <th>দেশের নাম</th>
                                        <th>প্রতিষ্ঠান</th>
                                        <th>পদায়নের স্থান</th>
                                        <th>দপ্তরের ঠিকানা</th>
                                        <th>পদবি</th>
                                        <th>বদলি আদেশের নম্বর</th>
                                        <th>বদলি আদেশের তারিখ</th>
                                        <th>বদলির কারণ</th>
                                        <th>পদায়নের ধরণ</th>
                                        <th>বদলির ধরণ</th>
                                        <th>যোগদানের তারিখ</th>
                                        <th>বেতন স্কেল</th>
                                        <th>মন্তব্য</th>
                                        <th>সংযুক্তি</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data?.hasOwnProperty('transfer_infos') && data.transfer_infos.map((info, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td>{info.type_text}</td>
                                                    <td>{info.country_text}</td>
                                                    <td>{info.institute}</td>
                                                    <td>{info.place_of_posting}</td>
                                                    <td>{info.office_address}</td>
                                                    <td>{info.designation_name}</td>
                                                    <td>{info.transfer_order_no}</td>
                                                    <td>{translateToBanglaDigit(formatDate(info.transfer_order_date))}</td>
                                                    <td>{info.transfer_order_cause}</td>
                                                    <td>{info.type_of_posting}</td>
                                                    <td>{info.type_of_transfer}</td>
                                                    <td>{translateToBanglaDigit(formatDate(info.joining_date))}</td>
                                                    <td>{info.salary_scale}</td>
                                                    <td>{info.remarks}</td>
                                                    <td><AttachmentsPage attachments={info.attachments}/></td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <div className="card">
                            <h5 className="card-header card-header-colored">ভ্রমণ সংক্রান্ত তথ্য</h5>
                            <div className="card-body">
                                <Table bordered responsive={true}>
                                    <thead>
                                    <tr>
                                        <th>ভ্রমনের ধরণ</th>
                                        <th>দেশের নাম</th>
                                        <th>মন্ত্রণালয়ের নাম</th>
                                        <th>ভ্রমণাদেশ অনুমতির নম্বর</th>
                                        <th>ভ্রমণের স্থান</th>
                                        <th>ভ্রমণের কারণ</th>
                                        <th>ভ্রমণ শুরুর তারিখ</th>
                                        <th>ভ্রমণ শেষের তারিখ</th>
                                        <th>মন্তব্য</th>
                                        {/*<th>সংযুক্তি</th>*/}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data?.hasOwnProperty('travel_infos') && data.travel_infos.map((info, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td>{info.type_text}</td>
                                                    <td>{info.country_text}</td>
                                                    <td>{info.ministry_name_text}</td>
                                                    <td>{info.travel_order_no}</td>
                                                    <td>{info.travel_place}</td>
                                                    <td>{info.travel_cause}</td>
                                                    <td>{translateToBanglaDigit(formatDate(info.travel_start_date))}</td>
                                                    <td>{translateToBanglaDigit(formatDate(info.travel_stop_date))}</td>
                                                    <td>{info.remarks}</td>
                                                    {/*<td><AttachmentsPage attachments={info.attachments}/></td>*/}
                                                </tr>
                                            </>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <div className="card">
                            <h5 className="card-header card-header-colored">ভাষাগত দক্ষতা সংক্রান্ত তথ্য</h5>
                            <div className="card-body">
                                <Table bordered responsive={true}>
                                    <thead>
                                    <tr>
                                        <th>ভাষার নাম</th>
                                        <th>দক্ষতার ধরণ</th>
                                        <th width="40%">সংযুক্তি</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data?.hasOwnProperty('language_expertness_infos') && data.language_expertness_infos.map((info, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td>{info.language_name}</td>
                                                    <td>{info.expertness_text}</td>
                                                    <td><AttachmentsPage attachments={info.attachments}/></td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-3">
                        <div className="card">
                            <h5 className="card-header card-header-colored">প্রশিক্ষণ সংক্রান্ত তথ্য</h5>
                            <div className="card-body">
                                <Table bordered responsive={true}>
                                    <thead>
                                    <tr>
                                        <th>দেশের নাম</th>
                                        <th>কোর্সের নাম</th>
                                        <th>প্রশিক্ষণদাতা প্রতিষ্ঠানের নাম</th>
                                        <th>প্রশিক্ষণ শুরুর তারিখ</th>
                                        <th>প্রশিক্ষণ শেষের তারিখ</th>
                                        <th>অর্জিত ফলাফল</th>
                                        <th>মন্তব্য</th>
                                        <th>সংযুক্তি</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data?.hasOwnProperty('training_infos') && data.training_infos.map((info, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td>{info.country_text}</td>
                                                    <td>{info.course_name}</td>
                                                    <td>{info.institution_name}</td>
                                                    <td>{translateToBanglaDigit(formatDate(info.training_start_date))}</td>
                                                    <td>{translateToBanglaDigit(formatDate(info.training_end_date))}</td>
                                                    <td>{info.result}</td>
                                                    <td>{info.remarks}</td>
                                                    <td><AttachmentsPage attachments={info.attachments}/></td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default EmployeeFullViewPage;