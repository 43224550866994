import React, {useEffect, useState} from 'react';
import EmployeeAddressCom from '../../Components/EmployeeInfo/EmployeeAddress';
import useEmployeeInfo from '../../Hooks/useEmployeeInfo';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import {useFieldArray, useForm} from 'react-hook-form';
import Loader from '../../Components/Loader/Loader';
import {toast, ToastContainer} from "react-toastify";
import {axiosConfig, url} from "../../helpers/helpers";

const EmployeeAddressPage = ({ permissions }) => {
    const {id} = useParams();
    const [address, setAddress] = useState([])
    const {basicInfo, loading} = useEmployeeInfo(id);
    const [users, setUsers] = useState();
    const [errors, setErrors] = useState([]);
    const [division, setDivision] = useState([]);
    const [district, setDistrict] = useState([]);
    const [upazilla, setUpazilla] = useState([]);
    console.log(basicInfo?.data?.name_in_bn, "basicInfo?.data?.name_in_bn")
    const defaultAddress = [
        {
            type: 1,
            house_no: "",
            road_no: "",
            word: "",
            village: "",
            municipality: "",
            post_office: "",
            upazila: "",
            district: "",
            postal_code: "",
            division: "",
            phone: "",
            email: ""
        },
        {
            type: 2,
            house_no: "",
            road_no: "",
            word: "",
            village: "",
            municipality: "",
            post_office: "",
            upazila: "",
            district: "",
            postal_code: "",
            division: "",
            phone: "",
            email: ""
        }
    ];

    const {register, control, handleSubmit, setValue, getValues, reset} = useForm({
        defaultValues: {
            address: defaultAddress
        }
    });

    const {fields, append, remove} = useFieldArray({
        control,
        name: "address"
    });

    const getDivision = () => {
        try {
            axios.get(url(`fetch-division`), axiosConfig())
                .then(({data: {data}}) => {
                    setDivision(data);
                })
                .catch((error) => {
                    console.log(error);
                })
        } catch (e) {
            toast.error('something went wrong')
        }
    }

    const handleDivisionChange = (index, id) => {
        let dist = district;
        dist[index] = division.find(data => data.id == id)?.districts;
        setDistrict(() => [...dist]);
    }

    const handleDistrictChange = (index, id) => {
        let formUpazilla = upazilla;
        formUpazilla[index] = district[index]?.find(data => data.id == id)?.upazillas;
        setUpazilla(() => [...formUpazilla]);
    }

    useEffect(() => {
        getDivision();
        fetchAddressType();
        if (basicInfo?.data?.address?.length > 0) {
            setValue(`address`, basicInfo?.data?.address);
            basicInfo?.data?.address.map((info, index) => {
                handleDivisionChange(index, info.division);
                handleDistrictChange(index, info.district);
            });
        } else {
            setValue(`address`, defaultAddress);
        }
    }, [basicInfo?.data]);

    const onSubmit = (data, e) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/employee`, {
            basic_info: {...basicInfo?.data},
            address: [...data.address]
        }, axiosConfig()).then(response => {
            if (response.data?.message === 'Success') {
                toast.success('Saved Successfully');
                setErrors([]);
                const {data : {data: {address}}} = response;
                setValue(`address`, address);
            }
        })
            .catch(error => {
                if (error.response.status === 422) {
                    setErrors(error.response.data.errors);
                }
            });
    }

    const fetchAddressType = async () => {
        try {
            const [address] = await Promise.all([
                axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-address-type`, axiosConfig())
            ]);
            setAddress(address?.data?.data)
        } catch (error) {
            console.error(error);
        }
    };

    if (loading) {
        return <Loader/>
    }

    return (
        <div className='component_container py-2 my-3 py-md-5 my-md-5'>
            <ToastContainer/>
            <EmployeeAddressCom
                onSubmit={onSubmit}
                register={register}
                errors={errors}
                setErrors={setErrors}
                handleSubmit={handleSubmit}
                fields={fields}
                append={append}
                remove={remove}
                address={address}
                users={users}
                setUsers={setUsers}
                getValues={getValues}
                setValue={setValue}
                reset={reset}
                handleDivisionChange={handleDivisionChange}
                handleDistrictChange={handleDistrictChange}
                division={division}
                district={district}
                upazilla={upazilla}
                userName={basicInfo?.data?.name_in_bn}
                permissions={permissions}
            />
        </div>
    );
};

export default EmployeeAddressPage;