import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import './topNavbar.css'

const SecondaryTopNavbar = ({location, topnav, employeeId, handleCheckEmployeeId}) => {

    const [currentEmployeeId, setCurrentEmployeeId] = useState();
    const [isPersonalInfoNavShow, setPersonalInfoVisibility] = useState(false);

    useEffect(() => {
            setCurrentEmployeeId(employeeId || localStorage.getItem("currentUserId"))
    },[employeeId])

    useEffect(() => {
        const hasPersonalInfoRoute = location.pathname.match('employee-info')?.length;
        let isVisible = typeof hasPersonalInfoRoute !== 'undefined';
        setPersonalInfoVisibility(isVisible);
    }, [location.pathname]);

    const handleCurrentEmployeeId = () => {

        const currentEmployee = employeeId || localStorage.getItem("currentUserId");
        if(!currentEmployee) {
            window.location.replace('/employee-info');
        }
    }
    const handleEmployeeNavigation = () => {
        return employeeId || localStorage.getItem("currentUserId");
    }
    const togglePersonalInformation = () => {
        setPersonalInfoVisibility((prevState) => !prevState)
    }
    return (
        <div className='component_container d-flex justify-content-center flex-column align-items-center mt-3'
            // data-aos="fade-up" data-aos-duration="5000"
        >
                <div className="topnav" id="myTopnav" ref={topnav}>
                    <div className="row topnav" style={{borderBottom: "10px solid white"}}>
                        <div className="col-md-12 d-flex justify-content-center">
                            <NavLink
                                className={isPersonalInfoNavShow ? 'activeBtn' : "custom-tab"}
                                onClick={togglePersonalInformation}
                                to={`/employee-info-edit/${handleEmployeeNavigation()}`}>ব্যক্তিগত তথ্য
                            </NavLink>
                            <NavLink
                                className={({isActive, isPending}) => {
                                    return isActive ? "activeBtn" : "custom-tab";
                                }}
                                onClick={handleCurrentEmployeeId}
                                to={`/job-information/${handleEmployeeNavigation()}`}>চাকুরীর তথ্য
                            </NavLink>
                            <NavLink
                                className={({isActive, isPending}) => {
                                    return isActive ? "activeBtn" : "custom-tab";
                                }}
                                onClick={handleCurrentEmployeeId}
                                to={`/leave-management/${handleEmployeeNavigation()}`}>ছুটি সম্পর্কিত তথ্য
                            </NavLink>
                            <NavLink
                                className={({isActive, isPending}) => {
                                    return isActive ? "activeBtn" : "custom-tab";
                                }}
                                onClick={handleCurrentEmployeeId}
                                to={`/prize-info/${handleEmployeeNavigation()}`}> পুরস্কার / সনদপত্র
                            </NavLink>
                            <NavLink
                                className={({isActive, isPending}) => {
                                    return isActive ? "activeBtn" : "custom-tab";
                                }}
                                onClick={handleCurrentEmployeeId}
                                to={`/punishment/${handleEmployeeNavigation()}`}>শৃঙ্খলা সম্পর্কিত তথ্য
                            </NavLink>
                        </div>
                    </div>
                    {isPersonalInfoNavShow && <div className="row topnav">
                        <div className="col-md-12 text-center">
                            {currentEmployeeId ? (
                                <NavLink
                                    className={({isActive, isPending}) => {
                                        if (location.pathname === '/employee-info') {
                                            isActive = true;
                                        }
                                        return isActive ? "activeBtn" : "custom-tab";
                                    }}
                                    to={`/employee-info-edit/${handleEmployeeNavigation()}`}>
                                    কর্মচারী / কর্মকর্তা তথ্য
                                </NavLink>
                            ) : (
                                <NavLink
                                    className={({isActive, isPending}) => {
                                        if (location.pathname === '/employee-info') {
                                            isActive = true;
                                        }
                                        return isActive ? "activeBtn" : "custom-tab";
                                    }}
                                    to={`/employee-info`}>
                                    কর্মচারী / কর্মকর্তা তথ্য
                                </NavLink>
                            )}


                            <NavLink
                                className={({ isActive, isPending }) => {
                                    return isActive ? "activeBtn" : "custom-tab";
                                }}
                                onClick={handleCurrentEmployeeId}
                                to={`/employee-info/${handleEmployeeNavigation()}/address`}>ঠিকানা
                            </NavLink>

                            <NavLink
                                className={({ isActive, isPending }) => {
                                    return isActive ? "activeBtn" : "custom-tab";
                                }}
                                onClick={handleCurrentEmployeeId}
                                to={`/employee-info/${handleEmployeeNavigation()}/education-degree`}>প্রাতিষ্ঠানিক শিক্ষা
                            </NavLink>

                            <NavLink
                                className={({ isActive, isPending }) => {
                                    return isActive ? "activeBtn" : "custom-tab";
                                }}
                                onClick={handleCurrentEmployeeId}
                                to={`/employee-info/${handleEmployeeNavigation()}/spouse`}>স্বামী / স্ত্রী
                            </NavLink>

                            <NavLink
                                className={({ isActive, isPending }) => {
                                    return isActive ? "activeBtn" : "custom-tab";
                                }}
                                onClick={handleCurrentEmployeeId}
                                to={`/employee-info/${handleEmployeeNavigation()}/child`}>সন্তান
                            </NavLink>

                            <NavLink
                                className={({ isActive, isPending }) => {
                                    return isActive ? "activeBtn" : "custom-tab";
                                }}
                                onClick={handleCurrentEmployeeId}
                                to={`/employee-info/${handleEmployeeNavigation()}/transfer`}>বদলি
                            </NavLink>

                            <NavLink
                                className={({ isActive, isPending }) => {
                                    return isActive ? "activeBtn" : "custom-tab";
                                }}
                                onClick={handleCurrentEmployeeId}
                                to={`/employee-info/${handleEmployeeNavigation()}/travell`}>ভ্রমণ
                            </NavLink>

                            <NavLink
                                className={({ isActive, isPending }) => {
                                    return isActive ? "activeBtn" : "custom-tab";
                                }}
                                onClick={handleCurrentEmployeeId}
                                to={`/employee-info/${handleEmployeeNavigation()}/language-efficiency`}>ভাষাগত দক্ষতা
                            </NavLink>

                            <NavLink
                                className={({ isActive, isPending }) => {
                                    return isActive ? "activeBtn" : "custom-tab";
                                }}
                                onClick={handleCurrentEmployeeId}
                                to={`/employee-info/${handleEmployeeNavigation()}/trainings`}>প্রশিক্ষণ
                            </NavLink>

                            <NavLink
                                className={({ isActive, isPending }) => {
                                    return isActive ? "activeBtn" : "custom-tab";
                                }}
                                onClick={handleCurrentEmployeeId}
                                to={`/employee-info/${handleEmployeeNavigation()}/extra-curricular`}>এক্সট্রা-কারিকুলার
                            </NavLink>

                            <NavLink
                                className={({ isActive, isPending }) => {
                                    return isActive ? "activeBtn" : "custom-tab";
                                }}
                                onClick={handleCurrentEmployeeId}
                                to={`/employee-info/${handleEmployeeNavigation()}/research`}>প্রকাশনা
                            </NavLink>
                        </div>
                    </div> }
                </div>
        </div>
    );
};

export default SecondaryTopNavbar;