import React, {useCallback, useEffect, useState} from "react";
import {useFieldArray, useForm} from "react-hook-form";
import {axiosConfig, isAdmin, payloadAsFormData, url} from "../../helpers/helpers";
import axios from "axios";
import {toast} from "react-toastify";
import NestedFormIncDec from "../EmployeeCommonSection/ NestedFormIncDec";
import FileUpload from "../Utils/FileUpload";
import FourColumn from "../EmployeeCommonSection/FourColumn";
import FieldErrorMessage from "../Utils/FieldErrorMessage";
import {compareArraysAsSet} from "@testing-library/jest-dom/dist/utils";
import FormSubmitBtn from "../EmployeeCommonSection/FormSubmitBtn";
import Select2Wrapper from "../Utils/Select2Wrapper";
import EmployeeHeader from "../EmployeeCommonSection/EmployeeHeader";

const JoiningInformation = ({userInfo, userId, employeeId, deleteAttachment, permissions}) => {
    const [initialValues] = useState([{
        employee_id: "",
        designation: "",
        ministry_id: "",
        joining_date: "",
        order_no: "",
        order_date: "",
        last_working_date: "",
        grade: "",
        salary_scale: "",
        attachments: "",
    }]);

    const [errors, setErrors] = useState([]);
    const [designation, setDesignation] = useState([]);
    const [institution, setInstitution] = useState([]);
    const [organization, setOrganization] = useState([]);
    const [ministryOrganizationAndInstitution, setMinistryOrganizationAndInstitution] = useState([]);

    const {control, register, setValue, getValues, handleSubmit} = useForm({
        defaultValues: {
            joiningInformation: initialValues
        }
    });

    const {fields, append, remove} = useFieldArray({
        control, name: "joiningInformation"
    });

    useEffect(() => {
        if (userInfo?.hasOwnProperty('joining_infos') && userInfo.joining_infos.length > 0) {
            setValue('joiningInformation', userInfo.joining_infos);
            userInfo.joining_infos.map((info, index) => {
                handleMinistryChange(index, info.ministry_id);
                handleOrganizationChange(index, info.organization_id);
            });
        } else {
            setValue('joiningInformation', initialValues);
        }
    }, [userInfo]);

    useEffect(() => {
        getMinistryOrganizationAndInstitution();
        getDesignation();
    }, []);

    const getDesignation = () => {
        try {
            axios.get(url(`fetch-designation`), axiosConfig())
                .then(({data: {data}}) => {
                    setDesignation(data)
                })
                .catch((error) => {
                    console.log(errors);
                })
        } catch (e) {
            toast.error('something went wrong')
        }
    }

    const getMinistryOrganizationAndInstitution = () => {
        try {
            axios.get(url(`fetch-ministry-organization-and-institution`), axiosConfig())
                .then(({data: {data}}) => {
                    setMinistryOrganizationAndInstitution(data);
                })
                .catch((error) => {
                    console.log(errors);
                })
        } catch (e) {
            toast.error('something went wrong')
        }
    }

    const onSubmit = (data) => {
        let formData = new FormData();

        if (!employeeId) {
            toast.warning('Select Employee First!');
            return;
        }

        for (let topIndex = 0; topIndex < data.joiningInformation.length; topIndex++) {
            let payload = data.joiningInformation[topIndex];
            payload['employee_id'] = employeeId;
            formData = payloadAsFormData(formData, `joining_info[${topIndex}]`, payload)
        }

        axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/joining-info`, formData, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("accessToken")}`, "Content-Type": "multipart/form-data"
            }
        }).then(response => {
            if (response.data?.message === 'Success') {
                toast.success('Saved successful.');
            }
        }).catch(error => {
            if (error.response.status === 422) {
                setErrors(error.response.data.errors);
            }
        });
    }

    const handleMinistryChange = (index, id) => {
        let org = organization;
        org[index] = ministryOrganizationAndInstitution.filter(data => data.parent_id == id && data.type === 'organization');
        setOrganization(() => [...org]);
    }
    const handleOrganizationChange = (index, id) => {
        let institute = institution;
        institute[index] = ministryOrganizationAndInstitution.filter(data => data.parent_id == id && data.type === 'institution');
        setInstitution(() => [...institute]);
    }

    return (<>
        <div className="employee_info">
            <div className='card shadow border-0 mt-2' >
                <div className="card-header">
                    <EmployeeHeader e_title="যোগদানের তথ্য" userName={userInfo?.name_in_bn} e_num="2200443689"/>
                </div>
                <div className='card-body'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {fields.map((field, index) => {
                            let attachments = field?.attachments;
                            return (
                                <div key={field.id}>
                                    <input type="hidden"
                                        className="form-control"
                                        {...register(`joiningInformation.${index}.employee_id`)}
                                    />
                                    <NestedFormIncDec fields={fields} append={append} remove={remove} index={index}/>
                                    <div className='row'>
                                        <div className="col-3">
                                            <label className="form-label">পদবি <span
                                                className='text-danger'>*</span></label>
                                            <Select2Wrapper
                                                placeholder="পদবি নির্বাচন করুন"
                                                value={getValues(`joiningInformation.${index}.designation`)}
                                                onChange={(value) => setValue(`joiningInformation.${index}.designation`, value)}
                                                options={designation}
                                            />
                                            <FieldErrorMessage errors={errors} name="designation" prefix="joining_info"
                                                            index={index}/>
                                        </div>
                                        <div className="col-3">
                                            <label className="form-label">মন্ত্রণালয় নাম </label>
                                            <select
                                                className="form-select"
                                                {...register(`joiningInformation.${index}.ministry_id`)}
                                                onChange={(e) => handleMinistryChange(index, e.target.value)}
                                            >
                                                <option value="" style={{color: 'gray'}}>মন্ত্রণালয় নির্বাচন করুন</option>
                                                {ministryOrganizationAndInstitution && ministryOrganizationAndInstitution.map(ministry => {
                                                    return ministry.parent_id === null ? (
                                                        <option key={ministry.id} value={ministry.id}>
                                                            {ministry.name}
                                                        </option>) : '';
                                                })}
                                            </select>
                                            <FieldErrorMessage errors={errors} name="ministry_id" prefix="joining_info"
                                                            index={index}/>
                                        </div>
                                        <div className="col-3">
                                            <label className="form-label">দপ্তর /সংস্থা নাম </label>
                                            <select
                                                className="form-select"
                                                {...register(`joiningInformation.${index}.organization_id`)}
                                                onChange={(e) => handleOrganizationChange(index, e.target.value)}
                                            >
                                                <option value="" style={{color: 'gray'}}>দপ্তর /সংস্থা নির্বাচন করুন</option>
                                                {organization.hasOwnProperty(index) && organization[index].map(org => {
                                                    return (<option key={org.id} value={org.id}>
                                                        {org.name}
                                                    </option>);
                                                })}
                                            </select>
                                            <FieldErrorMessage errors={errors} name="ministry_id" prefix="joining_info"
                                                            index={index}/>
                                        </div>
                                        <div className="col-3">
                                            <label className="form-label">প্রতিষ্ঠানের নাম </label>
                                            <Select2Wrapper
                                                placeholder="প্রতিষ্ঠান নির্বাচন করুন"
                                                value={getValues(`joiningInformation.${index}.institution_id`)}
                                                onChange={(value) => setValue(`joiningInformation.${index}.institution_id`, value)}
                                                options={institution.hasOwnProperty(index) ? institution[index] : []}
                                            />
                                            <FieldErrorMessage errors={errors} name="institution_id" prefix="joining_info"
                                                            index={index}/>
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className="col-3">
                                            <label className="form-label">যোগদানের তারিখ </label>
                                            <input type="date" className="form-control"
                                                {...register(`joiningInformation.${index}.joining_date`)}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <label className="form-label">আদেশ নম্বর <span
                                                className='text-danger'>*</span></label>
                                            <input type="text"
                                                className="form-control"
                                                {...register(`joiningInformation.${index}.order_no`)}
                                            />
                                            <FieldErrorMessage errors={errors} name="order_no" prefix="joining_info"
                                                            index={index}/>
                                        </div>
                                        <div className="col-3">
                                            <label className="form-label">আদেশের তারিখ </label>
                                            <input type="date" className="form-control"
                                                {...register(`joiningInformation.${index}.order_date`)}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <label className="form-label">শেষ কর্মদিবসের তারিখ </label>
                                            <input type="date" className="form-control"
                                                {...register(`joiningInformation.${index}.last_working_date`)}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className="col-3">
                                            <label className="form-label">প্রাপ্ত গ্রেড</label>
                                            <input type="text"
                                                className="form-control"
                                                {...register(`joiningInformation.${index}.grade`)}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <label className="form-label">বেতন স্কেল</label>
                                            <input type="text"
                                                className="form-control"
                                                {...register(`joiningInformation.${index}.salary_scale`)}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <label className="form-label">প্রমাণক</label>
                                            <FileUpload
                                                title="প্রমাণক সংযুক্ত করুন"
                                                register={register}
                                                registerkey={`joiningInformation.${index}.attachments`}
                                                attachments={attachments}
                                                deleteAttachment={deleteAttachment}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        {((isAdmin() || permissions?.includes('edit'))) &&  <FormSubmitBtn/> }
                    </form>
                </div>
            </div>
        </div>
    </>);
}

export default JoiningInformation;