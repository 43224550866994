import React, {useEffect, useState} from "react";
import FieldErrorMessage from "../../Utils/FieldErrorMessage";
import {MdDeleteOutline} from "react-icons/md";
import {FaRegEdit} from "react-icons/fa";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import axios from "axios";
import {axiosConfig, isAdmin, translateToBanglaDigit, url} from "../../../helpers/helpers";
import {toast} from "react-toastify";

const Organization = (
    {
        handleSubmit,
        onSubmit,
        errors,
        register,
        setValue,
        data,
        parents,
        handleDelete,
        handleEdit,
        setData,
        permissions
    }) => {

    const onDragEnd = e => {
        if (!e.destination) {
            return;
        }
        const sorted = reorder(data, e.source.index, e.destination.index);
        setData(sorted)
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const handleSort = () => {
        axios.post(url(`organization-sort`), data, axiosConfig())
            .then((response) => {
                toast.success('Sorted Successful');
            })
            .catch(error => {
                console.log(error);
            });
    }

    const totalEmployeeData = (organizations=[], property=null) => {
        return organizations.reduce((total, org) => total + (parseInt(org[property]) > 0 ? parseInt(org[property]) : 0) , 0);
    }

    useEffect(() => {
        setValue('type', 'organization');
    }, [])

    return (
        <>
            <div className='card shadow border-0 mt-2'>
                <div className='card-body'>
                    <div className="row">
                        <div className="col-4">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-12">
                                        <label htmlFor="phone" className="form-label">মন্ত্রণালয় <span
                                            className='text-danger'>*</span></label>
                                        <select className="form-select"
                                                {...register('parent_id')}>
                                            <option value="" style={{color: 'gray'}}>নির্বাচন করুন</option>
                                            {
                                                parents && parents.map(parent => {
                                                    return (
                                                        <option key={parent.id} value={parent.id}>{parent.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <FieldErrorMessage errors={errors} name='parent_id'/>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <label htmlFor="phone" className="form-label">নাম <span
                                            className='text-danger'>*</span></label>
                                        <input type="text" className="form-control"
                                               {...register("name")}/>
                                        <FieldErrorMessage errors={errors} name='name'/>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <label htmlFor="phone" className="form-label">অর্ডার</label>
                                        <select className="form-select"
                                                aria-label="Default select example" {...register('order')}>
                                            <option value="">অর্ডার নির্বাচন করুন</option>
                                            <option value="A">A</option>
                                            <option value="B">B</option>
                                            <option value="C">C</option>
                                            <option value="D">D</option>
                                            <option value="E">E</option>
                                            <option value="F">F</option>
                                            <option value="G">G</option>
                                            <option value="H">H</option>
                                            <option value="I">I</option>
                                            <option value="J">J</option>
                                            <option value="K">K</option>
                                            <option value="L">L</option>
                                            <option value="M">M</option>
                                            <option value="N">N</option>
                                            <option value="O">O</option>
                                            <option value="P">P</option>
                                            <option value="Q">Q</option>
                                            <option value="R">R</option>
                                            <option value="S">S</option>
                                            <option value="T">T</option>
                                            <option value="U">U</option>
                                            <option value="V">V</option>
                                            <option value="W">W</option>
                                            <option value="X">X</option>
                                            <option value="Y">Y</option>
                                            <option value="Z">Z</option>
                                        </select>
                                    </div>
                                </div>
                                <br/>
                                <button className="btn btn-sm btn-success">সংরক্ষণ করুন</button>
                            </form>
                        </div>
                        <div className="col-8">
                            <div className='table-responsive pt-4'>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="Table">
                                        {provided => (
                                            <table
                                                className="table table-bordered table-hover" {...provided.droppableProps}
                                                ref={provided.innerRef}>
                                                <thead>
                                                <tr style={{backgroundColor: '#f9f9f9'}}>
                                                    <th scope="col" style={{textAlign: 'center'}}>ক্রমিক নং</th>
                                                    <th scope="col">নাম</th>
                                                    <th scope="col">মন্ত্রণালয়</th>
                                                    <th className="text-center" scope="col">এন্ট্রিকৃত ডাটা</th>
                                                    <th className="text-center" scope="col">অর্ডার</th>
                                                    <th scope="col">
                                                        অ্যাকশন
                                                        <button className="btn btn-sm btn-primary float-end"
                                                                onClick={handleSort}>
                                                            Sort
                                                        </button>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    data && data.map((ministry, index) => {
                                                        return (
                                                            <Draggable
                                                                key={String(ministry.id)}
                                                                draggableId={String(ministry.id)}
                                                                index={index}>
                                                                {(provided) => (
                                                                    <tr ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <td style={{textAlign: 'center'}}>{translateToBanglaDigit(index + 1)}</td>
                                                                        <td>{ministry.name}</td>
                                                                        <td>{ministry.parent?.name}</td>
                                                                        <td className="text-center">{translateToBanglaDigit(ministry?.org_employees_count)}</td>
                                                                        <td className="text-center">{ministry.order}</td>
                                                                        <td>
                                                                            <div>
                                                                                {
                                                                                    (isAdmin() || permissions?.includes('edit')) &&
                                                                                    <button className="btn btn-sm btn-secondary"
                                                                                            onClick={() => handleEdit(ministry)}>
                                                                                        <FaRegEdit/>
                                                                                    </button>
                                                                                }
                                                                                {/* &nbsp;
                                                                                {
                                                                                    (isAdmin() || permissions?.includes('delete')) &&
                                                                                    <button className="btn btn-sm btn-secondary"
                                                                                            onClick={() => handleDelete(ministry.id)}>
                                                                                        <MdDeleteOutline/>
                                                                                    </button>
                                                                                } */}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    })
                                                }
                                                {provided.placeholder}
                                                <tr>
                                                    <td style={ {textAlign:'right'}} colSpan={3}>মোট</td>
                                                    <td style={{textAlign: 'center'}}>{ translateToBanglaDigit(totalEmployeeData(data, 'org_employees_count')) }</td>
                                                    <td></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Organization;