import axios from 'axios';
import React, {useState} from 'react';
import SignUp from '../../Components/Login/SignUp';
import {useNavigate} from "react-router-dom";
import { toast } from 'react-toastify';

const SignUpPage = () => {
    const navigate = useNavigate()
    const [errors, setErrors] = useState([]);

    const onSubmit = (data) => {
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/register`, data)
            .then(({data}) => {
                if (data?.message === 'Success') {
                    window.unique_id = data.data.unique_id;
                    toast.success('User registration successfully.');
                    navigate('/login');
                }
            })
            .catch(error => {
                if (error.response.status === 422) {
                    setErrors(error.response.data.errors);
                }
            });
    }

    return (
        <>
            <SignUp onSubmit={onSubmit} errors={errors}/>
        </>
    );
};

export default SignUpPage;