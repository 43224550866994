import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import {toast, ToastContainer} from 'react-toastify';
import JoiningInformation from "./JoiningInformation";
import {authToken, authUser, url} from "../../helpers/helpers";
import PromotionInformation from "./PromotionInformation";
import Select2Wrapper from "../Utils/Select2Wrapper";
import CurrentJobInformation from "./CurrentJobInformation";
import {useParams} from "react-router";
import useEmployeeInfo from "../../Hooks/useEmployeeInfo";
import Loader from '../Loader/Loader';

const JobInformation = ({permissions}) => {
    const {id} = useParams()
    const {basicInfo: {data}, loading, error} = useEmployeeInfo(id);
    const navigate = useNavigate();
    const [userId, setUserId] = useState('');
    const [designation, setDesignation] = useState([]);
    const [employeeId, setEmployeeId] = useState();
    const [users, setUsers] = useState();
    const [userInfo, setUserInfo] = useState();
    const [loadingUser, setLoadingUser] = useState(true);

    const axiosHeader = {
        headers: {
            authorization: `Bearer ${authToken()}`,
        }
    }

    useEffect(() => {
        if (!data || typeof data === 'undefined') return;
        setUserId(data?.user_id);
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-user-info/${data?.user_id}`, axiosHeader)
                .then(({data: {data}}) => {
                    setLoadingUser(false)
                    setUserInfo(data);
                    if (data) {
                        setEmployeeId(data.id);
                    } else {
                        setUserId('');
                        toast.warning('Employee not found. Please create employee first.')
                    }
                })
                .catch((error) => {
                    setLoadingUser(false)
                    console.log(error)
                })
    }, [data]);

    useEffect(() => {
        if (!authToken()) {
            navigate('/login')
        }
    }, [navigate]);

    const deleteAttachment = async (id) => {
        try {
            const {data} = await axios.delete(url(`attachment/${id}`), axiosHeader);
            if (data) {
                toast.success('Attachment deleted successful.')
                window.location.reload()
            }
        } catch (e) {
            toast.error('something went wrong')
        }
    }
     
    
    return (
        <div>
            <div style={{display: (loading || loadingUser) ? "block" : "none"}}>
                <Loader/>
            </div>
            <ToastContainer/>
            <div style={{display: (loading || loadingUser) ? "none" : "block"}} className="component_container py-2 my-3">
                <div className="row">
                    <div className="col-12">
                        <ul className="nav nav-pills mb-2" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="current-job-info-tab" data-bs-toggle="pill"
                                        data-bs-target="#current-job-info" type="button" role="tab"
                                        aria-controls="current-job-info" aria-selected="true"
                                        >বর্তমান চাকুরির তথ্য
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="btn btn-sm nav-link" id="joining-info-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#joining-info" type="button" role="tab"
                                        aria-controls="joining-info"
                                        aria-selected="false"
                                        > যোগদানের তথ্য
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="promotion-info-tab" data-bs-toggle="pill"
                                        data-bs-target="#promotion-info" type="button" role="tab"
                                        aria-controls="promotion-info" aria-selected="false"
                                        >পদোন্নতি
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-8">
                    </div>
                    <div className="col-4">
                        {/*<Select2Wrapper*/}
                        {/*    value={userId}*/}
                        {/*    onChange={(value) => setUserId(value)}*/}
                        {/*    options={users}*/}
                        {/*/>*/}
                        {/*<select className="form-control" onChange={(e) => setUserId(e.target.value)}>*/}
                        {/*    <option value="" style={{color: 'gray'}}>নির্বাচন করুন</option>*/}
                        {/*    {*/}
                        {/*        users?.map(item => <option key={item.id} value={item.id}>{item.text}</option>)*/}
                        {/*    }*/}
                        {/*</select>*/}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="current-job-info" role="tabpanel"
                                aria-labelledby="current-job-info-tab">
                                <CurrentJobInformation
                                    userInfo={userInfo}
                                    userId={userId}
                                    employeeId={employeeId}
                                    deleteAttachment={deleteAttachment}
                                    permissions={permissions}
                                />
                            </div>
                            <div className="tab-pane fade" id="joining-info" role="tabpanel"
                                aria-labelledby="joining-info-tab">
                                <JoiningInformation
                                    userInfo={userInfo}
                                    userId={userId}
                                    employeeId={employeeId}
                                    deleteAttachment={deleteAttachment}
                                    permissions={permissions}
                                />
                            </div>
                            <div className="tab-pane fade" id="promotion-info" role="tabpanel"
                                aria-labelledby="promotion-info-tab">
                                <PromotionInformation
                                    userInfo={userInfo}
                                    userId={userId}
                                    employeeId={employeeId}
                                    deleteAttachment={deleteAttachment}
                                    permissions={permissions}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>   
    );
};

export default JobInformation;