import React, { useEffect, useState } from 'react';
import avatar from "../../Assets/avatar.png"
import { FaList } from 'react-icons/fa';
import {asset, authUser} from "../../helpers/helpers";
import Institution from '../Settings/MinistryOrganizationAndInstitution/Institution';
import { Tooltip } from 'react-bootstrap';
import {useLocation} from "react-router-dom";
import axios from 'axios';

  
const PrimaryTopNavbar = ({pathTitle, menuIconClick}) => {

    const [completePercentage, setCompletePercentage] = useState(0);


    const onError = ({ currentTarget }) => {
        currentTarget.onError = null;
        currentTarget.src= avatar
    }
    useEffect(()=>{
        if(document.body.clientWidth <= 767.98){
            document.getElementById('closeMenuBtn').style.display = 'none';
        }
    },[]);
    
    let employeeId = null;
    const location = useLocation()
    const myArray = location.pathname.split("/");
    if (myArray[1] == "employee-info-edit")  {
        employeeId = myArray[2];
    }

    useEffect(() => {
        if (!employeeId) {
            setCompletePercentage(null) 
        }
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-percentage/${employeeId}`,{
                headers: {
                    authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                }
            }).then(({data: {data}}) => {
                if (data[0]?.total_percentage) {
                    setCompletePercentage(data[0]?.total_percentage);
                }
            }).catch((error) => {
                console.log(error)
            })
    }, [employeeId]);




    return (
        <nav className="navbar navbar-expand shadow">
            <div className="container-fluid p-0">
                {/* <div className="navbar-brand text-center"><FaList/></div> */}
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="component_container py-2 collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-lg-0">
                        <li className="nav-item text-center d-flex align-items-center">
                            {/* <img height='40' className='me-2' src={patron} /> */}
                            <div className="me-3" style={{cursor: 'pointer'}} id='closeMenuBtn' onClick={menuIconClick}>
                                <FaList />
                            </div>
                            <h4 className='m-0 fw-bold' style={{color: '#683091'}}>{pathTitle} {completePercentage && <small style={{fontSize: '14px'}}>(সম্পূর্ণ প্রোফাইল সম্পাদনা হয়েছে {completePercentage}%)</small> }  </h4>
                            <Tooltip></Tooltip>
                        </li>
                    </ul>
                    <div className="d-flex justify-content-between justify-content-md-around align-items-center">
                        <img onError={onError} className='img-fluid mx-3 bd_logo' src={authUser()?.image ? asset(authUser().image) : avatar} alt=""/>
                        <p className='text-success fw-bold m-0 d-none d-sm-block'>
                            <span> { authUser()?.name }</span>
                            { authUser()?.designation_name && <span><br/> { authUser()?.designation_name }</span> }
                            { authUser()?.organization_name && <span><br/> { authUser()?.organization_name }</span> }, 
                            { authUser()?.institute_name && <span className='p-2'>{ authUser()?.institute_name }</span> }
                        </p>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default PrimaryTopNavbar;

