import {useState, useEffect} from 'react';
import axios from 'axios';

const useEmployeeById = (id) => {
    const [basicInfo, setBasicInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const config = {headers: {authorization: `Bearer ${localStorage.getItem("accessToken")}`}};
        const url = `${process.env.REACT_APP_BASE_URL}/api/v1/employee/${id}`;

        if (id) {
            axios.get(url, config)
                .then(response => {
                    setBasicInfo(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    setError(error);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [id]);

    return {basicInfo, loading, error, setLoading};
};

export default useEmployeeById;