import React, {Fragment} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import {AiOutlineDelete} from 'react-icons/ai';
import {GrAttachment} from 'react-icons/gr';

const FileUpload = ({title, register, attachments, deleteAttachment, registerkey}) => {
    const [modalShow, setModalShow] = React.useState(false);

    let modalProps = {show: modalShow}
    const closeModal = () => {
        setModalShow(false);
    }
    const reset = () => {
        document.getElementById("fileAttachment").value = null;
        setModalShow(false);
    }

    return (
        <>
            <Button className=" w-100" variant="outline-secondary" onClick={() => setModalShow(true)}>
                <GrAttachment/> &nbsp; প্রমাণক সংযুক্ত করুন
            </Button>

            <Modal
                {...modalProps}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <GrAttachment/> &nbsp; {title ? title : 'Attachments'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label htmlFor="fileAttachment" className="drop-container">
                        <span className="drop-title">Select files here</span>
                        or
                        <input type="file"
                               id="fileAttachment"
                               {...register(registerkey ? registerkey : 'attachments')}
                               multiple
                        />
                    </label>
                    <small className="text-danger text-center">Max file upload size 1MB</small>
                    <br/>

                    <Table bordered>
                        <thead>
                        <tr>
                            <th>SL</th>
                            <th>Name</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Array.isArray(attachments)
                            ? attachments?.map((attachment, i) => {
                                return <Fragment key={i}>
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{attachment.name}</td>
                                        <td>
                                            {attachment.hasOwnProperty('id') &&
                                                <div className="text-center">
                                                    <button className="btn btn-sm btn-danger"
                                                            onClick={() => deleteAttachment(attachment.id)}>
                                                        <AiOutlineDelete/>
                                                    </button>
                                                    {/* &nbsp;
                                                <a className="btn btn-sm btn-primary" target="_blank"
                                                   href={asset(attachment.path)}>
                                                    <BsDownload/>
                                                </a> */}
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                </Fragment>
                            })
                            : null
                        }
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"danger"} onClick={reset}>বাতিল করুন</Button>
                    <Button onClick={closeModal}>সংযুক্ত করুন</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default FileUpload;