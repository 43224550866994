import React from 'react';
import {useFieldArray, useForm} from 'react-hook-form';
import NestedFormIncDec from '../EmployeeCommonSection/ NestedFormIncDec';
import EmployeeInfoHeader from '../EmployeeCommonSection/EmployeeInfoHeader';
import FormSubmitBtn from '../EmployeeCommonSection/FormSubmitBtn';
import FourColumn from '../EmployeeCommonSection/FourColumn';
import EmployeeHeader from '../EmployeeCommonSection/EmployeeHeader';
import { isAdmin } from '../../helpers/helpers';

const Punishment = (
    {
        onSubmit,
        register,
        handleSubmit,
        errors,
        fields,
        append,
        remove,
        punishmentType,
        setUsers,
        users,
        setUserId,
        userId,
        permissions,
        userName
    }) => {

    return (
        <div className='employee_info'>
            <div className='card shadow border-0 mt-2' >
                <div className='card-header'>
                    <EmployeeHeader register={register} setUsers={setUsers} users={users} setUserId={setUserId}
                                        userId={userId}
                                        errors={errors} e_title="শৃঙ্খলা সম্পর্কিত তথ্য" userName={userName}/>
                </div>
                <div className='card-body'>
                    <form className='pt-4' onSubmit={handleSubmit(onSubmit)}>
                        {fields.map((item, index) => {
                            return (
                                <div key={item.id}>
                                    <NestedFormIncDec fields={fields} append={append} remove={remove} index={index}/>

                                    <div className='row'>
                                        <div className="col-xs-auto col-md mb-3">
                                            <select className="form-select"
                                                    aria-label="Default select example" {...register(`punishment.${index}.type`, {required: true})}>
                                                <option value="" style={{color: 'gray'}}>নির্বাচন করুন</option>
                                                {
                                                    punishmentType?.map(child => <option key={child.id}
                                                                                            value={child.id}>{child.bn}</option>)
                                                }
                                            </select>
                                            {errors?.punishment?.[index]?.type &&
                                                <h6 className='text-danger h6'>This field is required.</h6>}
                                        </div>
                                        <FourColumn/>
                                    </div>

                                    <div className='row'>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">দন্ডের কারণ <span
                                                className='text-danger'>*</span></label>
                                            <input className="form-control" placeholder=""
                                                    {...register(`punishment.${index}.reason`, {required: true})}
                                            />
                                            {errors?.punishment?.[index]?.reason &&
                                                <h6 className='text-danger h6'>This field is required.</h6>}
                                        </div>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">দন্ডের বিবরণ </label>
                                            <input className="form-control" placeholder=""
                                                    {...register(`punishment.${index}.description`)}
                                            />
                                        </div>
                                        <div className="col-xs-auto col-md"></div>
                                        <div className="col-xs-auto col-md"></div>
                                        <div className="col-xs-auto col-md"></div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">দন্ডের সময়কাল <span
                                                className='text-danger'>*</span></label>
                                            <input className="form-control" placeholder=""
                                                    {...register(`punishment.${index}.duration`, {required: true})}
                                            />
                                            {errors?.punishment?.[index]?.duration &&
                                                <h6 className='text-danger h6'>This field is required.</h6>}
                                        </div>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">দন্ডের শুরুর তারিখ <span
                                                className='text-danger'>*</span></label>
                                            <input className="form-control" type="date"
                                                    {...register(`punishment.${index}.start_date`, {required: true})}
                                            />
                                            {errors?.punishment?.[index]?.start_date &&
                                                <h6 className='text-danger h6'>This field is required.</h6>}
                                        </div>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">দন্ডের শেষের তারিখ <span
                                                className='text-danger'>*</span></label>
                                            <input className="form-control" type="date"
                                                    {...register(`punishment.${index}.end_date`, {required: true})}
                                            />
                                            {errors?.punishment?.[index]?.end_date &&
                                                <h6 className='text-danger h6'>This field is required.</h6>}
                                        </div>
                                        <div className="col-xs-auto col-md">
                                            <label htmlFor="inputAddress" className="form-label">মন্তব্য</label>
                                            <input className="form-control" placeholder=""
                                                    {...register(`punishment.${index}.remarks`)}
                                            />
                                        </div>
                                        <div className="col-xs-auto col-md"></div>
                                    </div>
                                </div>
                            );
                        })}
                        {((isAdmin() || permissions?.includes('edit'))) &&  <FormSubmitBtn/> }
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Punishment;