import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import EmployeeLanguageEfficiency from '../../Components/EmployeeInfo/EmployeeLanguageEfficiency';
import useEmployeeInfo from '../../Hooks/useEmployeeInfo';
import axios from 'axios';
import Loader from '../../Components/Loader/Loader';
import { payloadAsFormData, url } from '../../helpers/helpers';
import { ToastContainer, toast } from 'react-toastify';

const EmployeeLanguageEfficiencyPage = ({permissions}) => {
    const {id} = useParams();
    const {basicInfo, loading, error} = useEmployeeInfo(id);
    const [languageProficiency, setLanguageProficiency] = useState([])

    const { register, control, handleSubmit, setValue, formState: {errors}, reset } = useForm({
        defaultValues: {
            language: [
                {
                    language_name: "",
                    expertness: "",
                    attachments: ""
                }
            ]
        }
    });

    const {fields, append, remove} = useFieldArray({
        control,
        name: "language"
    });

    useEffect(() => {
        if (basicInfo?.data?.language_expertness_infos.length > 0) {
            setValue(`language`, basicInfo?.data?.language_expertness_infos);
        } else {
            setValue(`language`, [
                {
                    language_name: "",
                    expertness: "",
                    attachments: ""
                }
            ]);
        }
    }, [basicInfo?.data, languageProficiency]);


    const onSubmit = (data) => {
        let formData = new FormData();
        formData = payloadAsFormData(formData, 'basic_info', basicInfo?.data)

        for (let topIndex = 0; topIndex < data.language.length; topIndex++ ) {
            let payload = data.language[topIndex];
            formData = payloadAsFormData(formData, `language_expertness_info[${topIndex}]`, payload)
        }

        axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/employee`, formData, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                "Content-Type": "multipart/form-data"
            }
        }).then(response => {
                if (response.data?.message === 'Success') {
                    toast.success('Saved successful.');
                }
            })
            .catch(error => {
                console.error(error);
        });
    }

    const deleteAttachment = async (id) => {
        try {
            const accessToken = localStorage.getItem("accessToken");
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const {data} = await axios.delete(url(`attachment/${id}`), {headers});
            if (data) {
                console.log(data.data)
                toast.success('Attachment deleted successful.')
            }
        } catch (e) {
            toast.error('something went wrong')
        }
    }

    const fetchLanguageProficiency = async () => {
        try {
          const accessToken = localStorage.getItem("accessToken");
          const headers = {
            Authorization: `Bearer ${accessToken}`,
          };

          const [languageProficiency] = await Promise.all([
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-skill-type`, {headers})
          ]);
          setLanguageProficiency(languageProficiency?.data?.data);
        } catch (error) {
          console.error(error);
        }
      };

    useEffect(()=>{
        fetchLanguageProficiency();
    },[])

    if(loading){
        return <Loader />
    }

    return (
        <div className='component_container py-2 my-3 py-md-5 my-md-5'>
            <ToastContainer />
            <EmployeeLanguageEfficiency
                onSubmit={onSubmit}
                register={register}
                handleSubmit={handleSubmit}
                errors={errors}
                fields={fields}
                append={append}
                remove={remove}
                languageProficiency={languageProficiency}
                deleteAttachment={deleteAttachment}
                reset={reset}
                permissions={permissions}
                userName={basicInfo?.data?.name_in_bn}
            />
        </div>
    );
};

export default EmployeeLanguageEfficiencyPage;