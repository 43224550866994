import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import $ from 'jquery';
import {toast} from "react-toastify";

const FormSubmitBtn = ({reset, setErrors}) => {
    const location = useLocation();
    const [employeeId, setEmployeeId] = useState();
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        const employeeId = location.pathname.split('/')[2];
        if (!employeeId) {
            return;
        }
        setEmployeeId(employeeId);
    }, [location]);

    const handleCancelEdit = () => {
        window.location.href = '/';
    }

    const handleReset = () => {
        reset();
        setErrors && setErrors([]);
    }

    const handleEditable = () => {
        const formElement = $('.employee_info');
        if (formElement.css('pointer-events') === 'auto') {
            formElement.css('pointer-events', 'none');
            setEditMode(false);
        } else {
            formElement.css('pointer-events', 'auto');
            setEditMode(true);
        }
        toast.success('Edit mode changed.')
    }

    return (
        <div className="row mb-5 mt-4">
            <div className="col-12 d-grid gap-2 d-md-flex justify-content-md-end text-start">
                <button className="px-5 btn btn-primary" type="button"
                        onClick={handleEditable}>
                    পরিবর্তন
                    {/*{editMode ? 'disable' : 'enable'}*/}
                </button>
                {
                    employeeId && <button className="px-5 btn btn-outline-danger me-md-2"
                                    type="button"
                                    onClick={handleReset}>
                            বাতিল করুন
                        </button>
                }

                <button className="px-5 btn btn-success" type="submit">সংরক্ষণ করুন</button>
            </div>
        </div>
    );
};

export default FormSubmitBtn;