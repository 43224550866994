import React from 'react';
import govtLogo from '../../Assets/bdLogo.png'

const LoginSignupLogo = () => {
    return (
        <div className="col-12 col-md-6 login_left">
            <img className='w-25 mb-4' src={govtLogo} alt=""/>
            <h3>শিল্প মন্ত্রণালয়</h3>
            <h6>গণপ্রজাতন্ত্রী বাংলাদেশ সরকার</h6>
        </div>
    );
};

export default LoginSignupLogo;