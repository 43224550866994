import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Login from '../../Components/Login/Login';
import {toast, ToastContainer} from "react-toastify";

const LoginPage = () => {
    const navigate = useNavigate();
    const [errors, setErrors] = useState([]);

    useEffect(function () {
        const searchParams = new URLSearchParams(document.location.search)
        if (searchParams.get('register') === 'now') {
            navigate('/login');
            toast.success('Register Successfully');
        }
    }, [navigate])

    const onSubmit = (data) => {
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/login`, data)
            .then(({data:{data}}) => {
                if (data?.token) {
                    localStorage.setItem('accessToken', data?.token);
                    localStorage.setItem('user', JSON.stringify(data));
                    localStorage.setItem('currentUserId', data?.employee_id);
                    localStorage.setItem('editId', data?.employee_id);
                    navigate('/', {state: data, replace: true});
                } else {
                    console.log("Something is Wrong");
                }
            }).catch(error => {
            if (error.response.data.status === false) {
                setErrors(error.response.data);
            }
            if (error.response.status === 422) {
                setErrors(error.response.data.errors);
            }
        });
    }

    return (
        <>
            <ToastContainer/>
            <Login onSubmit={onSubmit} errors={errors}/>
        </>
    );
};

export default LoginPage;