import React from 'react';
import {TbFileUpload} from "react-icons/tb";
import NestedFormIncDec from '../EmployeeCommonSection/ NestedFormIncDec';
import EmployeeInfoHeader from '../EmployeeCommonSection/EmployeeInfoHeader';
import FormSubmitBtn from '../EmployeeCommonSection/FormSubmitBtn';
import FourColumn from '../EmployeeCommonSection/FourColumn';
import EmployeeHeader from '../EmployeeCommonSection/EmployeeHeader';
import FileUpload from '../Utils/FileUpload';
import {useForm} from "react-hook-form";
import FieldErrorMessage from "../Utils/FieldErrorMessage";
import Select2Wrapper from "../Utils/Select2Wrapper";
import { isAdmin } from '../../helpers/helpers';
// import Select from 'react-select';

const EmployeeTransfer = (
    {
        onSubmit,
        register,
        handleSubmit,
        errors,
        setErrors,
        fields,
        append,
        remove,
        transfer,
        countries,
        reset,
        setValue,
        getValues,
        designation,
        deleteAttachment,
        permissions,
        userName
    }) => {

const {control} = useForm();
    return (
        <div className='employee_info'>
            <div className='card shadow border-0 mt-2'>
                <div className='card-header'>
                    <EmployeeHeader userName={userName} e_title="বদলি সংক্রান্ত তথ্য" e_num="22004689"/>
                </div>
                <div className='card-body'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {fields.map((item, index) => {
                            let attachments = item.attachments;
                            return (
                                <div className='mt-2' key={item.id}>
                                    <NestedFormIncDec fields={fields} append={append} remove={remove} index={index}/>
                                    <div className='row'>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">বদলির ধরণ<span
                                                className='text-danger'>*</span></label>
                                            <select className="form-select" aria-label="Default select example"
                                                    {...register(`transfer.${index}.type`)}>
                                                <option value="" style={{color: 'gray'}}>নির্বাচন করুন</option>
                                                {
                                                    transfer.map(transfer => <option key={transfer.id}
                                                                                    value={transfer.id}>{transfer.bn}</option>)
                                                }
                                            </select>
                                            <FieldErrorMessage errors={errors} name="type" prefix="transfer_info" index={index}/>
                                        </div>
                                        <FourColumn/>
                                    </div>

                                    <div className='row'>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">দেশের নাম<span
                                                className='text-danger'>*</span></label>

                                            <Select2Wrapper
                                                value={getValues(`transfer.${index}.country_id`)}
                                                onChange={(value) => setValue(`transfer.${index}.country_id`, value)}
                                                options={countries}
                                            />
                                            <FieldErrorMessage errors={errors} name="country_id" prefix="transfer_info" index={index}/>
                                        </div>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">প্রতিষ্ঠান<span
                                                className='text-danger'>*</span></label>
                                            <input className="form-control"
                                                {...register(`transfer.${index}.institute`)}
                                            />
                                            <FieldErrorMessage errors={errors} name="institute" prefix="transfer_info" index={index}/>
                                        </div>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">পদায়নের স্থান</label>
                                            <input className="form-control"
                                                {...register(`transfer.${index}.place_of_posting`)}
                                            />
                                        </div>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">দপ্তরের ঠিকানা</label>
                                            <input className="form-control"
                                                {...register(`transfer.${index}.office_address`)}
                                            />
                                        </div>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">পদবি<span
                                                className='text-danger'>*</span></label>
                                            {/*<input className="form-control"*/}
                                            {/*       {...register(`transfer.${index}.designation`)}*/}
                                            {/*/>*/}
                                            <Select2Wrapper
                                                value={getValues(`transfer.${index}.designation`)}
                                                onChange={(value) => setValue(`transfer.${index}.designation`, value)}
                                                options={designation}
                                            />
                                            <FieldErrorMessage errors={errors} name="designation" prefix="transfer_info" index={index}/>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">বদলি আদেশের নম্বর</label>
                                            <input className="form-control"
                                                {...register(`transfer.${index}.transfer_order_no`)}
                                            />
                                            <FieldErrorMessage errors={errors} name="transfer_order_no" prefix="transfer_info" index={index}/>
                                        </div>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">বদলি আদেশের তারিখ</label>
                                            <input className="form-control" type="date"
                                                {...register(`transfer.${index}.transfer_order_date`)}
                                            />
                                            <FieldErrorMessage errors={errors} name="transfer_order_date" prefix="transfer_info" index={index}/>
                                        </div>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">বদলির কারণ</label>
                                            <input className="form-control"
                                                {...register(`transfer.${index}.transfer_order_cause`)}
                                            />
                                        </div>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">পদায়নের ধরণ</label>
                                            <input className="form-control"
                                                {...register(`transfer.${index}.type_of_posting`)}
                                            />
                                        </div>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="" className='form-label'>বদলির ধরণ</label>
                                            <input className="form-control"
                                                {...register(`transfer.${index}.type_of_transfer`)}
                                            />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">যোগদানের তারিখ</label>
                                            <input className="form-control" type="date"
                                                {...register(`transfer.${index}.joining_date`)}
                                            />
                                        </div>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">বেতন স্কেল</label>
                                            <input className="form-control"
                                                {...register(`transfer.${index}.salary_scale`)}
                                            />
                                        </div>
                                        <div className="col-xs-auto col-md">
                                            <label htmlFor="inputAddress" className="form-label">মন্তব্য</label>
                                            <input className="form-control"
                                                {...register(`transfer.${index}.remarks`)}
                                            />
                                        </div>
                                        <div className="col-xs-auto col-md">
                                            <label htmlFor="attachments" className="form-label">প্রমাণক</label>
                                            <FileUpload
                                                title="প্রমাণক সংযুক্ত করুন"
                                                register={register}
                                                registerkey={`transfer.${index}.attachments`}
                                                attachments={attachments}
                                                deleteAttachment={deleteAttachment}
                                            />
                                        </div>
                                        <div className="col-xs-auto col-md mt-2 btn_file text-center"></div>
                                    </div>
                                </div>
                            );
                        })}
                        {((isAdmin() || permissions?.includes('edit'))) &&  <FormSubmitBtn setErrors={setErrors} reset={reset}/> }
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EmployeeTransfer;