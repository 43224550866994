import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import Loader from '../../Components/Loader/Loader';
import useEmployeeInfo from '../../Hooks/useEmployeeInfo';
import EmployeeTraining from '../../Components/EmployeeInfo/EmployeeTraining';
import { ToastContainer, toast } from 'react-toastify';
import { payloadAsFormData, url } from '../../helpers/helpers';

const EmployeeTrainingPage = ({permissions}) => {
    const {id} = useParams();
    const {basicInfo, loading, error} = useEmployeeInfo(id);
    const [trainingType, setTrainingType] = useState([]);
    const [countries, setCountries] = useState([]);
    const [resultPosition, setResultPosition] = useState([]);

    const { register, control, handleSubmit, setValue, formState: { errors }, reset } = useForm({
        defaultValues: {
            training: [
                {
                    type: "",
                    country_id: "",
                    course_name: "",
                    institution_name: "",
                    training_start_date: "",
                    training_end_date: "",
                    result: "",
                    remarks: "",
                    attachments: ""
                }
            ]
        }
    });

    const {fields, append, remove} = useFieldArray({
        control,
        name: "training"
    });

    useEffect(() => {
        if (basicInfo?.data?.training_infos.length > 0) {
            setValue(`training`, basicInfo?.data?.training_infos);
        } else {
            setValue(`training`, [
                {
                    type: "",
                    country_id: "",
                    course_name: "",
                    institution_name: "",
                    training_start_date: "",
                    training_end_date: "",
                    result: "",
                    remarks: "",
                    attachments: ""
                }
            ]);
        }
    }, [basicInfo?.data, trainingType]);

    const onSubmit = (data) => {
        let formData = new FormData();
        formData = payloadAsFormData(formData, 'basic_info', basicInfo?.data)

        for (let topIndex = 0; topIndex < data.training.length; topIndex++ ) {
            let payload = data.training[topIndex];
            formData = payloadAsFormData(formData, `training_info[${topIndex}]`, payload)
        }

        axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/employee`, formData, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                "Content-Type": "multipart/form-data"
            }
        }).then(response => {
                if (response.data?.message === 'Success') {
                    toast.success('Saved successful.');
                }
            })
            .catch(error => {
                console.error(error);
        });
    }

    const deleteAttachment = async (id) => {
        try {
            const accessToken = localStorage.getItem("accessToken");
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const {data} = await axios.delete(url(`attachment/${id}`), {headers});
            if (data) {
                console.log(data.data)
                toast.success('Attachment deleted successful.')
                window.location.reload()
            }
        } catch (e) {
            toast.error('something went wrong')
        }
    }

    const fetchTrainingType = async () => {
        try {
          const accessToken = localStorage.getItem("accessToken");
          const headers = {
            Authorization: `Bearer ${accessToken}`,
          };
    
          const [trainingTypes, countries, resultPosition] = await Promise.all([
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-training-type`, {headers}),
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-countries`, {headers}),
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-result-position`, {headers}),
          ]);
          setTrainingType(trainingTypes?.data?.data);
          setCountries(countries?.data?.data);
          setResultPosition(resultPosition?.data?.data);
        } catch (error) {
          console.error(error);
        }
      };

    useEffect(()=>{
        fetchTrainingType();
    },[])

    if(loading){
        return <Loader /> 
    }

    return (
        <div className='component_container py-2 my-3 py-md-5 my-md-5'>
            <ToastContainer />
            <EmployeeTraining
                onSubmit={onSubmit}
                register={register}
                handleSubmit={handleSubmit}
                errors={errors}
                fields={fields}
                append={append}
                remove={remove}
                trainingType={trainingType}
                countries={countries}
                resultPosition={resultPosition}
                reset={reset}
                deleteAttachment={deleteAttachment}
                permissions={permissions}
                userName={basicInfo?.data?.name_in_bn}
            />
        </div>
    );
};

export default EmployeeTrainingPage;