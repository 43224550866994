import React, {useEffect, useState} from 'react';
import EmployeeSpouse from '../../Components/EmployeeInfo/EmployeeSpouse';
import useEmployeeInfo from '../../Hooks/useEmployeeInfo';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import {useFieldArray, useForm} from 'react-hook-form';
import Loader from '../../Components/Loader/Loader';
import {payloadAsFormData, url} from '../../helpers/helpers';
import {toast, ToastContainer} from 'react-toastify';

const EmployeeSpousePage = ({permissions}) => {
    const {id} = useParams();
    const {basicInfo, loading} = useEmployeeInfo(id);
    const [bloodGroups, setBloodGroups] = useState();
    const [errors, setErrors] = useState([]);
    const emptySpouseInfo = [{
        nid: "",
        name_in_bn: "",
        name_in_en: "",
        blood_group: "",
        occupation: "",
        mother_name_in_bn: "",
        mother_name_in_en: "",
        father_name_in_bn: "",
        father_name_in_en: "",
        passport_no: "",
        phone: "",
        house_no: "",
        road_no: "",
        village: "",
        municipality: "",
        post_office: "",
        upazila: "",
        district: "",
        division: "",
        attachments: "",
    }];
    const {register, control, handleSubmit, setValue, reset} = useForm({
        defaultValues: {
            spouse_info: emptySpouseInfo
        }
    });
    const {fields, append, remove} = useFieldArray({
        control,
        name: "spouse_info"
    });

    useEffect(() => {
        if (basicInfo?.data?.spouse_infos.length > 0) {
            setValue(`spouse_info`, basicInfo?.data?.spouse_infos);
        } else {
            setValue(`spouse_info`, emptySpouseInfo)
        }
    }, [basicInfo?.data, bloodGroups])

    const onSubmit = (data) => {
        let formData = new FormData();
        formData = payloadAsFormData(formData, 'basic_info', basicInfo?.data);
        for (let index = 0; index < data.spouse_info.length; index++) {
            let payload = data.spouse_info[index];
            formData = payloadAsFormData(formData, `spouse_info[${index}]`, payload);
        }

        axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/employee`, formData, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                "Content-Type": "multipart/form-data"
            }
        }).then(response => {
            if (response.data?.message === "Success") {
                toast.success('Saved successfully.');
                setValue('spouse_info', response?.data?.data?.spouse_infos);
                setErrors([]);
            }
        }).catch(error => {
                if (error.response.status === 422) {
                    setErrors(error?.response?.data?.errors);
                }
            }
        );
    }

    const deleteAttachment = async (id) => {
        try {
            const accessToken = localStorage.getItem("accessToken");
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const {data} = await axios.delete(url(`attachment/${id}`), {headers});
            if (data) {
                toast.success('Attachment deleted successful.');
                window.location.reload();
            }
        } catch (e) {
            //toast.error('something went wrong')
        }
    }

    const fetchBloodGroup = async () => {
        try {
            const accessToken = localStorage.getItem("accessToken");
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const [bloodGroups] = await Promise.all([
                axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-blood-group`, {headers}),
            ]);
            setBloodGroups(bloodGroups?.data?.data)
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchBloodGroup();
    }, []);

    if (loading) {
        return <Loader/>
    }

    return (
        <div className='component_container py-2 my-3 py-md-5 my-md-5'>
            <ToastContainer/>
            <EmployeeSpouse
                onSubmit={onSubmit}
                register={register}
                handleSubmit={handleSubmit}
                errors={errors}
                fields={fields}
                append={append}
                remove={remove}
                reset={reset}
                bloodGroups={bloodGroups}
                deleteAttachment={deleteAttachment}
                permissions={permissions}
                userName={basicInfo?.data?.name_in_bn}
            />
        </div>
    );
};

export default EmployeeSpousePage;