import React, {useState} from 'react';
import FormSubmitBtn from '../EmployeeCommonSection/FormSubmitBtn';
import ThreeColumn from '../EmployeeCommonSection/ThreeColumn';
import EmployeeHeader from '../EmployeeCommonSection/EmployeeHeader';
import FieldErrorMessage from "../Utils/FieldErrorMessage";
import Select2Wrapper from "../Utils/Select2Wrapper";
import {isAdmin} from '../../helpers/helpers';

const EmployeeAddressCom = (
        {
            onSubmit,
            register,
            errors,
            handleSubmit,
            fields,
            address,
            reset,
            handleDivisionChange,
            handleDistrictChange,
            division,
            district,
            upazilla,
            getValues,
            setValue,
            setErrors,
            permissions,
            userName,
        }
    ) => {
        const [copyAddressChecked, setCopyAddressChecked] = useState(false);
        const handleCheckBoxClick = (index, event) => {
            let addresses = getValues('address');
            if (addresses.length !== 2 || addresses[index - 1] === undefined) {
                return;
            }
            const presentAddress = {...addresses?.[index - 1]};
            const permanentAddress = {...addresses?.[index]};

            if (event.target.checked) {
                const ignoredKeys = ['id', 'type', 'created_at', 'created_by', 'deleted_at', 'deleted_by', 'updated_at', 'updated_by'];
                const previousTypeId = addresses?.[index]?.['type'];
                addresses[index] = {...permanentAddress, ...filterObjectWithOutKeys(presentAddress, ignoredKeys)};
                addresses[index]['type'] = previousTypeId;
            }

            setValue('address', [...addresses]);
            handleDivisionChange(index, presentAddress?.division);
            handleDistrictChange(index, presentAddress?.district);
        }
        const filterObjectWithOutKeys = (obj, keys) => {
            const filteredObject = obj;
            Object.values(keys).forEach(function (key) {
                delete filteredObject[key];
            })

            return filteredObject;
        }
        const getAddressNameByType = (type) => {
            return Object.values(address).find(function (addr) {
                return parseInt(addr.id) === parseInt(type);
            })?.bn;
        }
        return (
            <div className='employee_info'>
                <div className='card shadow border-0 mt-2'>
                    <div className='card-header'>
                        <EmployeeHeader e_title="কর্মকর্তা / কর্মচারীর ঠিকানা"
                                        userName={userName}
                        />
                    </div>
                    <div className='card-body'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {fields.map((item, index) => {
                                return (
                                    <div className='row' key={item.id}>
                                        <div className="col-xs-auto col-md mb-3">

                                            <h5 className='mt-3'>{getAddressNameByType(getValues(`address.${index}.type`))}</h5>
                                        </div>
                                        <div className="col-xs-auto col-md mb-3">
                                            {
                                                getValues(`address.${index}.type`) === 2 &&
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        defaultChecked={copyAddressChecked}
                                                        onChange={(event) => {
                                                            setCopyAddressChecked(!copyAddressChecked);
                                                            handleCheckBoxClick(index, event)
                                                        }}
                                                    />
                                                    <span> বর্তমান  ঠিকানার মতো একই</span>
                                                </div>
                                            }
                                        </div>

                                        <div className='row'>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">বাসা নম্বর (যদি থাকে)</label>
                                                <input type="text" className="form-control"
                                                       {...register(`address.${index}.house_no`)}/>
                                            </div>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">রাস্তা নম্বর (যদি থাকে)</label>
                                                <input type="text" className="form-control"
                                                       {...register(`address.${index}.road_no`)}/>
                                            </div>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">ওয়ার্ড নং</label>
                                                <input type="text" className="form-control"
                                                       {...register(`address.${index}.word`)}/>
                                                <FieldErrorMessage errors={errors} name="word" index={index}
                                                                   prefix="address"/>
                                            </div>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">গ্রাম/শহর </label>
                                                <input type="text" className="form-control"
                                                       {...register(`address.${index}.village`)}/>
                                                <FieldErrorMessage errors={errors} name="village" index={index}
                                                                   prefix="address"/>
                                            </div>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">পৌরসভা</label>
                                                <input type="text" className="form-control"
                                                       {...register(`address.${index}.municipality`)}/>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">বিভাগ </label>
                                                <Select2Wrapper
                                                    value={getValues(`address.${index}.division`)}
                                                    onChange={(value) => {
                                                        setValue(`address.${index}.division`, value);
                                                        handleDivisionChange(index, value)
                                                    }}
                                                    options={division}
                                                />
                                                <FieldErrorMessage errors={errors} name="division" index={index}
                                                                   prefix="address"/>
                                            </div>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">জেলা <span
                                                    className='text-danger'>*</span></label>
                                                <Select2Wrapper
                                                    value={getValues(`address.${index}.district`)}
                                                    onChange={(value) => {
                                                        setValue(`address.${index}.district`, value);
                                                        handleDistrictChange(index, value)
                                                    }}
                                                    options={district[index]}
                                                />
                                                <FieldErrorMessage errors={errors} name="district" index={index}
                                                                   prefix="address"/>
                                            </div>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">উপজেলা <span
                                                    className='text-danger'>*</span></label>
                                                <Select2Wrapper
                                                    value={getValues(`address.${index}.upazila`)}
                                                    onChange={(value) => {
                                                        setValue(`address.${index}.upazila`, value);
                                                    }}
                                                    options={upazilla[index]}
                                                />
                                                <FieldErrorMessage errors={errors} name="upazila" index={index}
                                                                   prefix="address"/>
                                            </div>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">ডাকঘর</label>
                                                <input type="text" className="form-control"
                                                       {...register(`address.${index}.post_office`)}/>
                                                <FieldErrorMessage errors={errors} name="post_office" index={index}
                                                                   prefix="address"/>
                                            </div>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">পোস্টাল কোড</label>
                                                <input type="text" className="form-control"
                                                       {...register(`address.${index}.postal_code`)}/>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">মোবাইল নম্বর</label>
                                                <input type="tel" className="form-control"
                                                       {...register(`address.${index}.phone`)}/>
                                            </div>
                                            <div className="col-xs-auto col-md mb-3">
                                                <label className="form-label">ইমেইল এড্রেস (যদি থাকে)</label>
                                                <input type="email" className="form-control"
                                                       {...register(`address.${index}.email`)}/>
                                            </div>
                                            <ThreeColumn/>
                                        </div>
                                    </div>
                                );
                            })}
                            {((isAdmin() || permissions?.includes('edit'))) &&
                                <FormSubmitBtn reset={reset} setErrors={setErrors}/>}
                        </form>
                    </div>
                </div>
            </div>
        );
    }
;

export default EmployeeAddressCom;