import React, {useEffect, useState} from "react";
import FieldErrorMessage from "../Utils/FieldErrorMessage";
import {MdDeleteOutline} from "react-icons/md";
import {FaRegEdit} from "react-icons/fa";
import {toast, ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {authToken, axiosConfig, translateToBanglaDigit, url} from "../../helpers/helpers";
import axios from "axios";

const Ministry = () => {
    const navigate = useNavigate();
    const [designation, setDesignation] = useState([]);
    const [errors, setErrors] = useState([]);
    const {register, setValue, handleSubmit} = useForm();
    const axiosHeader = {
        headers: {
            authorization: `Bearer ${authToken()}`,
        }
    }

    const getDesignation = () => {
        axios.get(url(`designation`), axiosHeader)
            .then(({data}) => {
                console.log(data)
                setDesignation(data.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleDelete = (id) => {
        if (!window.confirm('Are you sure?')) return;

        axios.delete(url(`designation/${id}`), axiosConfig())
            .then((response) => {
                toast.success('Deleted Successful');
                getDesignation();
            })
            .catch(error => {
                console.log(error);
            });
    }
    const handleEdit = (data) => {
        Object.entries(data).forEach(([key, value]) => setValue(key, value));
    }
    const onSubmit = (data) => {
        axios.post(url('designation'), data, axiosConfig())
            .then(response => {
                setErrors([]);
                setValue('id', '');
                setValue('name', '');
                toast.success('Saved Successful');
                getDesignation();
            })
            .catch(error => {
                if (error.response.status === 422) {
                    setErrors(error.response.data.errors);
                }
            });
    }

    useEffect(() => {
        getDesignation();
    }, [])

    return (
        <>
            <div className="component_container py-2 my-3">
                <ToastContainer/>
                <div className="row">
                    <div className="col-12">
                        <div className='card mt-2'>
                            <div className="card-header pt-3">
                                <h4>পদবি</h4>
                            </div>
                            <div className='card-body'>
                                <div className="row">
                                    <div className="col-4">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <label htmlFor="phone" className="form-label">নাম <span
                                                className='text-danger'>*</span></label>
                                            <input type="text" className="form-control"
                                                   {...register("name")}/>
                                            <FieldErrorMessage errors={errors} name='name'/>
                                            <br/>
                                            <button className="btn btn-sm btn-success">সংরক্ষণ করুন</button>
                                        </form>
                                    </div>
                                    <div className="col-8">
                                        <div className='table-responsive pt-4'>
                                            <table className="table table-bordered table-hover">
                                                <thead>
                                                <tr style={{backgroundColor: '#f9f9f9'}}>
                                                    <th scope="col" style={{textAlign: "center"}}>ক্রমিক নং</th>
                                                    <th scope="col">নাম</th>
                                                    <th scope="col">অ্যাকশন</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    designation && designation.map((value, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td style={{textAlign: 'center'}}>{translateToBanglaDigit(index + 1)}</td>
                                                                <td>{value.name}</td>
                                                                <td>
                                                                    <div>
                                                                        <button className="btn btn-sm btn-secondary"
                                                                                onClick={() => handleEdit(value)}>
                                                                            <FaRegEdit/>
                                                                        </button>
                                                                        {/* &nbsp;
                                                                        <button className="btn btn-sm btn-secondary"
                                                                                onClick={() => handleDelete(value.id)}>
                                                                            <MdDeleteOutline/>
                                                                        </button> */}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Ministry;