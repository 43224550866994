import axios from 'axios';
import React, { useEffect, useState } from 'react';
import "./loginSignUp.css";
import LoginSignupLogo from './LoginSignupLogo';
import SignUpForm from './SignUpForm';

const SignUp = ({onSubmit, errors}) => {
    const [uniqueId, setUniqueId] = useState()

    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-register-unique-id`)
        .then(response => {
                setUniqueId(response.data)
            })
            .catch(error => {
                console.error(error);
        });
    },[])

    return (
        <div className='login_container'>
            <div className='clip_path'></div>
            <div className='container'>
                <div className="row gx-2 align-items-center justify-content-center login_content">
                    <LoginSignupLogo />
                    <SignUpForm onSubmit={onSubmit} uniqueId={uniqueId} errors={errors}/>
                </div>
            </div>
        </div>
    );
};

export default SignUp;