import React from 'react';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';

const  NestedFormIncDec = ({fields, append, remove, index}) => {
    return (
        <div className='py-1 d-flex justify-content-end'>
            {fields.length === 1 ? 
                <button className='btn btn-sm btn-success' type="button" onClick={() => append(index)}>
                    <AiOutlinePlus />
                </button>
                :
                <div className='d-flex'>
                    <button className='btn btn-sm btn-danger me-1' type="button" onClick={() => remove(index)}>
                        <AiOutlineMinus />
                    </button>
                    <button className='btn btn-sm btn-success' type="button" onClick={() => {append()}}>
                        <AiOutlinePlus />
                    </button>
                </div>
            }
        </div>
    );
};

export default  NestedFormIncDec;