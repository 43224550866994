import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFoundPage = () => {
    return (
        <div className='component_container py-2 my-3 py-md-5 my-md-5 d-flex align-items-center justify-content-center' style={{height: '40vh'}}>
            <div className='text-center'>
                <h4>Sorry, this page isn't available.</h4>
                <br />
                <p>The link you followed may be broken, or the page may have been removed. <Link to="/" style={{textDecoration: 'none', color: 'blueviolet'}}>Go back to Home</Link>.</p>
            </div>
        </div>
    );
};

export default PageNotFoundPage;