import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Loader from '../../Components/Loader/Loader';
import TravelReport from '../../Components/Reports/TravelReport';

const TravelReportPage = () => {
    const [travelInfo, setTravelInfo] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [ministryType, setMinistryType] = useState();
    console.log('ministryType', ministryType);

    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/employee`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            }
        }).then(response => {
                setTravelInfo(response.data?.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error(error);
                setIsLoading(false);
        });
    }, [])

    return (
        <>
            {
                isLoading ? (<Loader />) : (<TravelReport travelInfo={travelInfo} />)
            }
        </>
        
    );
};

export default TravelReportPage;