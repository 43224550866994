import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import EmployeeInfo from '../../Components/EmployeeInfo/EmployeeInfo';
import useEmployeeInfo from '../../Hooks/useEmployeeInfo';
import {useForm} from 'react-hook-form';
import Loader from '../../Components/Loader/Loader';
import {payloadAsFormData, url} from "../../helpers/helpers";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const EmployeeInfoPage = ({permissions}) => {
    const navigate = useNavigate()
    const {id} = useParams()
    const {register, handleSubmit, setValue, reset, getValues} = useForm();
    const {basicInfo, loading, error} = useEmployeeInfo(id);
    const [classes, setClasses] = useState([]);
    const [married, setMarried] = useState([]);
    const [genders, setGenders] = useState([]);
    const [religion, setReligion] = useState([]);
    const [cadres, setCadres] = useState([]);
    const [bloodGroups, setBloodGroups] = useState([]);
    const [users, setUsers] = useState();
    const [userId, setUserId] = useState();
    const [attachments, setAttachments] = useState([]);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (basicInfo?.data) {
            Object.entries(basicInfo?.data).forEach(([key, value]) => setValue(key, value));
            setAttachments(basicInfo?.data?.attachments)
        }
        if (!userId && !id) {
            let user = JSON.parse(localStorage.getItem("user"));
            setValue('user_id', user.id);
            setUserId(user.id);
        }
    }, [basicInfo?.data, classes, married, genders, cadres, bloodGroups, users]);

    useEffect(() => {
        if (userId) {
            let cloneUserId = userId;
            const config = {headers: {authorization: `Bearer ${localStorage.getItem("accessToken")}`}};
            const url = `${process.env.REACT_APP_BASE_URL}/api/v1/fetch-user-info/${userId}`;

            axios.get(url, config)
                .then(({data}) => {
                    if (data.data) {
                        Object.entries(data.data).forEach(([key, value]) => setValue(key, value));
                        localStorage.setItem("currentUserId", data.data.id);
                        window.location.replace(`/employee-info-edit/${localStorage.getItem("currentUserId")}`);
                    } else {
                        reset();
                        setValue('user_id', cloneUserId);
                        toast.warning('Information not found.')
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        }
    }, [userId])

    const onSubmit = async (payload, e) => {
        e.preventDefault();
        try {
            let formData = new FormData();
            const config = {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                    "Content-Type": "multipart/form-data"
                },
            };
            const {data} = await axios.post(
                url('employee'),
                payloadAsFormData(formData, 'basic_info', payload),
                config
            );

            if (data) {
                window.history.replaceState({}, '', `/employee-info-edit/${data?.data?.id}`);
                // window.location.replace(`/employee-info-edit/${data?.data?.id}`)
                toast.success('Saved Successfully');
                localStorage.setItem("currentUserId", data.data.id);
                setAttachments(data.data.attachments);
                // setValue('user_id', data.data.user_id);
                // setUserId(data.data.user_id);
                setErrors([]);
            }
        } catch (error) {
            if (error?.response?.status === 422) {
                setErrors(error.response.data.errors);
            }
        }
    }

    const fetchDropdownData = async () => {
        try {
            const accessToken = localStorage.getItem("accessToken");
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const [classes, genders, married, cadres, bloodGroups, religion] = await Promise.all([
                axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-class`, {headers}),
                axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-gender`, {headers}),
                axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-marital-status`, {headers}),
                axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-cadre`, {headers}),
                axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-blood-group`, {headers}),
                axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-religion`, {headers}),
            ]);
            setCadres(cadres?.data?.data)
            setGenders(genders?.data?.data)
            setMarried(married?.data?.data)
            setClasses(classes?.data?.data)
            setBloodGroups(bloodGroups?.data?.data)
            setReligion(religion?.data?.data)
        } catch (error) {
            console.error(error);
        }
    };

    const deleteAttachment = async (id) => {
        try {
            const accessToken = localStorage.getItem("accessToken");
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };

            const {data} = await axios.delete(url(`attachment/${id}`), {headers});
            if (data) {
                setAttachments(data.data);
                toast.success('Attachment deleted successful.')
            }
        } catch (e) {
            //toast.error('something went wrong')
        }
    }

    useEffect(() => {
        fetchDropdownData();
    }, []);

    useEffect(() => {
        if (!localStorage.getItem('accessToken')) {
            navigate('/login')
        }
    }, [localStorage.getItem('accessToken')])

    if (loading) {
        return <Loader/>
    }

    return (

        <div className='component_container py-2 my-3 py-md-5 my-md-5'>
            <ToastContainer/>
            <EmployeeInfo
                permissions={permissions}
                onSubmit={onSubmit}
                register={register}
                handleSubmit={handleSubmit}
                errors={errors}
                setErrors={setErrors}
                genders={genders}
                classes={classes}
                married={married}
                cadres={cadres}
                bloodGroups={bloodGroups}
                religion={religion}
                setUsers={setUsers}
                users={users}
                userId={userId}
                attachments={attachments}
                reset={reset}
                setValue={setValue}
                getValues={getValues}
                deleteAttachment={deleteAttachment}
                userName={basicInfo?.data?.name_in_bn}
                percentage={basicInfo?.data?.percentage?.total_percentage}
            />
        </div>
    );
};

export default EmployeeInfoPage;