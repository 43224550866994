import React, { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { axiosConfig, isAdmin, translateToBanglaDigit, url} from "../../helpers/helpers";
import axios from "axios";
import Select2Wrapper from '../Utils/Select2Wrapper';
import { AiOutlineSearch } from 'react-icons/ai';
import { FiRefreshCw } from 'react-icons/fi';
import { FaList } from 'react-icons/fa';
import Loader from '../Loader/Loader';

const EmployeeReport = ({travelInfo}) => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const [organizations, setOrganizations] = useState([]);
    const [from, setFrom] = useState();
    const [employeeData, setEmployeeData] = useState([]);
    const {register, getValues, setValue, handleSubmit, reset} = useForm({defaultValues: {
        organization_id: '',
        institute_id: ''
    }});
    const [ministryOrganizationAndInstitution, setMinistryOrganizationAndInstitution] = useState([]);
    const [institutions, setInstitutions] = useState([]);

    const handleResetBtnClick = () => {
        reset({
            organization_id: '',
            institute_id: ''
        })
        toast.success("Refreshed");
    }
    const getReports = (formData = {}) => {
        setIsLoading(true);
        axios.get(url(`employee-report`), {...axiosConfig(), params: formData})
            .then(({data: {data}}) => {
                setEmployeeData(data)
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                if (error?.response?.status === 401) {
                    navigate('/login')
                }
            });
    }
    const getMinistryOrganizationAndInstitution = () => {
        try {
            axios.get(url(`fetch-ministry-organization-and-institution-user-wise`), axiosConfig())
                .then(({data: {data}}) => {
                    setMinistryOrganizationAndInstitution(data);
                    let org = data.filter(item => item.type == 'organization');
                    setOrganizations(org);
                })
                .catch((error) => {
                    console.log(error);
                })
        } catch (e) {
            toast.error('something went wrong')
        }
    }

    const handleOrganizationChange = (id) => {
        setValue('organization_id', id)
        let institutes = ministryOrganizationAndInstitution.filter(data => data.parent_id == id && data.type === 'institution');
        setInstitutions(institutes);
    }
    const totalEntryDataActive = (employees=[]) => {
        const totalOrgEmployee = employees.map(item => {
            const totalInsEmp = item.institutes.reduce((total, employee) => total + Number(employee.institute_employees_active_count), 0);
            return {
                total: totalInsEmp,
            }
        })
        return totalOrgEmployee.reduce((total, org) => total + org.total, 0);
    }
    const totalEntryDataInactive = (employees=[]) => {
        const totalOrgEmployee = employees.map(item => {
            const totalInsEmp = item.institutes.reduce((total, employee) => total + Number(employee.institute_employees_in_active_count), 0);
            return {
                total: totalInsEmp,
            }
        })
        return totalOrgEmployee.reduce((total, org) => total + org.total, 0);
    }
    const totalEmployeeData = (employees=[]) => {
        const totalOrgEmployee = employees.map(item => {
            const totalEmployee = item.institutes.reduce((total, employee) => total + (employee.total_employee > 0 ? Number(employee.total_employee) : 0), 0);
            return {
                totalEmployee: totalEmployee,
            }
        })

        console.log(totalOrgEmployee, 'totalOrgEmployee');

        return totalOrgEmployee.reduce((total, org) => total + Number(org.totalEmployee), 0);

    }


    useEffect(() => {
        getMinistryOrganizationAndInstitution();
        getReports()
    }, []);


    return (
        <div className='component_container'>

            <div className="card">
                <div className="card-header pt-3">
                    <div className="row">
                        <div className="col-md-10">
                            <h4><FaList/> &nbsp; </h4>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className=''>
                            <form onSubmit={handleSubmit(getReports)}>
                                <div className="row">
                                    <div className="col-3">
                                        <Select2Wrapper
                                            value={getValues('organization_id')}
                                            placeholder="প্রতিষ্ঠান নির্বাচন করুন"
                                            options={organizations}
                                            onChange={(value) => handleOrganizationChange(value)}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <Select2Wrapper
                                            value={getValues('institute_id')}
                                            placeholder="প্রতিষ্ঠান নির্বাচন করুন"
                                            onChange={(value) => setValue('institute_id', value)}
                                            options={institutions}
                                        />
                                    </div>

                                    <div className="col-3" style={{ textAlign: 'right' }}>
                                        <button type="submit" className="btn btn-success mt-1 text-right"
                                                style={{marginRight: '10px'}}>
                                            <AiOutlineSearch/></button>
                                        <button className="btn btn-warning mt-1 text-right" onClick={handleResetBtnClick}>
                                            <FiRefreshCw/>
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <div className='table-responsive mt-4'>
                                <table className='table table-bordered border-dark text-center' style={{fontSize: '14px'}}>
                                    <thead>
                                        <tr>
                                            <th className="text-center" scope="col">ক্রমিক নং</th>
                                            <th scope="col">মন্ত্রণালয়</th>
                                            <th scope="col">দপ্তর/সংস্থা</th>
                                            <th scope="col">প্রতিষ্ঠান</th>
                                            <th scope="col">অ্যাক্টিভ ডাটা</th>
                                            <th scope="col">ইনঅ্যাক্টিভ ডাটা</th>
                                            <th scope="col">মোট ডাটা</th>
                                            <th scope="col">মোট জনবল</th>
                                        </tr>
                                    </thead>
                                    { isLoading ?
                                    <tbody>
                                        <tr >
                                            <td colSpan={8} className='text-center'><p style={{fontSize: '22px'}}>Loading please wait...</p></td>
                                        </tr> 
                                    </tbody> :
                                    <tbody>
                                           { employeeData.length ? employeeData.map((orgData, index) => {
                                                return orgData.institutes.map((item, indx) => {
                                                    return (
                                                        <tr>
                                                            { indx == 0 && <td rowSpan={orgData.institutes.length}>{ translateToBanglaDigit(index +1)  }</td>}
                                                            { indx == 0 && <td rowSpan={orgData.institutes.length}>{ 'শিল্প মন্ত্রণালয়' }</td>}
                                                            { indx == 0 && <td rowSpan={orgData.institutes.length}>{ orgData.name }</td>}
                                                            <td>{ item.name }</td>
                                                            <td>{ translateToBanglaDigit(item.institute_employees_active_count) }</td>
                                                            <td>{ translateToBanglaDigit(item.institute_employees_in_active_count) }</td>
                                                            <td>{ translateToBanglaDigit(Number(item.institute_employees_active_count) + Number(item.institute_employees_in_active_count)) }</td>
                                                            <td>{ translateToBanglaDigit(item.total_employee) }</td>
                                                        </tr>
                                                    )
                                                })
                                            }) : ''
                                           }
                                           {
                                            employeeData.length ?  <tr>
                                                <td style={ {textAlign:'right'}} colSpan={4}>মোট</td>
                                                <td>{ translateToBanglaDigit(totalEntryDataActive(employeeData)) }</td>
                                                <td>{ translateToBanglaDigit(totalEntryDataInactive(employeeData)) }</td>
                                                <td>{ translateToBanglaDigit(Number(totalEntryDataActive(employeeData)) + Number(totalEntryDataInactive(employeeData))) }</td>
                                                <td>{ translateToBanglaDigit(totalEmployeeData(employeeData)) }</td>
                                            </tr> : ''
                                            }
                                            {
                                                !employeeData.length && 
                                                <tr>
                                                    <td colSpan={8}>(খালি)</td>
                                                </tr>
                                            }
                                    </tbody>
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmployeeReport;