import React, { useState } from 'react';
import { TbFileUpload } from "react-icons/tb";
import NestedFormIncDec from '../EmployeeCommonSection/ NestedFormIncDec';
import EmployeeInfoHeader from '../EmployeeCommonSection/EmployeeInfoHeader';
import FormSubmitBtn from '../EmployeeCommonSection/FormSubmitBtn';
import ThreeColumn from '../EmployeeCommonSection/ThreeColumn';
import EmployeeHeader from '../EmployeeCommonSection/EmployeeHeader';
import FileUpload from '../Utils/FileUpload';
import { isAdmin } from '../../helpers/helpers';

const EmployeeExtraCurricular = (
    {
        onSubmit,
        register,
        handleSubmit,
        errors,
        XCActivitiesList,
        appendXCActivitiesList,
        removeXCActivitiesList,
        reset,
        deleteAttachment,
        permissions,
        userName
    }) => {

    return (
        <div className='employee_info'>
            <div className='card shadow border-0 mt-2'>
                <div className='card-header'>
                    <EmployeeHeader userName={userName} e_title="এক্সট্রা-কারিকুলার সংক্রান্ত তথ্য" e_num="22004689" />
                </div>
                <div className='card-body'>
            <form className='pt-4' onSubmit={handleSubmit(onSubmit)}>
                {XCActivitiesList.map((item, index) => {
                    let attachments = item.attachments;

                    return (

                        <div className='row' key={item.id}>
                            <NestedFormIncDec fields={XCActivitiesList} append={appendXCActivitiesList} remove={removeXCActivitiesList} index={index} />

                                    <div className='col-xs-auto col-md mb-3'>
                                        <label htmlFor="inputAddress" className="form-label">কাজের ধরণ<span className='text-danger'>*</span></label>
                                        <input className="form-control" placeholder=""
                                            {...register(`xc_activities.${index}.type`, { required: true })}
                                        />
                                        {errors?.xc_activities?.[index]?.type && <small className='text-danger'>This field is required.</small>}
                                    </div>
                                    <div className='col-xs-auto col-md mb-3'>
                                        <label htmlFor="inputAddress" className="form-label">কাজ শুরুর তারিখ</label>
                                        <input className="form-control" type="date"
                                            {...register(`xc_activities.${index}.start_date`)}
                                        />
                                    </div>
                                    <div className='col-xs-auto col-md mb-3'>
                                        <label htmlFor="inputAddress" className="form-label">কাজ শেষের তারিখ</label>
                                        <input className="form-control" type="date"
                                            {...register(`xc_activities.${index}.end_date`)}
                                        />
                                    </div>
                                    <div className='col-xs-auto col-md mb-3'>
                                        <label htmlFor="inputAddress" className="form-label">কাজের স্থান</label>
                                        <input className="form-control" placeholder=""
                                            {...register(`xc_activities.${index}.work_place`)}
                                        />
                                    </div>
                                    <div className='col-xs-auto col-md mb-3'>
                                        <label htmlFor="inputAddress" className="form-label">কাজের বর্ণনা</label>
                                        <input className="form-control" placeholder=""
                                            {...register(`xc_activities.${index}.work_description`)}
                                        />
                                    </div>

                                <div className='row'>
                                    <div className='col-xs-auto col-md mb-3'>
                                        <label htmlFor="inputAddress" className="form-label">সহযোগী ব্যক্তি বা প্রতিষ্ঠান</label>
                                        <input className="form-control" placeholder=""
                                            {...register(`xc_activities.${index}.associated_person_or_institute`)}
                                        />
                                    </div>
                                    <div className="col-xs-auto col-md mt-2 btn_file text-center">
                                        <label htmlFor="attachments" className="form-label"></label>
                                        <br/>
                                        <FileUpload
                                            title="প্রমাণক সংযুক্ত করুন"
                                            register={register}
                                            registerkey={`xc_activities.${index}.attachments`}
                                            attachments={attachments}
                                            deleteAttachment={deleteAttachment}
                                        />
                                    </div>
                                    <ThreeColumn />
                                </div>
                                </div>
                    );
                })}

                {((isAdmin() || permissions?.includes('edit'))) &&  <FormSubmitBtn reset={reset}/> }

            </form>
        </div>
        </div>
        </div>
    );
};

export default EmployeeExtraCurricular;