import React from 'react';
import {TbFileUpload} from "react-icons/tb";
import NestedFormIncDec from '../EmployeeCommonSection/ NestedFormIncDec';
import EmployeeInfoHeader from '../EmployeeCommonSection/EmployeeInfoHeader';
import FormSubmitBtn from '../EmployeeCommonSection/FormSubmitBtn';
import FourColumn from '../EmployeeCommonSection/FourColumn';
import EmployeeHeader from '../EmployeeCommonSection/EmployeeHeader';
import FileUpload from '../Utils/FileUpload';
import { isAdmin } from '../../helpers/helpers';

const EmployeeTraining = (
    {
        onSubmit,
        register,
        handleSubmit,
        errors,
        fields,
        append,
        remove,
        trainingType,
        countries,
        resultPosition,
        reset,
        deleteAttachment,
        permissions,
        userName
    }) => {

    return (
        <div className='employee_info'>
            <div className='card shadow border-0 mt-2' >
                <div className='card-header'>
                    <EmployeeHeader userName={userName} e_title="প্রশিক্ষণ সংক্রান্ত তথ্য" e_num="22004689"/>
                </div>         
                <div className='card-body'>       
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {fields.map((item, index) => {
                            let attachments = item.attachments;
                            return (
                                <div key={item.id}>
                                    <NestedFormIncDec fields={fields} append={append} remove={remove} index={index}/>
                                    <div className='row'>
                                        <div className="col-xs-auto col-md mb-3">
                                            <select className="form-select" aria-label="Default select example"
                                                    {...register(`training.${index}.type`, {required: true})}>
                                                <option value="" style={{color: 'gray'}}>নির্বাচন করুন</option>
                                                {
                                                    trainingType.map(training => <option key={training.id}
                                                                                            value={training.id}>{training.bn}</option>)
                                                }
                                            </select>
                                            {errors?.training?.[index]?.type &&
                                                <small className='text-danger'>This field is required.</small>}
                                        </div>
                                        <FourColumn/>
                                    </div>
                                    <div className='row'>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">দেশের নাম <span
                                                className='text-danger'>*</span></label>
                                            <select className="form-select" aria-label="Default select example"
                                                    {...register(`training.${index}.country_id`, {required: true})}>
                                                <option value="" style={{color: 'gray'}}>নির্বাচন করুন</option>
                                                {
                                                    countries.map(country => <option key={country.id}
                                                                                        value={country.id}>{country.bn}</option>)
                                                }
                                            </select>
                                            {errors?.training?.[index]?.country_id &&
                                                <small className='text-danger'>This field is required.</small>}
                                        </div>
                                        <div className='col-xs-auto col-md mb-3'>
                                            <label htmlFor="inputAddress" className="form-label">কোর্সের নাম <span
                                                className='text-danger'>*</span></label>
                                            <input className="form-control"
                                                    {...register(`training.${index}.course_name`, {required: true})}
                                            />
                                            {errors?.training?.[index]?.course_name &&
                                                <small className='text-danger'>This field is required.</small>}
                                        </div>
                                        <div className='col-xs-auto col-md mb-3'>
                                            <label htmlFor="inputAddress" className="form-label">প্রশিক্ষণদাতা প্রতিষ্ঠানের
                                                নাম</label>
                                            <input className="form-control"
                                                    {...register(`training.${index}.institution_name`)}
                                            />
                                        </div>
                                        <div className='col-xs-auto col-md mb-3'>
                                            <label htmlFor="inputAddress" className="form-label">প্রশিক্ষণ শুরুর তারিখ </label>
                                            <input className="form-control" type="date"
                                                    {...register(`training.${index}.training_start_date`)}
                                            />
                                            {errors?.training?.[index]?.training_start_date &&
                                                <small className='text-danger'>This field is required.</small>}
                                        </div>
                                        <div className='col-xs-auto col-md mb-3'>
                                            <label htmlFor="inputAddress" className="form-label">প্রশিক্ষণ শেষের তারিখ</label>
                                            <input className="form-control" type="date"
                                                    {...register(`training.${index}.training_end_date`)}
                                            />
                                            {errors?.training?.[index]?.training_end_date &&
                                                <small className='text-danger'>This field is required.</small>}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">অর্জিত ফলাফল</label>
                                            <select className="form-select" aria-label="Default select example"
                                                    {...register(`training.${index}.result`)}>
                                                <option value="" style={{color: 'gray'}}>নির্বাচন করুন</option>
                                                {
                                                    resultPosition.map(result => <option key={result.id}
                                                                                            value={result.id}>{result.bn}</option>)
                                                }
                                            </select>
                                        </div>
                                        <div className='col-xs-auto col-md'>
                                            <label htmlFor="inputAddress" className="form-label">মন্তব্য</label>
                                            <input className="form-control"
                                                    {...register(`training.${index}.remarks`)}
                                            />
                                        </div>
                                        <div className="col-xs-auto col-md mt-2 btn_file text-center">
                                            <label htmlFor="attachments" className="form-label"></label>
                                            <br/>
                                            <FileUpload
                                                title="প্রমাণক সংযুক্ত করুন"
                                                register={register}
                                                registerkey={`training.${index}.attachments`}
                                                attachments={attachments}
                                                deleteAttachment={deleteAttachment}
                                            />
                                        </div>
                                        <div className='col-xs-auto col-md'></div>
                                        <div className='col-xs-auto col-md'></div>
                                    </div>
                                </div>
                            );
                        })}
                        {((isAdmin() || permissions?.includes('edit'))) &&  <FormSubmitBtn reset={reset}/> }
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EmployeeTraining;