import React, { useEffect } from 'react';
import Home from '../../Components/Home/Home';

const HomePage = () => {

    return (
        <Home />
    );
};

export default HomePage;