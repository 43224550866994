import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import {toast, ToastContainer} from 'react-toastify';
import {authToken, authUser, url} from "../../helpers/helpers";
import Edit from "./Edit";
import PasswordChange from "./PasswordChange";

const Profile = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState([]);
    const axiosHeader = {
        headers: {
            authorization: `Bearer ${authToken()}`,
        }
    }

    const getUser = () => {
        axios.get(url(`users/${authUser().id}`), axiosHeader)
            .then(({data: {data}}) => {
                console.log(data);
                setUser(data);
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        if (!authToken()) {
            navigate('/login')
        }
        getUser();
    }, [navigate]);

    const handleTabChange = (tab) => {
        // setUserId(null);
        // setUserInfo(null);
        // setEmployeeId(null);
    }

    return (
        <div className="component_container py-2 my-3">
            <ToastContainer/>
            <div className="row">
                <div className="col-12">
                    <ul className="nav nav-pills mb-2" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="btn btn-sm nav-link active" id="profile-info-change-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#profile-info-change" type="button" role="tab"
                                    aria-controls="profile-info-change"
                                    aria-selected="true"
                                    onClick={() => handleTabChange('profile-info-change')}> তথ্য পরিবর্তন করুন
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="password-change-tab" data-bs-toggle="pill"
                                    data-bs-target="#password-change" type="button" role="tab"
                                    aria-controls="password-change" aria-selected="false"
                                    onClick={() => handleTabChange('password-change')}> পাসওয়ার্ড পরিবর্তন করুন
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="profile-info-change" role="tabpanel"
                             aria-labelledby="profile-info-change-tab">
                            <Edit
                                user={user}
                            />
                        </div>
                        <div className="tab-pane fade" id="password-change" role="tabpanel"
                             aria-labelledby="password-change-tab">
                            <PasswordChange/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;