import React from 'react';
import './loader.css';

const Loader = () => {
    return (
        <div className='d-flex justify-content-center align-items-center' style={{height: '65vh'}}>
            <span className="spinner spinner-large"></span>
        </div>
    );
};

export default Loader;