import React, {useCallback, useEffect, useState} from "react";
import {useFieldArray, useForm} from "react-hook-form";
import {axiosConfig, isAdmin, payloadAsFormData, url} from "../../helpers/helpers";
import axios from "axios";
import {toast} from "react-toastify";
import NestedFormIncDec from "../EmployeeCommonSection/ NestedFormIncDec";
import FileUpload from "../Utils/FileUpload";
import FourColumn from "../EmployeeCommonSection/FourColumn";
import FieldErrorMessage from "../Utils/FieldErrorMessage";
import {compareArraysAsSet} from "@testing-library/jest-dom/dist/utils";
import FormSubmitBtn from "../EmployeeCommonSection/FormSubmitBtn";
import Select2Wrapper from "../Utils/Select2Wrapper";
import Loader from "../Loader/Loader";
import EmployeeHeader from "../EmployeeCommonSection/EmployeeHeader";

const CurrentJobInformation = ({userInfo, userId, employeeId, deleteAttachment, permissions}) => {
    const [errors, setErrors] = useState([]);
    const [cadre, setCadre] = useState([]);
    const [institution, setInstitution] = useState([]);
    const [designation, setDesignation] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [organization, setOrganization] = useState([]);
    const [gradeList, setGradeList] = useState([]);
    const [ministryOrganizationAndInstitution, setMinistryOrganizationAndInstitution] = useState([]);
    const [loading, setLoading] = useState(true);

    const {register,getValues, setValue, handleSubmit, reset} = useForm();


    useEffect(() => {
        reset();
        console.log(userInfo?.current_job_info,'userInfo?.current_job_info');
        if (userInfo?.current_job_info) {
            const {current_job_info} = userInfo;
            Object.entries(current_job_info).forEach(([key, value]) => setValue(key, value));
            setAttachments(current_job_info.attachments)
            handleMinistryChange(current_job_info.ministry_id)
            handleOrganizationChange(current_job_info.organization_id)
        }
    }, [userInfo]);

    useEffect(() => {
        getCadre();
        getDesignation();
        getMinistryOrganizationAndInstitution();

        let gradeArray = [];
        for (let i = 1; i <= 20; i++) {
            gradeArray.push({
                value: i,
                label: i
            });
        }
        setGradeList(gradeArray)
    }, []);

    const getMinistryOrganizationAndInstitution = () => {
        try {
            setLoading(true)
            axios.get(url(`fetch-ministry-organization-and-institution`), axiosConfig())
                .then(({data: {data}}) => {
                    setMinistryOrganizationAndInstitution(data);
                    setLoading(false)
                })
                .catch((error) => {
                    setLoading(false)
                    console.log(error);
                })
        } catch (e) {
            toast.error('something went wrong')
        }
    }

    const getDesignation = () => {
        try {
            setLoading(true)

            axios.get(url(`fetch-designation`), axiosConfig())
                .then(({data: {data}}) => {
                    setDesignation(data)
                    setLoading(false)

                })
                .catch((error) => {
                    setLoading(false)
                    console.log(errors);
                })
        } catch (e) {
            toast.error('something went wrong')
        }
    }
    const getCadre = () => {
        try {
            setLoading(true)
            axios.get(url(`fetch-cadre`), axiosConfig())
                .then(({data: {data}}) => {
                    setCadre(data)
                    setLoading(false)
                })
                .catch((error) => {
                    setLoading(false)
                    console.log(error);
                })
        } catch (e) {
            toast.error('something went wrong')
        }
    }

    const onSubmit = (data) => {
        let formData = new FormData();

        if (!employeeId) {
            toast.warning('Select Employee First!');
            return;
        }

        data['employee_id'] = employeeId;
        formData = payloadAsFormData(formData, null, data)

        axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/current-job-info`, formData, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("accessToken")}`, "Content-Type": "multipart/form-data"
            }
        }).then(response => {
            if (response.data?.message === 'Success') {
                toast.success('Saved successful.');
            }
        }).catch(error => {
            if (error.response.status === 422) {
                setErrors(error.response.data.errors);
            }
        });
    }

    const handleMinistryChange = (id) => {
        let org = organization;
        org = ministryOrganizationAndInstitution.filter(data => data.parent_id == id && data.type === 'organization');
        setOrganization(() => [...org]);
    }
    const handleOrganizationChange = (id) => {
        let institute = institution;
        institute = ministryOrganizationAndInstitution.filter(data => data.parent_id == id && data.type === 'institution');
        setInstitution(() => [...institute]);
    }

    return (
        <div className="employee_info">
            <div className='card shadow border-0 mt-2'>
                <div className='card-header'>
                    <EmployeeHeader e_title="বর্তমান চাকুরির তথ্য" userName={userInfo?.name_in_bn} e_num="220044689"/>
                </div>
                <div className='card-body'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <input type="hidden"
                            className="form-control"
                            {...register(`employee_id`)}
                        />
                        <div className='row'>
                            <div className="col-3">
                                <label className="form-label">পদবি <span
                                    className='text-danger'>*</span></label>
                                <Select2Wrapper
                                    placeholder="পদবি নির্বাচন করুন"
                                    value={getValues('designation_id')}
                                    onChange={(value) => setValue('designation_id',value)}
                                    options={designation}
                                />
                                <FieldErrorMessage errors={errors} name="designation_id"/>
                            </div>
                            <div className="col-3">
                                <label className="form-label">মন্ত্রণালয় নাম <span
                                    className='text-danger'>*</span></label>
                                <select
                                    className="form-select"
                                    {...register(`ministry_id`)}
                                    onChange={(e) => handleMinistryChange(e.target.value)}
                                >
                                    <option value="" style={{color: 'gray'}}>মন্ত্রণালয় নির্বাচন করুন</option>
                                    {ministryOrganizationAndInstitution && ministryOrganizationAndInstitution.map(ministry => {
                                        return ministry.parent_id === null ? (
                                            <option key={ministry.id} value={ministry.id}>
                                                {ministry.name}
                                            </option>) : '';
                                    })}
                                </select>
                                <FieldErrorMessage errors={errors} name="ministry_id"/>
                            </div>
                            <div className="col-3">
                                <label className="form-label">দপ্তর /সংস্থা নাম <span
                                    className='text-danger'>*</span></label>
                                <select
                                    className="form-select"
                                    {...register(`organization_id`)}
                                    onChange={(e) => handleOrganizationChange(e.target.value)}
                                >
                                    <option value="" style={{color: 'gray'}}>দপ্তর /সংস্থা নির্বাচন করুন</option>
                                    {organization && organization.map(org => {
                                        return (<option key={org.id} value={org.id}>
                                            {org.name}
                                        </option>);
                                    })}
                                </select>
                                <FieldErrorMessage errors={errors} name="ministry_id"/>
                            </div>
                            <div className="col-3">
                                <label className="form-label">প্রতিষ্ঠানের নাম <span
                                    className='text-danger'>*</span></label>
                                <Select2Wrapper
                                    placeholder="প্রতিষ্ঠান নির্বাচন করুন"
                                    value={getValues('institution_id')}
                                    onChange={(value) => setValue('institution_id',value)}
                                    options={institution}
                                />
                                <FieldErrorMessage errors={errors} name="institution_id"/>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className="col-3">
                                <label className="form-label">বর্তমান পদে যোগদানের তারিখ </label>
                                <input type="date" className="form-control"
                                    {...register(`joining_date`)}
                                />
                            </div>
                            <div className="col-3">
                                <label className="form-label">শ্রেণি </label>
                                <input type="text"
                                    className="form-control"
                                    {...register(`class`)}
                                />
                                <FieldErrorMessage errors={errors} name="class"/>
                            </div>
                            <div className="col-3">
                                <label className="form-label">পি আর এল তারিখ </label>
                                <input type="date" className="form-control"
                                    {...register(`prl_date`)}
                                />
                            </div>
                            <div className="col-3">
                                <label className="form-label">গ্রেড</label>
                                {/* <input type="text"
                                    className="form-control"
                                    {...register(`grade`)}
                                /> */}
                                <Select2Wrapper
                                    placeholder="গ্রেড নির্বাচন করুন"
                                    value={getValues('grade')}
                                    onChange={(value) => setValue('grade',value)}
                                    options={gradeList}
                                />
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className="col-3">
                                <label htmlFor="inputAddress" className="form-label">ক্যাডার</label>
                                <select id='cadre' className="form-select" aria-label="Default select example"
                                        {...register("cadre")}>
                                    <option value="" style={{color: 'gray'}}>নির্বাচন করুন</option>
                                    {
                                        cadre.map(cadre => <option key={cadre.id} value={cadre.id}>{cadre.bn}</option>)
                                    }
                                </select>
                                <FieldErrorMessage errors={errors} name="cadre"/>
                            </div>
                            <div className="col-3">
                                <label className="form-label">ব্যাচ নম্বর </label>
                                <input type="text"
                                    className="form-control"
                                    {...register(`batch_no`)}
                                />
                            </div>
                            <div className="col-3">
                                <label className="form-label">প্রমাণক</label>
                                <FileUpload
                                    title="প্রমাণক সংযুক্ত করুন"
                                    register={register}
                                    registerkey={`attachments`}
                                    attachments={attachments}
                                    deleteAttachment={deleteAttachment}
                                />
                            </div>
                            {((isAdmin() || permissions?.includes('edit'))) &&  <FormSubmitBtn reset={reset}/> }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CurrentJobInformation;