import React, {useEffect, useRef, useState} from 'react';
import HomePage from './Pages/HomePages/HomePage';
import LoginPage from './Pages/LoginPages/LoginPage';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import EmployeeSpousePage from './Pages/EmployeeInfoPages/EmployeeSpousePage';
import EmployeeTransferPage from './Pages/EmployeeInfoPages/EmployeeTransferPage';
import EmployeeTravellPage from './Pages/EmployeeInfoPages/EmployeeTravellPage';
import EmployeeLanguageEfficiencyPage from './Pages/EmployeeInfoPages/EmployeeLanguageEfficiencyPage';
import EmployeeExtraCurricularPage from './Pages/EmployeeInfoPages/EmployeeExtraCurricularPage';
import EmployeeInfoPage from './Pages/EmployeeInfoPages/EmployeeInfoPage';
import EmployeeTrainingPage from './Pages/EmployeeInfoPages/EmployeeTrainingPage';
import PrizeInfoDetailsPage from './Pages/PrizeInfoPages/PrizeInfoDetailsPage';
import LeaveManagementPage from './Pages/LeaveManagementPages/LeaveManagementPage';
import PunishmentPage from './Pages/PunishmentPages/PunishmentPage';
import TopNavbarPage from './Pages/NavigationPages/TopNavbarPage';
import SideNavbarPage from './Pages/NavigationPages/SideNavbarPage';
import EmployeeChildPage from './Pages/EmployeeInfoPages/EmployeeChildPage';
import EmployeeAddressPage from './Pages/EmployeeInfoPages/EmployeeAddressPage';
import EmployeeEducationPage from './Pages/EmployeeInfoPages/EmployeeEducationPage';
import PageNotFoundPage from './Pages/PageNotFoundPage';
import AOS from "aos";
import "aos/dist/aos.css";
import './App.css';
import SignUpPage from './Pages/LoginPages/SignUpPage';
import DashboardPage from './Pages/DashboardPages/DashboardPage';
import TravelReportPage from './Pages/ReportPages/TravelReportPage';
import EmployeeResearchPage from './Pages/EmployeeInfoPages/EmployeeResearchPage';
import EmployeeViewPage from "./Pages/EmployeeInfoPages/ViewPages/EmployeeViewPage";
import JobInformation from "./Components/JobInformation/JobInformation";
import EmployeeFullViewPage from "./Pages/EmployeeInfoPages/ViewPages/EmployeeFullViewPage";
import EmployeeAttachmentViewPage from "./Pages/EmployeeInfoPages/ViewPages/EmployeeAttachmentViewPage";
import UserList from "./Components/Users/UserList";
import Profile from "./Components/Profile/Profile";
import MinistryOrganizationAndInstitution
    from "./Components/Settings/MinistryOrganizationAndInstitution/MinistryOrganizationAndInstitution";
import EmployeeReportPage
    from "./Pages/ReportPages/EmployeeReportPage";
import Designation from "./Components/Settings/Designation";
import Gallery from "./Components/Settings/Gallery";
import ProtectedRoute from "./ProtectedRoute";
import {authUser, isAdmin} from "./helpers/helpers";
import useUserPermissionsById from "./Hooks/useUserPermissionsById";
import Loader from "./Components/Loader/Loader";

function App() {
    const navbar = useRef();
    const location = useLocation();
    const [menuCollapse, setMenuCollapse] = useState(0);
    const {permissions, errors, loading} = useUserPermissionsById(authUser()?.id);



    const menuIconClick = () => {
        menuCollapse ? setMenuCollapse(0) : setMenuCollapse(1);
    };

    useEffect(() => {
        if (document.body.clientWidth <= 767.98) {
            setMenuCollapse(1);
        }
    }, []);

    useEffect(() => {
        if (navbar.current == null || typeof navbar.current === 'undefined') {
            return;
        }
        if (location.pathname === '/login') {
            navbar.current.style.display = 'none'
        } else if (location.pathname === '/signup') {
            navbar.current.style.display = 'none'
        } else {
            navbar.current.style.display = 'block'
        }
    }, [location.pathname])

    useEffect(() => {
        AOS.init({
            duration: 800
        });
    }, []);

    if (authUser().hasOwnProperty('id') && loading) {
        return <Loader/>
    }
    return (
        <div>
            <div ref={navbar}>
                <TopNavbarPage menuIconClick={menuIconClick}/>
                <SideNavbarPage menuCollapse={menuCollapse} permissions={permissions} />
            </div>

            <Routes>
                <Route element={
                    <ProtectedRoute
                        redirectTo={`/employee-info-edit/${authUser()?.employee_id}`}
                        isAllowed={isAdmin() || permissions?.includes('dashboard')}
                    />
                }>
                    <Route path='/' element={<HomePage/>}/>
                </Route>
                <Route path="/login" element={<LoginPage/>}/>
                <Route path="/signup" element={<SignUpPage/>}/>

                {/* <Route path={"/employee-info/:id"} element={<EmployeePersonalInfo />} /> */}
                <Route element={
                    <ProtectedRoute
                        redirectTo='/profile'
                        isAllowed={isAdmin() || permissions?.includes('personal_info')}
                    />
                }>
                    <Route path="/employee-info/" element={<EmployeeInfoPage permissions={permissions}/>}/>
                    <Route path="/employee-info-edit/:id" element={<EmployeeInfoPage permissions={permissions}/>}/>
                    <Route path='/employee-info/:id/address' element={<EmployeeAddressPage permissions={permissions}/>} />
                    <Route path='/employee-info/:id/education-degree' element={<EmployeeEducationPage permissions={permissions} />}/>
                    <Route path='/employee-info/:id/spouse' element={<EmployeeSpousePage permissions={permissions} />}/>
                    <Route path='/employee-info/:id/child' element={<EmployeeChildPage permissions={permissions} />}/>
                    <Route path='/employee-info/:id/transfer' element={<EmployeeTransferPage permissions={permissions} />}/>
                    <Route path='/employee-info/:id/travell' element={<EmployeeTravellPage permissions={permissions} />}/>
                    <Route path='/employee-info/:id/language-efficiency' element={<EmployeeLanguageEfficiencyPage permissions={permissions} />}/>
                    <Route path='/employee-info/:id/trainings' element={<EmployeeTrainingPage permissions={permissions} />}/>
                    <Route path='/employee-info/:id/extra-curricular' element={<EmployeeExtraCurricularPage permissions={permissions} />}/>
                    <Route path='/employee-info/:id/research' element={<EmployeeResearchPage permissions={permissions} />}/>
                    <Route path='/employee-info/:id/view' element={<EmployeeViewPage/>}/>
                    <Route path='/employee-info/:id/view-full' element={<EmployeeFullViewPage/>}/>
                    <Route path='/employee-info/:id/view-attachments' element={<EmployeeAttachmentViewPage/>}/>
                    <Route path='/job-information' element={<JobInformation permissions={permissions} />}/>
                    <Route path='/job-information/:id' element={<JobInformation permissions={permissions}/>}/>
                    <Route path='/prize-info' element={<PrizeInfoDetailsPage permissions={permissions}/>}/>
                    <Route path='/prize-info/:id' element={<PrizeInfoDetailsPage permissions={permissions}/>}/>
                    <Route path='/leave-management' element={<LeaveManagementPage permissions={permissions}/>}/>
                    <Route path='/leave-management/:id' element={<LeaveManagementPage permissions={permissions}/>}/>
                    <Route path='/punishment' element={<PunishmentPage permissions={permissions}/>}/>
                    <Route path='/punishment/:id' element={<PunishmentPage permissions={permissions}/>}/>
                </Route>
                {/* <Route path='services' element={<EmployeeService />} /> */}
                <Route element={<ProtectedRoute isAllowed={isAdmin() || permissions?.includes('all_info')}/>}>
                    <Route path='/dashboard' element={<DashboardPage permissions={permissions}/>}/>
                </Route>
                <Route element={<ProtectedRoute isAllowed={isAdmin() || permissions?.includes('user_list')}/>}>
                    <Route path='/users' element={<UserList permissions={permissions} />}/>
                </Route>
                <Route path='/profile' element={<Profile/>}/>
                <Route element={<ProtectedRoute isAllowed={isAdmin() || permissions?.includes('settings')}/>}>
                    <Route path='/ministry-organization-and-institution' element={<MinistryOrganizationAndInstitution permissions={permissions}/>}/>
                    <Route path='/designation' element={<Designation permissions={permissions}/>}/>
                    <Route path='/galleries' element={<Gallery />}/>
                </Route>

                {/* Reports */}
                <Route path='/employees-report' element={<EmployeeReportPage />}/>
                <Route path='travelreport' element={<TravelReportPage/>}/>

                {/* Page Not Found */}
                <Route path="/404" element={<PageNotFoundPage/>}/>
                <Route path="*" element={<Navigate to="/404"/>}/>
            </Routes>
        </div>
    );
}

export default App;
