import bdLogo from "../../Assets/bdLogo.png";
import React from "react";
import {asset, formatDate, translateToBanglaDigit} from "../../helpers/helpers";
import './Print.css'
import avatar from "../../Assets/avatar.png"

const PrintEmployeeList = ({employees, isPrinting, reference}) => {

    const onError = ({ currentTarget }) => {
        currentTarget.onError = null;
        currentTarget.src= avatar
    }

    return (
        <>
            <div className="employeePrint" ref={reference} style={{display: !isPrinting ? "none" : "block"}}>
                <div className="p-5">
                    <div className="row mb-2">
                        <div className="col-12">
                            <center>
                                <img className="mb-2" style={{width: 100 + "px"}} src={bdLogo} alt="PMIS LOGO"/>
                                <br/>
                                <strong>Personal Management Information System</strong>
                                <br/>
                                <strong>(PMIS)</strong>
                                <br/>
                            </center>
                        </div>
                    </div>
                    <table className="table table-bordered">
                        <thead>
                        <tr style={{backgroundColor: '#f9f9f9'}}>
                            <th className="text-center" scope="col">ক্রমিক নং</th>
                            <th scope="col">নাম</th>
                            <th scope="col">ছবি</th>
                            <th scope="col">আইডি</th>
                            <th scope="col">জাতীয় পরিচয়পত্র নম্বর</th>
                            <th scope="col">বর্তমান কর্মস্থল</th>
                            <th scope="col">পদবি</th>
                            <th scope="col">মন্ত্রণালয়</th>
                            <th scope="col">দপ্তর /সংস্থা</th>
                            <th scope="col">যোগদানের তারিখ</th>
                        </tr>
                        </thead>
                        <tbody>
                        {employees && employees.map((employee, key) => {
                            return (<tr key={employee?.id}>
                                <td className="text-center" style={{verticalAlign: 'center'}}>
                                    {translateToBanglaDigit(key + 1)}
                                </td>
                                <td style={{verticalAlign: 'center'}}>{employee?.name_in_bn}</td>
                                <td style={{textAlign: 'center'}}>
                                    <img onError={onError} style={{width: '80px', height: '80px', border: '2px solid gray', borderRadius: '50px'}} src={employee?.image ? asset(employee.image) : avatar } />
                                </td>
                                <td style={{verticalAlign: 'center'}}>{translateToBanglaDigit(employee?.unique_id)}</td>
                                <td style={{verticalAlign: 'center'}}>{translateToBanglaDigit(employee?.nid)}</td>
                                <td style={{verticalAlign: 'center'}}>{employee?.institute_name}</td>
                                <td style={{verticalAlign: 'center'}}>{employee?.designation}</td>
                                <td style={{verticalAlign: 'center'}}>{employee?.ministry}</td>
                                <td style={{verticalAlign: 'center'}}>{employee?.organization}</td>
                                <td style={{verticalAlign: 'center'}}>{translateToBanglaDigit(formatDate(employee?.joining_date))}</td>
                            </tr>)
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
export default PrintEmployeeList;