import React, {useRef, useEffect} from 'react';
import $ from 'jquery';
import 'select2';
import 'react-select2-wrapper/css/select2.css';

const Select2Wrapper = ({options, value, placeholder, onChange, infiniteScrollUrl, ...rest}) => {
    const selectRef = useRef(null);

    useEffect(() => {
        let select2Ref = selectRef.current;
        let attributes = {};
        if (infiniteScrollUrl) {
            attributes = {
                ajax: {
                    url: infiniteScrollUrl,
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                    },
                    data: function (params) {
                        return {
                            search: params.term,
                            page: params.page || 1
                        }
                    },
                    processResults: function ({data}) {
                        return {
                            results: data.data,
                            pagination: {
                                more: data.last_page !== data.current_page
                            }
                        };
                    }
                }
            }
        }
        $(select2Ref).select2(attributes);

        $(select2Ref).on('change', (e) => {
            const selectedValue = e.target.value;
            onChange(selectedValue);
        });

        return () => {
            $(select2Ref).off('change');
            $(select2Ref).select2('destroy');
        };
    }, [onChange]);

    useEffect(() => {
        if (value) {
            $(selectRef.current).val(value).change();
        }
    }, [value, options]);

    return (
        <select ref={selectRef}
                className="select2"
                {...rest}
                placeholder={'Select'}
                style={{width: 100 + '%'}}
        >
            <option value="" style={{color: 'gray'}}>{placeholder ? placeholder : 'নির্বাচন করুন'}</option>
            {(options && !infiniteScrollUrl) && options?.map((option) => (
                <option key={option.value} value={option.value} defaultValue={value}>
                    {option.label}
                </option>
            ))}
        </select>
    );
};

export default Select2Wrapper;