import React, { useState } from 'react';
import { TbFileUpload } from "react-icons/tb";
import FormSubmitBtn from '../EmployeeCommonSection/FormSubmitBtn';
import NestedFormIncDec from '../EmployeeCommonSection/ NestedFormIncDec';
import FourColumn from '../EmployeeCommonSection/FourColumn';
import EmployeeHeader from '../EmployeeCommonSection/EmployeeHeader';
import FileUpload from "../Utils/FileUpload";
import { isAdmin } from '../../helpers/helpers';

const EmployeeEducation = (
    {
        onSubmit,
        register,
        handleSubmit,
        errors,
        fields,
        append,
        remove,
        degrees,
        exams,
        eduData,
        setEduId,
        eduSubject,
        setEduIndex,
        eduBoard,
        reset,
        deleteAttachment,
        permissions,
        userName
    }) => {

    const [eduIds, setEduIds] = useState(Array(fields.length).fill(''));

    const handleEduData = (event, index) => {
        const { value } = event.target;
        setEduId(value);
        setEduIndex(index);
        setEduIds(prevEduIds => {
            const newEduIds = [...prevEduIds];
            newEduIds[index] = value;
            return newEduIds;
        });
    }

    return (
        <div className='employee_info'>
            <div className='card shadow border-0 mt-2' >
                <div className='card-header'>
                    <EmployeeHeader e_title="প্রাতিষ্ঠানিক শিক্ষা সংক্রান্ত তথ্য" userName={userName} e_num="22004689" />
                </div>
                <div className='card-body'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {fields.map((item, index) => {
                            let attachments = item.attachments;
                            return (
                                <div key={item.id}>
                                    <NestedFormIncDec fields={fields} append={append} remove={remove} index={index} />
                                    <div className='row'>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">শিক্ষাগত যোগ্যতা</label>
                                            {
                                                eduData &&
                                                <select className="form-select" aria-label="Default select example"
                                                        {...register(`education.${index}.type`, {required: true})}
                                                        onChange={(e) => {
                                                            handleEduData(e, index);
                                                        }}
                                                >
                                                    <option value="" style={{color: 'gray'}}>নির্বাচন করুন</option>
                                                    {
                                                        eduData?.map(edu => <option key={edu.id}
                                                                                    value={edu.id}>{edu.bn}</option>)
                                                    }
                                                </select>
                                            }
                                            {errors?.education?.[index]?.type && <small className='text-danger'>This field is required.</small>}
                                        </div>
                                        <FourColumn />
                                    </div>

                                    <div className='row'>
                                        {
                                            eduSubject[index]?.length ?
                                                <div className="col-xs-auto col-md mb-3">
                                                    <label htmlFor="inputAddress" className="form-label">পরীক্ষার নাম <span className='text-danger'>*</span></label>
                                                    <select className="form-select" aria-label="Default select example"
                                                        {...register(`education.${index}.exam_name`, { required: true })}>
                                                        <option style={{color: 'gray'}} value="">নির্বাচন করুন</option>
                                                        {
                                                            eduSubject[index]?.map(sub => <option key={sub.id} value={sub.id}>{sub.bn}</option>)
                                                        }
                                                    </select>
                                                    {errors?.education?.[index]?.exam_name && <small className='text-danger'>This field is required.</small>}
                                                </div>
                                            : <></>
                                        }

                                        {
                                            (eduBoard && eduBoard[index]?.length) &&
                                                (eduIds[index]==1 || eduIds[index]==2) ?
                                                <div className="col-xs-auto col-md mb-3">
                                                    <label htmlFor="inputAddress" className="form-label">বোর্ড <span className='text-danger'>*</span></label>
                                                    <select className="form-select" aria-label="Default select example"
                                                        {...register(`education.${index}.board`, { required: true })}>
                                                        <option style={{color: 'gray'}} value="">নির্বাচন করুন</option>
                                                        {
                                                            eduBoard[index]?.map(board => <option key={board.id} value={board.id}>{board.bn}</option>)
                                                        }
                                                    </select>
                                                    {errors?.education?.[index]?.board && <small className='text-danger'>This field is required.</small>}
                                                </div>
                                            :<></>
                                        }

                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">শিক্ষা প্রতিষ্ঠান <span className='text-danger'>*</span></label>
                                            <input className="form-control"
                                                {...register(`education.${index}.institute`, {required: true})}
                                            />
                                            {errors?.education?.[index]?.institute && <small className='text-danger'>This field is required.</small>}
                                        </div>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">বিভাগ / বিষয় / অর্জিত ডিগ্রি <span className='text-danger'>*</span></label>
                                            <input type="text" className="form-control"
                                                {...register(`education.${index}.department`, { required: true })}
                                            />
                                            {errors?.education?.[index]?.department && <small className='text-danger'>This field is required.</small>}
                                        </div>

                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">অর্জিত ফলাফল (গ্রেড / ডিভিশন)</label>
                                            <input className="form-control"
                                                {...register(`education.${index}.grade`)}
                                            />
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">পাশের বছর <span className='text-danger'>*</span></label>
                                            <input className="form-control" type="year"
                                                {...register(`education.${index}.passing_year`, { required: true })}
                                            />
                                            {errors?.education?.[index]?.passing_year && <small className='text-danger'>This field is required.</small>}
                                        </div>
                                        <div className="col-xs-auto col-md mb-3">
                                            <label htmlFor="inputAddress" className="form-label">রোল নাম্বার</label>
                                            <input className="form-control"
                                                {...register(`education.${index}.roll_no`)}
                                            />
                                        </div>
                                        <div className="col-xs-auto col-md">
                                            <label htmlFor="inputAddress" className="form-label">সেশন</label>
                                            <input className="form-control"
                                                {...register(`education.${index}.session`)}
                                            />
                                        </div>
                                        <div className="col-xs-auto col-md mt-2 btn_file text-center">
                                            <label htmlFor="attachments" className="form-label"></label>
                                            <br/>
                                            <FileUpload
                                                title="প্রমাণক সংযুক্ত করুন"
                                                register={register}
                                                registerkey={`education.${index}.attachments`}
                                                attachments={attachments}
                                                deleteAttachment={deleteAttachment}
                                            />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        {((isAdmin() || permissions?.includes('edit'))) && <FormSubmitBtn reset={reset} /> }
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EmployeeEducation;