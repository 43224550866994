import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import useEmployeeInfo from '../../Hooks/useEmployeeInfo';
import {useParams} from 'react-router-dom';
import FieldErrorMessage from "../Utils/FieldErrorMessage";
import Select2Wrapper from "../Utils/Select2Wrapper";
import {authUser} from "../../helpers/helpers";

const EmployeeInfoHeader = (
    {
        e_title,
        register,
        errors,
        setUsers,
        users,
        setUserId,
        userId
    }
) => {
    const handleUserIdChange = (id) => {
        if (!!id && parseInt(id) !== parseInt(userId)) {
            setUserId(id);
        }
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/fetch-user-wise-unique-id`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            }
        })
            .then(response => {
                setUsers(response.data?.data);
                if (!userId) {
                    setUserId(authUser().id)
                }
            }).catch(error => {
            console.error(error)
        })
    }, [])

    return (
        <div className='d-inline d-md-flex justify-content-between'>
            <h4 style={{color: '#683091', fontWeight: 'bold', fontSize: '22px'}}>{e_title}</h4>
            {/* <p style={{color: '#5D5E82'}}>কর্মচারী/কর্মকর্তা নম্বরঃ {user?.unique_id}</p> */}
            <div className='ms-auto w-25'>
                {/*<Select2Wrapper*/}
                {/*    value={userId}*/}
                {/*    onChange={(id) => handleUserIdChange(id)}*/}
                {/*    options={users}*/}
                {/*/>*/}
                {/*<select className="form-select" aria-label="Default select example"*/}
                {/*    {...register("user_id")}*/}
                {/*    onChange={handleUniqueId}*/}
                {/*>*/}
                {/*    <option value="" style={{color: 'gray'}}>নির্বাচন করুন</option>*/}
                {/*    {*/}
                {/*        users?.map(item => <option key={item.id} value={item.id}>{item.text}</option>)*/}
                {/*    }*/}
                {/*</select>*/}
                <FieldErrorMessage errors={errors} name="user_id" prefix="basic_info"/>
            </div>
        </div>
    );
};

export default EmployeeInfoHeader;